import React, { useEffect, useMemo, useState } from "react";
import Paper from "@mui/material/Paper";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";

// Icons
import CableIcon from "@mui/icons-material/Cable";
import AppsIcon from "@mui/icons-material/Apps";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { AppNav, currentRoute, routes } from "@/mods/route";
import { useLocation, useNavigate } from "react-router-dom";
import { isApp } from "@/bridge/client";

const AppFooter: React.FC = () => {
  const [appNav, setAppNav] = useState<AppNav | null>(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const syncAppNav = () => {
      const { appNav } = currentRoute();
      setAppNav(appNav ?? null);
    };
    syncAppNav();
  }, [location]);

  const navigationItems = useMemo(() => {
    const items = [
      {
        label: "连接",
        value: "connect",
        icon: <CableIcon />,
      },
      {
        label: "应用",
        value: "mini-apps",
        icon: <AppsIcon />,
      },
      {
        label: "账户",
        value: "account",
        icon: <AccountCircleIcon />,
      },
    ];

    return isApp ? items : items.filter((item) => item.value !== "connect");
  }, [isApp]);

  const navUrls = useMemo(() => {
    const navUrls: Record<AppNav, string> = {
      account: "",
      "mini-apps": "",
      connect: "",
      message: "",
    };
    for (const route of routes) {
      const appNav = route.appNav;
      if (appNav) {
        navUrls[appNav] = route.path;
      }
    }
    return navUrls;
  }, []);

  const changeNav = (newValue: AppNav) => {
    console.log("changeNav to", newValue, "with navUrls", navUrls);
    if (navUrls[newValue]) {
      navigate(navUrls[newValue]);
    }
  };

  if (!appNav || !isApp) return null;

  return (
    <Paper
      className="app-footer"
      sx={{
        "&.app-footer": {
          backgroundColor: "background.default",
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: (theme) => theme.zIndex.appBar,
          borderRadius: 0,
          borderTop: "1px solid",
          borderColor: "divider",
          boxShadow: "none",
          width: "100%",
          display: "block",
        },
      }}
      component="footer"
      elevation={0}
    >
      <BottomNavigation
        value={appNav}
        showLabels
        onChange={(_, newValue) => {
          changeNav(newValue);
        }}
        sx={{
          backgroundColor: "inherit",
          height: "56px",
        }}
      >
        {navigationItems.map((item) => (
          <BottomNavigationAction
            key={item.value}
            value={item.value}
            label={item.label}
            icon={item.icon}
          />
        ))}
      </BottomNavigation>
    </Paper>
  );
};

export default AppFooter;
