// cache.ts
import { storage } from "@/bridge/client";

const cache = {
  set: async <T>(key: string, value: T, seconds: number): Promise<void> => {
    try {
      const now = new Date();
      const item = {
        value: value,
        expiry: seconds < 0 ? -1 : now.getTime() + seconds * 1000,
      };
      await storage.set(key, JSON.stringify(item));
    } catch (error) {
      console.error("Cache set error:", error);
    }
  },

  get: async <T>(key: string): Promise<T | null> => {
    try {
      const jsonString = await storage.get<string>(key);
      if (!jsonString) {
        return null;
      }

      const item = JSON.parse(jsonString);

      if (!item || typeof item.expiry !== "number" || !("value" in item)) {
        await storage.delete(key);
        return null;
      }

      if (item.expiry < 0) {
        return item.value;
      }

      const now = new Date();
      if (now.getTime() > item.expiry) {
        await storage.delete(key);
        return null;
      }
      return item.value;
    } catch (error) {
      console.error("Cache get error:", error);
      await storage.delete(key);
      return null;
    }
  },

  del: async (key: string): Promise<void> => {
    try {
      await storage.delete(key);
    } catch (error) {
      console.error("Cache delete error:", error);
    }
  },

  clear: async (): Promise<void> => {
    try {
      await storage.clear();
    } catch (error) {
      console.error("Cache clear error:", error);
    }
  },
};

export default cache;
