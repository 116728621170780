import {
  createContext,
  useContext,
  ReactNode,
  useState,
  useEffect,
} from "react";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { createAppTheme } from "../theme";
import { CssBaseline } from "@mui/material";

interface ThemeContextType {
  mode: "light" | "dark";
  toggleTheme: () => void;
  useSystemTheme: () => void;
}

const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

// 添加一个检测系统主题的函数
const getSystemTheme = (): "light" | "dark" => {
  if (
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches
  ) {
    return "dark";
  }
  return "light";
};

export function ThemeProvider({ children }: { children: ReactNode }) {
  const [mode, setMode] = useState<"light" | "dark">(getSystemTheme());
  const [isSystemTheme, setIsSystemTheme] = useState(true);

  // 修改 toggleTheme 函数
  const toggleTheme = () => {
    setIsSystemTheme(false);
    setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
  };

  // 修改 useSystemTheme 函数
  const useSystemTheme = () => {
    setIsSystemTheme(true);
    setMode(getSystemTheme());
  };

  // 修改系统主题监听逻辑
  useEffect(() => {
    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");

    const handleChange = (e: MediaQueryListEvent) => {
      if (isSystemTheme) {
        const newTheme = e.matches ? "dark" : "light";
        setMode(newTheme);
      }
    };

    mediaQuery.addEventListener("change", handleChange);
    return () => mediaQuery.removeEventListener("change", handleChange);
  }, [isSystemTheme]);

  // 创建 MUI 主题
  const theme = createAppTheme(mode);

  return (
    <ThemeContext.Provider value={{ mode, toggleTheme, useSystemTheme }}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MuiThemeProvider>
    </ThemeContext.Provider>
  );
}

export function useTheme() {
  const context = useContext(ThemeContext);
  if (context === undefined) {
    throw new Error("useTheme must be used within a ThemeProvider");
  }
  return context;
}

