import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import { Link } from "react-router-dom";

const links = [
  { name: "我的设备", url: "/devices/" },
  { name: "我的服务方案", url: "/my-service/" },
  { name: "购买充值", url: "/purchase/" },
  { name: "我的订单", url: "/orders/" },
  { name: "管理成员", url: "/members/" },
  { name: "管理应用", url: "/apps/" },
  { name: "隐私策略", url: "/privacy-policy/" },
];

const Footer: React.FC = () => {
  return (
    <Box
      component="footer"
      sx={{
        py: 3,
        mt: "auto",
        width: "100%",
        backgroundColor: (theme) => theme.palette.background.paper,
      }}
    >
      <Container maxWidth={false} sx={{ px: { xs: 2, sm: 3, md: 4 } }}>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="center"
          flexWrap="wrap"
          sx={{ mb: 3 }}
        >
          {links.map((link, index) => (
            <React.Fragment key={link.url}>
              {index > 0 && <Divider orientation="vertical" flexItem />}
              <Link
                to={link.url}
                style={{
                  color: "inherit",
                  textDecoration: "none",
                }}
              >
                {link.name}
              </Link>
            </React.Fragment>
          ))}
        </Stack>

        <Typography variant="body2" color="text.secondary" align="center">
          © {new Date().getFullYear()} All Rights Reserved
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;
