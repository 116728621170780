import { v4 as uuidv4 } from "uuid";
import { storage } from "@/bridge/client";
import { base64Encode } from "@/utils";
import cache from "@/utils/cache";
import { User } from "@/utils/data";

const STORAGE_KEYS = {
  TOKEN: "token",
  DEVICE_ID: "deviceId",
  USER: "user",
} as const;

class AuthService {
  private static instance: AuthService;
  
  private constructor() {}
  
  static getInstance(): AuthService {
    if (!this.instance) {
      this.instance = new AuthService();
    }
    return this.instance;
  }

  // Token 相关方法
  async getToken(): Promise<string> {
    return (await storage.get<string>(STORAGE_KEYS.TOKEN)) || "";
  }

  async setToken(token: string): Promise<void> {
    await storage.set<string>(STORAGE_KEYS.TOKEN, token);
  }

  async clearToken(): Promise<void> {
    await storage.delete(STORAGE_KEYS.TOKEN);
  }

  // Device ID 相关方法
  async getDeviceId(): Promise<string> {
    const deviceId = await storage.get<string>(STORAGE_KEYS.DEVICE_ID);
    if (!deviceId) {
      const newDeviceId = uuidv4();
      await this.setDeviceId(newDeviceId);
      return newDeviceId;
    }
    return deviceId;
  }

  async setDeviceId(deviceId: string): Promise<void> {
    await storage.set(STORAGE_KEYS.DEVICE_ID, deviceId);
  }

  async forceSetDeviceId(deviceId: string): Promise<void> {
    await this.setDeviceId(deviceId);
  }

  // 认证状态相关方法
  async getAuthorizationHeader(): Promise<string> {
    const [deviceId, token] = await Promise.all([
      this.getDeviceId(),
      this.getToken()
    ]);
    
    if (deviceId && token) {
      const credentials = base64Encode(`${deviceId}:${token}`);
      return `Basic ${credentials}`;
    }
    return "";
  }

  async isAuthenticated(): Promise<boolean> {
    const token = await this.getToken();
    return !!token;
  }

  // User 缓存相关方法
  async getCachedUser(): Promise<User | null> {
    return cache.get<User>(STORAGE_KEYS.USER);
  }

  async setCachedUser(user: User | null): Promise<void> {
    if (user) {
      await cache.set(STORAGE_KEYS.USER, user, 3600); // 1小时缓存
    } else {
      await cache.del(STORAGE_KEYS.USER);
    }
  }

  // 清理所有认证相关数据
  async clearAll(): Promise<void> {
    await Promise.all([
      this.clearToken(),
      cache.del(STORAGE_KEYS.USER),
      storage.clear()
    ]);
  }

  // 设备登录相关方法
  async handleDeviceLogin(deviceId: string, token: string): Promise<boolean> {
    try {
      await this.forceSetDeviceId(deviceId);
      await this.setToken(token);
      return true;
    } catch (error) {
      console.error("Device login failed:", error);
      return false;
    }
  }
}

export const authService = AuthService.getInstance(); 