import React from "react";
import { Box } from "@mui/material";

interface AppContentProps {
  children: React.ReactNode;
  header?: React.ReactNode;
}

const AppContent: React.FC<AppContentProps> = ({ children, header }) => {
  return (
    <>
      {header}
      <Box
        sx={{
          flex: 1,
          overflow: "auto",
          display: "flex",
          flexDirection: "column",
          WebkitOverflowScrolling: "touch",
          height: "100vh",
          "&::-webkit-scrollbar": {
            display: "none"
          },
          scrollbarWidth: "none",
          msOverflowStyle: "none"
        }}
      >
        {children}
      </Box>
    </>
  );
};

export default AppContent;
