import { useCallback, useEffect } from "react";
import { useCustomerEvent } from "./useCustomerEvent";
import { CustomerKeyPointEvent } from "../services/CustomerEventEmitter";
import { Order } from "@/utils/data";

interface UsePayTrackerProps {
  currency: string;
  order?: Order;
  error?: string;
  paymentMethod?: string;
}

export const usePayTracker = ({
  currency,
  order,
  error,
  paymentMethod,
}: UsePayTrackerProps) => {
  const { sendEvent } = useCustomerEvent();

  useEffect(() => {
    if (paymentMethod) {
      sendEvent("pay_method", {
        paymentMethod,
        description: `支付方式变更: ${paymentMethod}`,
      });
    }
  }, [sendEvent, paymentMethod]);

  // 追踪货币切换
  useEffect(() => {
    if (currency && order) {
      sendEvent("switch_currency", {
        currency,
        order,
        description: `货币变更为: ${currency}`,
      });
    }
  }, [sendEvent, currency, order]);

  // 追踪支付失败
  useEffect(() => {
    if (error && order) {
      sendEvent(CustomerKeyPointEvent.PAY_FAILED, {
        error,
        order: order.uuid,
        description: `支付失败: ${error}`,
      });
    }
  }, [sendEvent, error, order]);

  const startPayEvent = useCallback(() => {
    sendEvent(CustomerKeyPointEvent.PAY, {
      description: `开始支付`,
    });
  }, [sendEvent]);

  return { startPayEvent };
};
