import { authService } from "@/services/auth";

export interface Response<T> {
  code: number;
  msg: string;
  data: T;
}

export interface Pagi {
  total: number;
  page: number;
  pageSize: number;
}

export interface ResponseList<T> {
  items: T[];
  pagination: Pagi;
}

const api = async <T>({
  path,
  method = "get",
  params,
  authRequired = true,
}: {
  path: string;
  method?: "get" | "post" | "patch" | "delete";
  params?: Record<string, unknown>;
  authRequired?: boolean;
}): Promise<Response<T>> => {
  // 在请求开始时获取认证状态
  const initialDeviceId = await authService.getDeviceId();
  const initialToken = await authService.getToken();
  
  const headers = {
    Authorization: await authService.getAuthorizationHeader(),
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  let options: RequestInit = { method: method.toUpperCase(), headers };
  if (params) {
    options = { ...options, body: JSON.stringify(params) };
  }

  const response = await fetch(path, options);
  const data = await response.json();

  // 处理认证相关的响应
  if (authRequired) {
    // 检查请求过程中认证状态是否发生变化
    const currentDeviceId = await authService.getDeviceId();
    const currentToken = await authService.getToken();
    
    if (currentDeviceId !== initialDeviceId || currentToken !== initialToken) {
      console.info("Auth state changed during request");
      return Promise.reject({ code: 498, msg: "认证状态已变更" });
    }

    if (data.code === 401) {
      await authService.clearAll();
      location.href = "/login";
      return Promise.reject(data);
    }
  }

  return data;
};

export default api;
