import { useCallback, useEffect } from "react";
import { useBasicInfo } from "@/context/BasicInfoContext";
import { currentRoute } from "@/mods/route";
import { useNetwork } from "@/context/NetworkContext";
import {
  customerEventEmitter,
  CustomerEventName,
  EventMeta,
  BaseEventData,
} from "../services/CustomerEventEmitter";
import { authService } from "@/services/auth";

export const useCustomerEvent = () => {
  const { isOnline } = useNetwork();
  const { basicInfo } = useBasicInfo();

  // 监听网络状态变化
  useEffect(() => {
    customerEventEmitter.setNetworkStatus(isOnline);
  }, [isOnline]);

  // 设置事件URL
  useEffect(() => {
    if (basicInfo?.links.seller_event_url) {
      customerEventEmitter.setEventUrl(basicInfo.links.seller_event_url);
    }
  }, [basicInfo]);

  const sendEvent = useCallback(
    async (eventName: CustomerEventName, meta: EventMeta) => {
      const deviceId = await authService.getDeviceId();
      const route = currentRoute();
      const eventData: BaseEventData = {
        page: route?.route?.name || "",
        customer: deviceId,
        event: eventName,
        meta,
        at: Math.floor(Date.now() / 1000),
      };

      customerEventEmitter.emit(eventData);
    },
    []
  );

  return { sendEvent };
};
