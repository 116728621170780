import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { Pagi } from "@/utils/api";
import { useMemo } from "react";

interface LoadMoreProps {
  data: Pagi;
  loading?: boolean;
  onLoadMore: (nextPage: number) => void;
}

const LoadMore = ({ data, loading = false, onLoadMore }: LoadMoreProps) => {
  const hasMore = useMemo(() => {
    const totalPages = Math.ceil(data.total / data.pageSize);
    return data.page + 1 < totalPages;
  }, [data.total, data.pageSize, data.page]);

  if (data.total === 0) {
    return (
      <Box sx={{ textAlign: "center", py: 2 }}>
        <Typography variant="body2" color="text.secondary">
          暂无数据
        </Typography>
      </Box>
    );
  }

  if (!hasMore) {
    return (
      <Box sx={{ textAlign: "center", py: 2 }}>
        <Typography variant="body2" color="text.secondary">
          已经到底啦
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ textAlign: "center", py: 2 }}>
      <Button
        onClick={() => onLoadMore(data.page + 1)}
        disabled={loading}
        variant="text"
        sx={{ minWidth: 120 }}
      >
        {loading ? (
          <CircularProgress size={20} sx={{ mr: 1 }} />
        ) : null}
        {loading ? "加载中..." : "加载更多"}
      </Button>
    </Box>
  );
};

export default LoadMore;
