import { Avatar, styled } from "@mui/material";

interface AppIconProps {
  size?: number;
}

const AppIcon = styled(Avatar)<AppIconProps>(({ theme, size = 60 }) => ({
  width: size,
  height: size,
  borderRadius: 999999,
  backgroundColor: theme.palette.background.paper,
}));

export default AppIcon;
