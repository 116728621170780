import dayjs from "dayjs";

export const formatSeconds = (seconds: number) => {
  const duration = dayjs().startOf("days").add(seconds, "seconds");
  const hours = duration.hour();
  const minutes = duration.minute();
  const remainingSeconds = duration.second();

  if (hours > 0) {
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
  } else if (minutes > 0) {
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  } else {
    return `${remainingSeconds.toString().padStart(2, "0")}`;
  }
};
