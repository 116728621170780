import cache from "@/utils/cache";
import React, {
  createContext,
  useContext,
  ReactNode,
  useState,
  useEffect,
  useCallback,
} from "react";
import api from "@/utils/api";

type SupportedLang = "zh-hans" | "zh-hant" | "en";

interface I18nContextType {
  t: (key: string, params?: Record<string, any>) => string;
  lang: SupportedLang;
  loading: boolean;
}

const I18nContext = createContext<I18nContextType>({
  t: (key: string) => key,
  lang: "en",
  loading: false,
});

const I18nProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [lang, setLang] = useState<SupportedLang>("en");
  const [translations, setTranslations] = useState<Record<string, string>>({});
  const [loading, setLoading] = useState(false);

  // 初始化语言设置
  useEffect(() => {
    const browserLang = navigator.language.toLowerCase();
    if (browserLang.startsWith("zh")) {
      if (
        browserLang.startsWith("zh-hans") ||
        browserLang.startsWith("zh-cn")
      ) {
        setLang("zh-hans");
      } else {
        setLang("zh-hant");
      }
    } else {
      setLang("en");
    }
  }, []);

  // 加载翻译内容
  const loadTranslations = useCallback(async () => {
    const cacheKey = `trans_${lang}`;
    const cachedTranslations = await cache.get<Record<string, string>>(
      cacheKey
    );

    if (cachedTranslations) {
      setTranslations(cachedTranslations);
      return;
    }

    console.log("loadTranslations: loading");
    setLoading(true);
    try {
      const data: any = await api<any>({
        path: `/api/translations/waymaker/${lang}`,
      });

      if (data) {
        setTranslations(data);
        await cache.set(cacheKey, data, 60 * 60 * 24 * 7); // 缓存7天
      } else {
        console.error("loadTranslations: no data");
      }
    } catch (error: any) {
      console.error(`loadTranslations error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  }, [lang]);

  useEffect(() => {
    loadTranslations();
  }, [loadTranslations, lang]);

  const t = useCallback(
    (key: string, params: Record<string, any> = {}): string => {
      let translation = translations[key] || key;
      Object.keys(params).forEach((param) => {
        translation = translation.replace(`{{${param}}}`, params[param]);
      });
      return translation;
    },
    [translations]
  );

  return (
    <I18nContext.Provider value={{ t, lang, loading }}>
      {!loading && children}
    </I18nContext.Provider>
  );
};

const useI18n = (): I18nContextType => {
  const context = useContext(I18nContext);
  if (!context) {
    throw new Error("useI18n必须在I18nProvider内部使用");
  }
  return context;
};

export { useI18n, I18nProvider };
