import { useState, useEffect, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Box,
  TextField,
  Button,
  Typography,
  CircularProgress,
  Container,
  AppBar,
  Toolbar,
  IconButton,
} from "@mui/material";
import { useToast } from "@/context/ToastContext";
import api from "@/utils/api";
import ContentLayout from "@/layouts/ContentLayout";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { isApp, actions } from "@/bridge/client";
import { useTheme as useMuiTheme } from "@mui/material/styles";
import { useAuth } from "@/context/AuthContext";
import { authService } from "@/services/auth";

const AuthVerify = () => {
  const { login } = useAuth();

  const [loading, setLoading] = useState(false);
  const [authCode, setAuthCode] = useState("");
  const [countdown, setCountdown] = useState(0);
  const [validateStatus, setValidateStatus] = useState<
    "error" | "success" | ""
  >("");
  const [validHelpText, setValidHelpText] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { toast } = useToast();
  const theme = useMuiTheme();

  const email = searchParams.get("email") || "";
  const authId = searchParams.get("authId") || "";
  const next = searchParams.get("next") || "/dashboard";

  useEffect(() => {
    if (!email || !authId) {
      navigate("/login");
    }
    setCountdown(60);
  }, []);

  useEffect(() => {
    if (countdown > 0) {
      const timer = setInterval(() => {
        setCountdown((c) => c - 1);
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [countdown]);

  useEffect(() => {
    const timer = setTimeout(() => {
      console.debug("Trying to focus auth input:", inputRef.current);
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  const handleVerify = async () => {
    setLoading(true);
    try {
      const deviceInfo = await actions.getDeviceInfo();
      const deviceId = await authService.getDeviceId();
      const { code, msg, data } = await api<{ token: string; userInfo: any }>({
        path: "/api/auth",
        method: "post",
        params: {
          authId,
          authCode,
          deviceId,
          deviceName: deviceInfo.name,
          isDevice: isApp,
        },
      });

      if (code === 0 && data.token) {
        const success = await login(data.token);
        if (success) {
          toast("success", "登录成功");
          await new Promise((resolve) => setTimeout(resolve, 100));
          navigate(next);
        } else {
          toast("error", "登录失败，请重试");
        }
      } else if (code === 422) {
        setValidateStatus("error");
        setValidHelpText("验证码错误");
      } else {
        toast("error", msg);
      }
    } catch (error) {
      console.error("验证失败:", error);
      toast("error", "验证失败，请重试");
    } finally {
      setLoading(false);
    }
  };

  const handleResend = async () => {
    try {
      const { code, msg } = await api({
        path: "/api/get-auth-code",
        method: "post",
        params: { email, deviceId: await authService.getDeviceId() },
      });

      if (code === 0) {
        setCountdown(60);
        toast("success", "验证码已重新发送");
      } else {
        toast("error", msg);
      }
    } catch (error) {
      console.error("发送失败:", error);
      toast("error", "发送失败，请重试");
    }
  };

  return (
    <ContentLayout>
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: theme.palette.background.paper,
          borderBottom: `1px solid ${theme.palette.divider}`,
          boxShadow: "none",
        }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            sx={{
              mr: 2,
              color: theme.palette.text.primary,
            }}
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Toolbar />
      <Container>
        <Box
          sx={{
            maxWidth: "md",
            mx: "auto",
            textAlign: "center",
            py: 4,
            mb: 4,
            width: "100%",
          }}
        >
          <Typography variant="h3" component="h1" color="text.primary">
            验证邮箱
          </Typography>
        </Box>

        <Box sx={{ maxWidth: "md", mx: "auto", width: "100%" }}>
          <Typography variant="body1" sx={{ mb: 3 }} color="text.primary">
            请输入发送到 {email} 的验证码
          </Typography>

          <TextField
            label="验证码"
            value={authCode}
            onChange={(e) => {
              setAuthCode(e.target.value);
              setValidateStatus("");
              setValidHelpText("");
            }}
            required
            fullWidth
            error={validateStatus === "error"}
            helperText={validHelpText}
            sx={{ mb: 3 }}
            inputRef={inputRef}
            autoFocus
            inputMode="numeric"
          />

          <Button
            variant="contained"
            fullWidth
            disabled={authCode.length !== 6 || loading}
            onClick={handleVerify}
            sx={{
              py: 1.5,
              bgcolor: theme.palette.primary.main,
              "&:hover": {
                bgcolor: theme.palette.primary.dark,
              },
              "&.Mui-disabled": {
                bgcolor: theme.palette.action.disabledBackground,
                color: theme.palette.action.disabled,
              },
            }}
          >
            {loading ? <CircularProgress size={24} /> : "验证"}
          </Button>

          <Box sx={{ mt: 3, textAlign: "right" }}>
            {countdown > 0 ? (
              <Typography variant="body2" color="text.secondary">
                验证码已发送到邮箱，{countdown}秒后可重新发送
              </Typography>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ mr: 1 }}
                >
                  没有收到验证码？
                </Typography>
                <Button
                  variant="text"
                  onClick={handleResend}
                  sx={{
                    color: theme.palette.primary.main,
                    "&:hover": {
                      bgcolor: theme.palette.action.hover,
                    },
                  }}
                >
                  重新发送
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      </Container>
    </ContentLayout>
  );
};

export default AuthVerify;
