import React from "react";
import { Box, keyframes } from "@mui/material";
import logo from "@/assets/logo.png";

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const AppLoading: React.FC = () => {
  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "background.default",
      }}
    >
      <Box
        component="img"
        src={logo}
        alt="Loading"
        sx={{
          width: 64,
          height: 64,
          animation: `${rotate} 2s linear infinite`,
        }}
      />
    </Box>
  );
};

export default AppLoading;
