import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import api from "@/utils/api";
import { useToast } from "@/context/ToastContext";
import { VpnApp } from "@/utils/data";
import { cropImageToBase64 } from "@/utils/image";
import {
  Box,
  TextField,
  Button,
  CircularProgress,
  MenuItem,
  Avatar,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
  Container,
} from "@mui/material";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import SaveIcon from "@mui/icons-material/Save";
import ContentLayout from "@/layouts/ContentLayout";

const AppEdit = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [app, setApp] = useState<VpnApp>({
    id: 0,
    name: "",
    type: "",
    link: "",
    icon: "",
    priority: 0,
    requireLabels: [],
  });
  const [loading, setLoading] = useState(false);
  const { toast } = useToast();
  const [touched, setTouched] = useState({
    name: false,
    link: false,
    priority: false,
  });

  useEffect(() => {
    if (id) {
      loadApp(parseInt(id));
    }
  }, [id]);

  const loadApp = (appId: number) => {
    setLoading(true);
    api<VpnApp>({
      path: `/api/group/apps/${appId}`,
      authRequired: true,
    })
      .then(({ code, msg, data }) => {
        if (code === 0) {
          setApp(data);
        } else {
          toast("error", msg);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    const method = id ? "post" : "patch";
    const path = id ? `/api/group/apps/${id}` : "/api/group/apps";

    api<VpnApp>({
      path,
      method,
      authRequired: true,
      params: { ...app, id: parseInt(id || "0") },
    })
      .then(({ code, msg }) => {
        if (code === 0) {
          toast("success", id ? "应用已更新" : "应用已创建");
          navigate("/apps");
        } else {
          toast("error", msg);
        }
      })
      .catch((error) => {
        console.error("保存应用时出错:", error);
        toast("error", "保存应用时发生错误");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setApp((prevApp) => ({ ...prevApp, [name]: value }));
  };

  const handleNumberChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setApp((prevApp) => ({ ...prevApp, [name]: parseInt(value) || 0 }));
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const url = URL.createObjectURL(file);
      cropImageToBase64(url, 1, 128).then((base64) => {
        if (base64) {
          setApp((prevApp) => ({ ...prevApp, icon: base64 }));
        }
      });
    }
  };

  const handleSelectChange = (e: SelectChangeEvent) => {
    const { name, value } = e.target;
    setApp((prevApp) => ({ ...prevApp, [name]: value }));
  };

  const options = [
    { name: "浏览器", value: "browser" },
    { name: "應用內", value: "webkit" },
    { name: "原生應用", value: "native" },
  ];

  // 定义面包屑配置
  const breadcrumbs = [
    { name: "控制面版", link: "/dashboard" },
    { name: "管理应用", link: "/apps" },
    { name: id ? "编辑应用" : "创建应用" },
  ];

  const handleBlur = (field: keyof typeof touched) => {
    setTouched(prev => ({ ...prev, [field]: true }));
  };

  if (loading && !app.id) {
    return (
      <ContentLayout breadcrumbs={breadcrumbs}>
        <Box
          maxWidth="sm"
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: 10,
            mx: "auto",
            width: "100%",
          }}
        >
          <CircularProgress />
        </Box>
      </ContentLayout>
    );
  }

  return (
    <ContentLayout breadcrumbs={breadcrumbs}>
      <Container
        maxWidth="sm"
        component="form"
        onSubmit={handleSubmit}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 3,
          mt: 10,
          mb: 10,
          mx: "auto",
          width: "100%",
        }}
      >
        <Box display="flex" justifyContent="center">
          <IconButton
            component="label"
            sx={{
              width: 128,
              height: 128,
              border: (theme) => `2px dashed ${theme.palette.divider}`,
            }}
          >
            {app.icon ? (
              <Avatar
                src={app.icon}
                alt="应用图标"
                variant="circular"
                sx={{ width: "100%", height: "100%" }}
              />
            ) : (
              <AddPhotoAlternateIcon sx={{ fontSize: 40 }} />
            )}
            <input
              type="file"
              hidden
              accept="image/*"
              onChange={handleFileChange}
            />
          </IconButton>
        </Box>

        <TextField
          fullWidth
          required
          name="name"
          label="应用名称"
          value={app.name}
          onChange={handleChange}
          onBlur={() => handleBlur('name')}
          placeholder="请输入应用名称"
          error={touched.name && app.name.length === 0}
          helperText={touched.name && app.name.length === 0 ? "应用名称不能为空" : ""}
        />

        <FormControl fullWidth required>
          <InputLabel>应用类型</InputLabel>
          <Select
            name="type"
            value={app.type}
            onChange={handleSelectChange}
            label="应用类型"
          >
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          fullWidth
          required
          name="link"
          label="应用链接"
          type="url"
          value={app.link}
          onChange={handleChange}
          onBlur={() => handleBlur('link')}
          placeholder="请输入应用链接"
          autoComplete="url"
          error={touched.link && !app.link.startsWith('http')}
          helperText={touched.link && !app.link.startsWith('http') ? "请输入有效的URL地址" : ""}
        />

        <TextField
          fullWidth
          required
          name="priority"
          label="优先级"
          type="number"
          value={app.priority}
          onChange={handleNumberChange}
          onBlur={() => handleBlur('priority')}
          placeholder="请输入优先级"
          slotProps={{
            htmlInput: {
              min: 0,
              max: 100
            }
          }}
          error={touched.priority && (app.priority < 0 || app.priority > 100)}
          helperText={touched.priority && (app.priority < 0 || app.priority > 100) ? "优先级范围: 0-100" : ""}
        />

        <Button
          type="submit"
          variant="contained"
          disabled={loading}
          startIcon={loading ? <CircularProgress size={20} /> : <SaveIcon />}
          sx={{ py: 1.5 }}
        >
          {loading ? "保存中..." : id ? "更新应用" : "创建应用"}
        </Button>
      </Container>
    </ContentLayout>
  );
};

export default AppEdit;
