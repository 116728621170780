import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Typography,
  Alert,
  AlertTitle,
  Button,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
} from "@mui/material";
import { Warning as WarningIcon } from "@mui/icons-material";
import ContentLayout from "@/layouts/ContentLayout";
import api from "@/utils/api";
import { useToast } from "@/context/ToastContext";
import { useAuth } from "@/context/AuthContext";

interface ConfirmStep {
  title: string;
  content: string;
}

const SelfDelete: React.FC = () => {
  const [confirmStep, setConfirmStep] = useState(0);
  const { toast } = useToast();
  const navigate = useNavigate();
  const { logout } = useAuth();

  const breadcrumbs = [
    { name: "控制面板", link: "/dashboard" },
    { name: "注销账号" },
  ];

  useEffect(() => {
    document.title = "注销账号";
  }, []);

  const handleConfirm = () => {
    if (confirmStep < 2) {
      setConfirmStep(confirmStep + 1);
      return;
    }

    api({
      path: "/api/self",
      method: "delete",
      authRequired: true,
    })
      .then(async ({ code, msg }) => {
        if (code === 0) {
          toast("success", "账号已成功注销");
          await logout();
          navigate("/");
        } else {
          toast("error", msg);
        }
      })
      .catch((error) => {
        console.error("注销账号时出错:", error);
        toast("error", "注销账号时发生错误");
      });
  };

  const handleCancel = () => {
    navigate("/dashboard");
  };

  const confirmSteps: ConfirmStep[] = [
    {
      title: "初次确认",
      content: "您确定要注销账号吗？此操作不可逆。",
    },
    {
      title: "二次确认",
      content:
        "删除账号会将您在开路者所有财产和可用服务，包括但不限于套餐，都将会被清除，请您再三确认。",
    },
    {
      title: "最终确认",
      content: "这是最后的确认，您确定要永久删除您的账号吗？",
    },
  ];

  const currentStep = confirmSteps[confirmStep];

  return (
    <ContentLayout breadcrumbs={breadcrumbs}>
      <Box maxWidth="md" sx={{ width: "100%", mx: "auto" }}>
        <Dialog
          open={true}
          maxWidth="sm"
          fullWidth
          PaperProps={{
            elevation: 0,
            sx: { borderRadius: 2 },
          }}
        >
          <DialogTitle
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              color: "error.main",
              pt: 3,
            }}
          >
            <Box display="flex" alignItems="center" gap={1}>
              <WarningIcon color="error" />
              <Typography variant="h6" component="span">
                {currentStep.title}
              </Typography>
            </Box>
          </DialogTitle>

          <DialogContent>
            <Alert
              severity="error"
              variant="outlined"
              sx={{
                my: 2,
                "& .MuiAlert-message": {
                  width: "100%",
                },
              }}
            >
              <AlertTitle>警告</AlertTitle>
              {currentStep.content}
            </Alert>
          </DialogContent>

          <DialogActions sx={{ p: 3 }}>
            <Stack direction="row" spacing={2}>
              <Button variant="outlined" onClick={handleCancel} size="large">
                取消
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={handleConfirm}
                size="large"
              >
                {confirmStep < 2 ? "下一步" : "确认注销"}
              </Button>
            </Stack>
          </DialogActions>
        </Dialog>
      </Box>
    </ContentLayout>
  );
};

export default SelfDelete;
