import { Navigate } from "react-router-dom";
import QuickStart from "../pages/QuickStart";
import MyService from "../pages/MyService";
import Purchase from "../pages/Purchase";
import DeviceList from "../pages/DeviceList";
import OrderList from "../pages/OrderList";
import MemberList from "../pages/MemberList";
import Pay from "../pages/Pay";
import AppList from "../pages/AppList";
import AppEdit from "../pages/AppEdit";
import MemberAdd from "../pages/MemberAdd";
import CouponConsume from "../pages/CouponConsume";
import SelfDelete from "../pages/SelfDelete";
import Error404 from "../pages/Error404";
import PayConfirm from "../pages/PayConfirm";
import AuthVerify from "@/pages/AuthVerify";
import AuthLogin from "@/pages/AuthLogin";
import Logout from "@/pages/Logout";
import AuthDeviceLogin from "@/pages/AuthDeviceLogin";
import { AccountLayout } from "@/layouts/AccountLayout";
import { lazy, Suspense, useMemo } from "react";
import AppLoading from "@/components/AppLoading";
import AboutUs from "@/pages/AboutUs";
import { isApp } from "@/bridge/client";
import { RouteGuard } from '@/components/RouteGuard';

export type AppNav = "account" | "mini-apps" | "connect" | "message";

const getNavComponents = (isApp: boolean) => ({
  account: lazy(() => import("@/pages/Dashboard")),
  ...(isApp && {
    "mini-apps": lazy(() => import("@/pages/AppMiniApps")),
    connect: lazy(() => import("@/pages/AppVpnConnect")),
  }),
});

export const CachedNavComponent = () => {
  const { appNav } = currentRoute();
  const components = useMemo(() => getNavComponents(isApp), []);

  return (
    <>
      {Object.entries(components).map(([nav, Component]) => (
        <div
          key={nav}
          style={{
            display: appNav === nav ? "block" : "none",
            height: "100%",
          }}
        >
          <Suspense fallback={<AppLoading />}>
            <Component />
          </Suspense>
        </div>
      ))}
    </>
  );
};

export interface RouteConfig {
  name: string;
  path: string;
  pageNoteId?: string;
  element: React.ReactElement;
  children?: RouteConfig[];
  appNav?: AppNav;
}

export const routes: RouteConfig[] = [
  {
    name: "控制台",
    path: "/",
    element: (
      <RouteGuard requireAuth>
        <AccountLayout />
      </RouteGuard>
    ),
    appNav: "account",
    children: [
      {
        name: "首页",
        path: "",
        element: <Navigate to="/dashboard" replace />,
      },
      {
        name: "控制台",
        path: "dashboard",
        pageNoteId: "vpn-account",
        element: <CachedNavComponent />,
        appNav: "account",
      },
      {
        name: "我的服务",
        path: "my-service",
        pageNoteId: "vpn-my-service",
        element: <MyService />,
      },
      {
        name: "购买服务",
        path: "purchase",
        pageNoteId: "vpn-purchase",
        element: <Purchase />,
      },
      {
        name: "兑换优惠券",
        path: "x",
        pageNoteId: "vpn-coupon-consume",
        element: <CouponConsume />,
      },
      {
        name: "设备管理",
        path: "devices",
        pageNoteId: "vpn-device-list",
        element: <DeviceList />,
      },
      {
        name: "订单记录",
        path: "orders",
        pageNoteId: "vpn-order-list",
        element: <OrderList />,
      },
      {
        name: "成员管理",
        path: "members",
        pageNoteId: "vpn-member-list",
        element: <MemberList />,
      },
      {
        name: "添加成员",
        path: "members/add",
        pageNoteId: "vpn-member-add",
        element: <MemberAdd />,
      },
      {
        name: "应用管理",
        path: "apps",
        pageNoteId: "vpn-app-list",
        element: <AppList />,
      },
      {
        name: "创建应用",
        path: "apps/create",
        pageNoteId: "vpn-app-add",
        element: <AppEdit />,
      },
      {
        name: "编辑应用",
        path: "apps/:id",
        pageNoteId: "vpn-app-edit",
        element: <AppEdit />,
      },
      {
        name: "关于我们",
        path: "about-us",
        element: <AboutUs />,
      },
      {
        name: "账号注销",
        path: "self-delete",
        element: <SelfDelete />,
      },
      {
        name: "退出",
        path: "logout",
        element: <Logout />,
      },
    ],
  },
  {
    name: "应用",
    path: "/mini-apps",
    element: isApp ? <CachedNavComponent /> : <Navigate to="/" replace />,
    appNav: "mini-apps",
  },
  {
    path: "/vpn",
    name: "连接",
    element: isApp ? <CachedNavComponent /> : <Navigate to="/" replace />,
    appNav: "connect",
  },
  {
    name: "登录",
    path: "login",
    element: (
      <RouteGuard>
        <AuthLogin />
      </RouteGuard>
    ),
    pageNoteId: "vpn-welcome",
  },
  {
    name: "验证码登录",
    path: "auth-verify",
    element: <AuthVerify />,
  },
  {
    name: "设备登录",
    path: "device-login",
    element: <AuthDeviceLogin />,
  },

  {
    name: "快速下单页",
    path: "quick-start",
    pageNoteId: "vpn-quick-start",
    element: <QuickStart />,
  },

  {
    name: "付款意图",
    path: "pay/order",
    pageNoteId: "vpn-pay",
    element: <Pay />,
  },
  {
    name: "支付结果确认",
    path: "pay/confirming",
    pageNoteId: "vpn-pay-confirm",
    element: <PayConfirm />,
  },
  {
    name: "404页面",
    path: "*",
    element: <Error404 />,
  },
];

interface RouteMatchResult {
  route: RouteConfig;
  score: number;
  parent?: RouteConfig;
}

export const currentRoute = (location?: Location) => {
  let pathSegments: string[] = [];
  if (!location) {
    pathSegments = window.location.pathname.split("/").filter(Boolean);
  } else {
    pathSegments = location.pathname.split("/").filter(Boolean);
  }

  // 递归检查路由及其子路由
  const findMatchingRoute = (
    routes: RouteConfig[],
    segments: string[],
    parentPath = "",
    parentRoute?: RouteConfig
  ): RouteMatchResult | null => {
    let bestMatch: RouteMatchResult | null = null;

    for (const route of routes) {
      if (route.path === "*") continue;

      const fullPath =
        parentPath +
        (route.path.startsWith("/") ? route.path : `/${route.path}`);
      const routeSegments = fullPath.split("/").filter(Boolean);
      let matchScore = 0;
      let isMatch = true;

      if (routeSegments.length > segments.length) {
        continue;
      }

      for (let i = 0; i < routeSegments.length; i++) {
        const routeSeg = routeSegments[i];
        const pathSeg = segments[i];

        if (routeSeg.startsWith(":")) {
          matchScore += 1;
        } else if (routeSeg === pathSeg) {
          matchScore += 2;
        } else {
          isMatch = false;
          break;
        }
      }

      if (isMatch && (!bestMatch || matchScore > bestMatch.score)) {
        bestMatch = {
          route,
          score: matchScore,
          parent: parentRoute,
        };
      }

      if (route.children) {
        const childMatch = findMatchingRoute(
          route.children,
          segments,
          fullPath,
          route
        );
        if (childMatch && (!bestMatch || childMatch.score > bestMatch.score)) {
          bestMatch = childMatch;
        }
      }
    }

    return bestMatch;
  };

  const matchResult = findMatchingRoute(routes, pathSegments);
  return {
    route: matchResult?.route,
    appNav: matchResult?.route?.appNav,
    parent: matchResult?.parent,
  };
};
