import React, { createContext, useContext, useEffect, useState } from "react";

interface NetworkContextType {
  isOnline: boolean;
  lastCheckTime: number;
}

const NetworkContext = createContext<NetworkContextType>({
  isOnline: true, // 默认假设在线
  lastCheckTime: 0,
});

const CHECK_URLS = [
  "https://captive.apple.com/",
  "https://www.apple.com/library/test/success.html",
];

// 添加检查单个 URL 的函数
const checkSingleUrl = (url: string): Promise<boolean> => {
  return new Promise((resolve) => {
    const iframe = document.createElement("iframe");
    const timeoutId = setTimeout(() => {
      document.body.removeChild(iframe);
      resolve(false);
    }, 5000); // 5秒超时

    iframe.style.display = "none";
    iframe.onload = () => {
      clearTimeout(timeoutId);
      document.body.removeChild(iframe);
      resolve(true);
    };
    iframe.onerror = () => {
      clearTimeout(timeoutId);
      document.body.removeChild(iframe);
      resolve(false);
    };

    iframe.src = url;
    document.body.appendChild(iframe);
  });
};

export const NetworkProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [networkState, setNetworkState] = useState<NetworkContextType>({
    isOnline: true,
    lastCheckTime: Date.now(),
  });

  const checkNetwork = async () => {
    try {
      // 使用 iframe 方式并行检查所有 URL
      const results = await Promise.all(
        CHECK_URLS.map((url) => checkSingleUrl(url))
      );

      // 只要有一个成功就认为在线
      const isOnline = results.some((result) => result === true);

      setNetworkState({
        isOnline,
        lastCheckTime: Date.now(),
      });

      //console.debug("Network check results:", { results, isOnline });
    } catch (error) {
      console.error("Network check failed:", error);
      setNetworkState((_prev) => ({
        isOnline: false,
        lastCheckTime: Date.now(),
      }));
    }
  };

  // 初始检查和定期检查
  useEffect(() => {
    checkNetwork();

    const intervalId = setInterval(checkNetwork, 30000);

    // 监听在线状态变化
    const handleOnline = () => {
      checkNetwork();
    };
    const handleOffline = () => {
      setNetworkState((prev) => ({
        ...prev,
        isOnline: false,
        lastCheckTime: Date.now(),
      }));
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      clearInterval(intervalId);
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  return (
    <NetworkContext.Provider value={networkState}>
      {children}
    </NetworkContext.Provider>
  );
};

// 自定义 hook 用于获取网络状态
export const useNetwork = () => {
  const context = useContext(NetworkContext);
  if (context === undefined) {
    throw new Error("useNetwork must be used within a NetworkProvider");
  }
  return context;
};
