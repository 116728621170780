import React from "react";
import { Outlet } from "react-router-dom";
import { Box } from "@mui/material";

export const AccountLayout: React.FC = () => {
  return (
    <Box sx={{ flex: 1 }}>
      <Outlet />
    </Box>
  );
};
