import React, { useEffect, useState } from "react";
import { Button, Box, Alert, Container } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { VpnPlan } from "@/utils/data";
import api, { ResponseList } from "@/utils/api";
import { useToast } from "@/context/ToastContext";
import ContentLayout from "@/layouts/ContentLayout";
import PlanCard from "@/components/PlanCard";
import { useNavigate } from "react-router-dom";
import { useMyService } from "@/context/MyServiceContext";
import { useAuth } from "@/context/AuthContext";
import SetMyService from "@/components/SetMyService";
import PayerServiceCard from "@/components/PayerServiceCard";
import { usePay } from "@/hooks/usePay";

const Purchase: React.FC = () => {
  const { serviceId, loadService, expired } = useMyService();
  const navigate = useNavigate();
  const { toast } = useToast();
  const [plans, setPlans] = useState<VpnPlan[]>([]);
  const [plan, setPlan] = useState<VpnPlan | null>(null);
  const { isPayer } = useAuth();
  const [err, setErr] = useState<string>("");
  const { seatQuota } = useMyService();
  const [serviceChoosed, setServiceChoosed] = useState(false);

  const { pay } = usePay();

  useEffect(() => {
    if (!isPayer) {
      navigate("/dashboard");
    }
  }, [isPayer, navigate]);

  useEffect(() => {
    if (serviceId && !expired) {
      setServiceChoosed(true);
    }
  }, [serviceId, expired]);

  useEffect(() => {
    loadService();
  }, []);

  useEffect(() => {
    const loadPlans = () => {
      api<ResponseList<VpnPlan>>({
        path: `/api/plans/${serviceId}`,
        method: "get",
      })
        .then(({ code, msg, data }) => {
          if (code === 0) {
            setPlans(data.items.sort((a, b) => a.price - b.price));
            if (data.items.length > 0) {
              setPlan(data.items[data.items.length - 1]);
            }
          } else {
            toast("error", msg);
          }
        })
        .catch(() => {
          toast("error", "加載失敗");
        });
    };

    if (serviceId) {
      loadPlans();
    }
  }, [toast, serviceId]);

  const createOrder = () => {
    setErr("");

    api<{ uuid: string }>({
      path: "/api/orders",
      method: "patch",
      params: {
        planId: plan?.id,
        quantity: seatQuota,
      },
    })
      .then(({ code, msg, data }) => {
        if (code === 0) {
          pay(data.uuid);
        } else {
          setErr(msg);
        }
      })
      .catch(() => {
        console.error("創建訂單時發生錯誤");
        setErr("請求發生錯誤，請重試");
      });
  };

  // 修改套餐选择的处理逻辑
  const handlePlanSelect = (selectedPlan: VpnPlan) => {
    setPlan(selectedPlan);
    console.log("Selected plan:", selectedPlan); // 用于调试
  };

  const renderSetMyService = () => (
    <Container maxWidth="lg">
      <Box mb={4}>
        <SetMyService
          onSuccess={async () => {
            await loadService();
            setServiceChoosed(true);
          }}
        />
      </Box>
    </Container>
  );

  const renderPurchasePlans = () => (
    <Box mb={4} sx={{ width: "100%", mx: "auto", maxWidth: "md" }}>
      <PayerServiceCard
        action={
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate("/my-service?change")}
          >
            修改方案
          </Button>
        }
      />

      <Container maxWidth="md">
        <Grid container spacing={2}>
          {plans.map((p) => (
            <Grid
              component="div"
              size={{
                xs: 12,
                sm: 6,
                md: 4,
              }}
              key={p.id}
            >
              <PlanCard
                plan={p}
                seats={seatQuota}
                isSelected={plan?.id === p.id}
                onClick={() => handlePlanSelect(p)}
              />
            </Grid>
          ))}
        </Grid>
      </Container>

      {err && (
        <Alert severity="error" sx={{ mt: 2 }}>
          {err}
        </Alert>
      )}

      <Box mt={4} textAlign="center">
        <Button
          variant="contained"
          size="large"
          disabled={!plan}
          onClick={createOrder}
          sx={{ minWidth: "10rem" }}
        >
          下一步
        </Button>
      </Box>
    </Box>
  );

  return (
    <ContentLayout
      breadcrumbs={[{ name: "控制面版", link: "/dashboard" }, { name: "充值" }]}
    >
      {serviceChoosed ? renderPurchasePlans() : renderSetMyService()}
    </ContentLayout>
  );
};

export default Purchase;
