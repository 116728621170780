import { useState, useCallback } from "react";

export function useThemeMode() {
  const [forceMode, setForceMode] = useState<"dark" | "light" | null>(null);

  const setMode = useCallback((mode: "dark" | "light") => {
    const classList = document.documentElement.classList;
    classList.remove("dark", "light");
    classList.add(mode);
    setForceMode(mode);
  }, []);

  const getMode = useCallback(() => {
    if (!forceMode) {
      return window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches
        ? "light"
        : "dark";
    }
    return forceMode;
  }, [forceMode]);

  const toggleMode = useCallback(() => {
    if (!forceMode) {
      setForceMode(getMode() === "dark" ? "light" : "dark");
    }
    setMode(forceMode === "dark" ? "light" : "dark");
  }, [forceMode, setMode, getMode]);

  return {
    toggleMode,
    setMode,
    getMode,
  };
}
