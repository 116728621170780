import { Card, CardContent, Typography, styled, Box } from "@mui/material";
import { VpnPlan } from "@/utils/data";
import { formatMoney } from "@/utils";

const StyledCard = styled(Card)(({ theme }) => ({
  cursor: "pointer",
  transition: "0.3s",
  position: "relative",
  overflow: "visible",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  border: `1px solid ${theme.palette.divider}`,
  "&:hover": {
    transform: "translateY(-4px)",
    boxShadow: theme.shadows[4],
    borderColor: theme.palette.primary.main,
  },
}));

const Ribbon = styled("div")(({ theme }) => ({
  position: "absolute",
  right: 0,
  top: 0,
  width: "100px",
  height: "100px",
  overflow: "hidden",
  "&::before": {
    content: '"已选择"',
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "150px",
    height: "30px",
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    transform: "rotate(45deg) translateY(-20px) translateX(50px)",
    boxShadow: "0 5px 10px rgba(0,0,0,0.1)",
    fontWeight: "bold",
    fontSize: "14px",
  },
}));

interface PlanCardProps {
  plan: VpnPlan;
  seats: number;
  isSelected: boolean;
  onClick: () => void;
}

const PlanCard = ({ plan, seats, isSelected, onClick }: PlanCardProps) => {
  return (
    <StyledCard
      raised={isSelected}
      onClick={onClick}
      sx={{
        bgcolor: isSelected ? "action.selected" : "background.paper",
        borderColor: isSelected ? "primary.main" : "divider",
        borderWidth: isSelected ? 2 : 1,
      }}
    >
      {isSelected && <Ribbon />}
      <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
        <Typography variant="h6" gutterBottom>
          {plan.name}
        </Typography>
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          mt: 'auto'  // 将价格信息推到底部
        }}>
          <Typography color="text.secondary">
            ${formatMoney(plan.price)} x {seats} 人
          </Typography>
          <Typography variant="h6" color="primary">
            ${formatMoney(plan.price * seats)}
          </Typography>
        </Box>
      </CardContent>
    </StyledCard>
  );
};

export default PlanCard; 