import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Typography,
  Alert,
  AlertTitle,
  Button,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
} from "@mui/material";
import { Warning as WarningIcon } from "@mui/icons-material";
import ContentLayout from "@/layouts/ContentLayout";
import { useAuth } from "@/context/AuthContext";

const Logout: React.FC = () => {
  const navigate = useNavigate();
  const { logout } = useAuth();

  const breadcrumbs = [
    { name: "控制面板", link: "/dashboard" },
    { name: "退出登录" },
  ];

  const handleLogout = async () => {
    await logout();
    navigate("/");
  };

  const handleCancel = () => {
    navigate(-1);
  };

  return (
    <ContentLayout breadcrumbs={breadcrumbs}>
      <Box maxWidth="md" sx={{ width: "100%", mx: "auto" }}>
        <Dialog
          open={true}
          maxWidth="sm"
          fullWidth
          PaperProps={{
            elevation: 0,
            sx: { borderRadius: 2 },
          }}
        >
          <DialogTitle
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              color: "warning.main",
              pt: 3,
            }}
          >
            <Box display="flex" alignItems="center" gap={1}>
              <WarningIcon color="warning" />
              <Typography variant="h6" component="span">
                确认退出
              </Typography>
            </Box>
          </DialogTitle>

          <DialogContent>
            <Alert
              severity="warning"
              variant="outlined"
              sx={{
                my: 2,
                "& .MuiAlert-message": {
                  width: "100%",
                },
              }}
            >
              <AlertTitle>提示</AlertTitle>
              退出登录后，您需要重新进行身份验证才能访问您的账户。这可能需要一些时间，您确定要退出吗？
            </Alert>
          </DialogContent>

          <DialogActions sx={{ p: 3 }}>
            <Stack direction="row" spacing={2}>
              <Button variant="outlined" onClick={handleCancel} size="large">
                取消
              </Button>
              <Button
                variant="contained"
                color="warning"
                onClick={handleLogout}
                size="large"
              >
                确认退出
              </Button>
            </Stack>
          </DialogActions>
        </Dialog>
      </Box>
    </ContentLayout>
  );
};

export default Logout;
