import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

// mb 為整型，返回的數字不保留小數
export const mbToHumanReadable = (mb: number): string => {
  if (mb < 1024) {
    return `${mb} MB`;
  } else if (mb < 1024 * 1024) {
    return `${(mb / 1024).toFixed(0)} GB`;
  } else {
    return `${(mb / (1024 * 1024)).toFixed(0)} TB`;
  }
};

// 查看
export const isExpired = (expiredAt: string): boolean => {
  const expiredMoment = dayjs(expiredAt);
  const now = dayjs();
  
  // 添加调试日志
  console.log('Expiration check:', {
    expiredAt,
    expiredMoment: expiredMoment.format(),
    now: now.format(),
    difference: expiredMoment.diff(now, 'seconds'),
    isExpired: now.isAfter(expiredMoment)
  });
  
  return now.isAfter(expiredMoment);
};

export const timeLeft = (expiredAt: string): string => {
  const now = dayjs();
  const targetDate = dayjs(expiredAt);
  const days = targetDate.diff(now, 'day');

  if (days < 0) {
    return "-";
  } else if (days >= 1) {
    return `${days}+ 天`;
  } else {
    const hours = targetDate.diff(now, 'hour');
    if (hours < 1) {
      const minutes = targetDate.diff(now, 'minute') % 60;
      return `${minutes}+ 分钟`;
    } else {
      return hours ? `${hours}+ 小时` : "-";
    }
  }
};

export const formatDate = (dateString: string): string => {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  };
  const formattedDate = new Intl.DateTimeFormat("zh-CN", options).format(date);
  return formattedDate.replace(/\//g, "-").replace(",", "").replace(" ", " ");
};

export const formatMoney = (amount: number): string => {
  return (amount / 100).toFixed(2);
};

export const secretEmail = (email: string): string => {
  return email.replace(/(\w{2})(\w+)(\w{2})@/, "$1***$3@");
};

export const base64Encode = (str: string): string => {
  return btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (_match, p1) {
      return String.fromCharCode(parseInt(p1, 16));
    })
  );
};

export const base64Decode = (str: string): string => {
  return decodeURIComponent(
    Array.prototype.map
      .call(atob(str), function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
};

export function countryFlag(countryCode: string, size?: number): string {
  const url = `https://flagsapi.com/${countryCode}/flat/${size || 64}.png`;
  // console.debug(`${countryCode} flag is ${url}`)
  return url;
}

export function bitReadable(v: number): string {
  const i = v == 0 ? 0 : Math.floor(Math.log(v) / Math.log(1024));
  return (
    +(v / Math.pow(1024, i)).toFixed(2) * 1 +
    " " +
    ["b", "Kb", "Mb", "Gb", "Tb"][i]
  );
}


export function getBrowserTimezone(): string {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}