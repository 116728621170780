import { useEffect } from "react";
import { useCustomerEvent } from "./useCustomerEvent";

interface UsePayConfirmTrackerProps {
  status: "success" | "failed" | null;
}

export const usePayConfirmTracker = ({
  status,
}: UsePayConfirmTrackerProps) => {
  const { sendEvent } = useCustomerEvent();
  useEffect(() => {
    if (status !== null) {
      sendEvent("pay_confirm", {
        status,
        description: `支付结果: ${status === "success" ? "成功" : "失败"}`,
      });
    }
  }, [sendEvent, status]);
}; 
