import cache from './cache';

const LOCKER_PREFIX = 'locker:';

const locker = {
  /**
   * 检查指定key是否被锁定
   * @param key - 锁定的键名（可以是页面名称或note ID）
   * @returns boolean - 如果被锁定返回true，否则返回false
   */
  isLocked: async (key: string | number): Promise<boolean> => {
    const cacheKey = `${LOCKER_PREFIX}${key}`;
    return (await cache.get<boolean>(cacheKey)) === true;
  },

  /**
   * 锁定指定的key一段时间
   * @param key - 要锁定的键名（可以是页面名称或note ID）
   * @param seconds - 锁定的秒数
   */
  lock: async (key: string | number, seconds: number): Promise<void> => {
    const cacheKey = `${LOCKER_PREFIX}${key}`;
    await cache.set(cacheKey, true, seconds);
  },

  /**
   * 解除指定key的锁定
   * @param key - 要解锁的键名
   */
  unlock: async (key: string | number): Promise<void> => {
    const cacheKey = `${LOCKER_PREFIX}${key}`;
    await cache.del(cacheKey);
  },

};

export default locker; 