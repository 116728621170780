import { useState, useEffect, useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  TextField,
  Button,
  Typography,
  CircularProgress,
  Container,
  Avatar,
} from "@mui/material";
import { isEmail } from "@/utils/valid";
import { useToast } from "@/context/ToastContext";
import api from "@/utils/api";
import cache from "@/utils/cache";
import ContentLayout from "@/layouts/ContentLayout";
import logoImage from "@/assets/logo.png";
import { useBasicInfo } from "@/context/BasicInfoContext";
import { actions, isWeb } from "@/bridge/client";
import { useAuth } from "@/context/AuthContext";

const AuthLogin = () => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const { toast } = useToast();
  const { deviceId } = useAuth();
  const navigate = useNavigate();
  const inputRef = useRef<HTMLInputElement>(null);
  const { links, version } = useBasicInfo();

  useEffect(() => {
    let mounted = true;

    const loadCachedEmail = async () => {
      try {
        const cachedEmail = await cache.get<string>("lastEmail");
        if (mounted && cachedEmail) {
          setEmail(cachedEmail);
        }
      } catch (error) {
        console.error("Failed to load cached email:", error);
      }
    };

    loadCachedEmail();

    return () => {
      mounted = false;
    };
  }, []);

  // 单独处理自动聚焦
  useEffect(() => {
    // 添加延迟确保DOM已经渲染
    const timer = setTimeout(() => {
      console.log("Trying to focus input:", inputRef.current); // 调试日志
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 100);

    return () => clearTimeout(timer);
  }, []); // 空依赖数组，只在组件挂载时执行一次

  const disabled = useMemo(() => {
    return !isEmail(email);
  }, [email]);

  const handleGetAuthCode = async () => {
    setLoading(true);
    await cache.set("lastEmail", email, -1);

    try {
      const deviceInfo = await actions.getDeviceInfo();
      const { code, msg, data } = await api<{ authId: string }>({
        path: "/api/get-auth-code",
        method: "post",
        params: {
          email,
          deviceId: deviceId,
          deviceName: deviceInfo.name,
        },
      });

      if (code === 0 && data.authId) {
        navigate(`/auth-verify?email=${email}&authId=${data.authId}`);
      } else {
        toast("error", msg);
      }
    } catch (error) {
      console.error("获取验证码失败:", error);
      toast("error", "网络错误，请稍后重试");
    } finally {
      setLoading(false);
    }
  };

  return (
    <ContentLayout>
      <Container sx={{ minHeight: "70vh" }}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            mb: 2,
            mt: 2,
          }}
        >
          <Avatar
            src={logoImage}
            sx={{
              width: 120,
              height: 120,
              animation: "spin 10s linear infinite",
              "@keyframes spin": {
                "0%": {
                  transform: "rotate(0deg)",
                },
                "100%": {
                  transform: "rotate(360deg)",
                },
              },
            }}
          />
        </Box>
        <Box
          sx={{
            textAlign: "center",
            py: 4,
            mb: 4,
            width: "100%",
          }}
        >
          <Typography variant="h3" component="h1">
            登录 / 注册
          </Typography>
        </Box>

        <Box sx={{ maxWidth: "sm", mx: "auto", width: "100%" }}>
          <Typography variant="body1" sx={{ mb: 3 }}>
            请输入您的邮箱地址
          </Typography>

          <TextField
            label="邮箱"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            fullWidth
            inputRef={inputRef}
            autoFocus
            sx={{ mb: 3 }}
          />

          <Button
            variant="contained"
            fullWidth
            disabled={disabled || loading}
            onClick={handleGetAuthCode}
            sx={{ py: 1.5 }}
          >
            {loading ? <CircularProgress size={24} /> : "继续"}
          </Button>

          <Typography
            variant="body2"
            sx={{ mt: 3, mb: 10, color: "text.secondary" }}
          >
            继续即表示您同意我们的
            <Button
              variant="text"
              onClick={() => actions.webviewOpen(links?.user_privacy_url)}
            >
              隐私政策
            </Button>
          </Typography>
        </Box>

        {!isWeb && (
          <Typography
            variant="caption"
            sx={{
              position: "fixed",
              bottom: 16,
              right: 16,
              color: "text.disabled",
            }}
          >
            {version}
          </Typography>
        )}
      </Container>
    </ContentLayout>
  );
};

export default AuthLogin;
