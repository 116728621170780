import { useBasicInfo } from "@/context/BasicInfoContext";
import { useI18n } from "@/context/I18nContext";
import { useMemo } from "react";

const useLoading = () => {
  const { loading: i18nLoading } = useI18n();
  const { loading: baseInfoLoading } = useBasicInfo();

  const isLoading = useMemo(() => {
    return i18nLoading || baseInfoLoading;
  }, [i18nLoading, baseInfoLoading]);

  return {
    isLoading,
    loading: isLoading
  };
};

export default useLoading;
