import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://358bea3251809b5ed50838362d33e3ff@o4508380447047680.ingest.us.sentry.io/4508380451962880",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "localhost",
    "127.0.0.1",
    new RegExp(`^${window.location.origin}/api/`),
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

import { StrictMode } from "react";
import { createRoot } from "react-dom/client";

import { setLogLevel, setDisplayCallerLine } from "@/utils/console";

const needDebug = !!process.env.IsDev || !!process.env.IsTest;

setLogLevel(needDebug ? "debug" : "warn");
setDisplayCallerLine(needDebug);

import App from "./App.tsx";
import { ToastProvider } from "@/context/ToastContext.tsx";
import { BasicInfoProvider } from "@/context/BasicInfoContext.tsx";
import { I18nProvider } from "@/context/I18nContext.tsx";
import { NetworkProvider } from "@/context/NetworkContext.tsx";

createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <NetworkProvider>
      <ToastProvider>
        <I18nProvider>
          <BasicInfoProvider>
            <App />
          </BasicInfoProvider>
        </I18nProvider>
      </ToastProvider>
    </NetworkProvider>
  </StrictMode>
);
