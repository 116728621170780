import { useCallback, useEffect, useRef, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
} from "@mui/material";
import locker from "@/utils/locker";
import api, { ResponseList } from "@/utils/api";
import { PageNote } from "@/utils/data";
import { actions } from "@/bridge/client";

interface PageNoteDialogProps {
  pageName: string;
}

const PageNoteDialog = ({ pageName }: PageNoteDialogProps) => {
  const [displayNote, setDisplayNote] = useState<PageNote>();
  const container = useRef<HTMLDivElement>(null);
  const [isDialogReady, setIsDialogReady] = useState(false);

  useEffect(() => {
    const html = displayNote?.htmlBody;
    console.info(`page note html for ${pageName}: ${html}`);

    if (html && container.current && isDialogReady) {
      container.current.innerHTML = html;
    }
  }, [displayNote, isDialogReady]);

  useEffect(() => {
    const timer = setTimeout(async () => {
      if (await locker.isLocked(pageName)) {
        return;
      }
      api<ResponseList<PageNote>>({
        path: `/api/page-notes/${pageName}`,
        authRequired: false,
      })
        .then(async ({ data }) => {
          await locker.lock(pageName, 60 * 30);
          return data.items || [];
        })
        .then(async (rows: PageNote[]) => {
          for (const note of rows) {
            if (!(await locker.isLocked(note.id))) {
              setDisplayNote(note);
              break;
            }
          }
        })
        .catch(console.error);
    }, 3000);

    return () => clearTimeout(timer);
  }, [pageName]);

  const executeAction = useCallback(async (url: string) => {
    if (!url) return;

    const protocol = url.split("://")[0];
    if (protocol === "webview+http" || protocol === "webview+https") {
      actions.externalOpen(url.substring("webview+".length));
    } else if (protocol === "itms-apps") {
      actions.externalOpen("https" + url.substring("itms-apps".length));
    } else {
      actions.externalOpen(url);
    }
  }, []);

  const handleClose = useCallback(
    async (executeClickAction: boolean) => {
      if (!displayNote) return;

      await locker.lock(displayNote.id, displayNote.redisplayLockSeconds);
      if (executeClickAction && displayNote.clickAction) {
        executeAction(displayNote.clickAction);
      }
      setDisplayNote(undefined);
    },
    [displayNote, executeAction]
  );

  return (
    <Dialog
      open={!!displayNote}
      onClose={() => handleClose(false)}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        elevation: 0,
        sx: {
          borderRadius: 2,
          mt: 2,
          bgcolor: "background.paper",
          color: "text.primary",
        },
      }}
      TransitionProps={{
        onEntered: () => setIsDialogReady(true),
        onExit: () => setIsDialogReady(false),
      }}
    >
      <DialogTitle
        sx={{
          color: "text.primary",
          borderBottom: 1,
          borderColor: "divider",
        }}
      >
        {displayNote?.title}
      </DialogTitle>
      <DialogContent>
        <Box
          ref={container}
          sx={{
            my: 2,
            color: "text.primary",
            "& a": {
              color: "primary.main",
            },
            "& img": {
              maxWidth: "100%",
              height: "auto",
            },
          }}
        />
      </DialogContent>
      <DialogActions
        sx={{
          p: 2,
          borderTop: 1,
          borderColor: "divider",
        }}
      >
        <Button
          onClick={() => handleClose(false)}
          sx={{
            color: "text.secondary",
          }}
        >
          取消
        </Button>
        <Button variant="contained" onClick={() => handleClose(true)}>
          {displayNote?.clickAction ? "打开" : "确认"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PageNoteDialog;
