import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  TextField,
  Button,
  CircularProgress,
  Container,
} from "@mui/material";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import ContentLayout from "@/layouts/ContentLayout";
import { useToast } from "@/context/ToastContext";
import api from "@/utils/api";

const MemberAdd = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const { toast } = useToast();
  const navigate = useNavigate();

  const breadcrumbs = [
    { name: "控制面版", link: "/dashboard" },
    { name: "我的成员", link: "/members" },
    { name: "添加成员" },
  ];

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    api({
      path: "/api/group/members",
      method: "patch",
      authRequired: true,
      params: { loginId: email, loginType: "email" },
    })
      .then(({ code, msg }) => {
        if (code === 0) {
          toast("success", "成员已成功添加");
          navigate("/members");
        } else {
          toast("error", msg);
        }
      })
      .catch((error) => {
        console.error("添加成员时出错:", error);
        toast("error", "添加成员时发生错误");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <ContentLayout breadcrumbs={breadcrumbs}>
      <Container
        maxWidth="sm"
        component="form"
        onSubmit={handleSubmit}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 3,
          mt: 10,
          mb: 10,
          mx: "auto",
          width: "100%",
        }}
      >
        <TextField
          fullWidth
          label="邮箱地址"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          placeholder="成员的邮箱地址"
        />

        <Button
          type="submit"
          variant="contained"
          disabled={loading}
          startIcon={
            loading ? <CircularProgress size={20} /> : <PersonAddIcon />
          }
          sx={{ py: 1.5 }}
        >
          {loading ? "添加中..." : "添加成员"}
        </Button>
      </Container>
    </ContentLayout>
  );
};

export default MemberAdd;
