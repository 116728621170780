import React, { createContext, useCallback, useContext, useState } from "react";
import {
  AlertColor,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
  Snackbar,
  SnackbarContent,
  IconButton,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";

interface ToastContextType {
  toast: (severity: AlertColor, message: string, action?: React.ReactNode) => void;
  confirm: (message: string) => Promise<boolean>;
}

const ToastContext = createContext<ToastContextType>({
  toast: () => {},
  confirm: () => Promise.resolve(false),
});

export const useToast = () => useContext(ToastContext);

interface ToastProps {
  open: boolean;
  message: string;
  severity: AlertColor;
  onClose: () => void;
  action?: React.ReactNode;
}

const Toast: React.FC<ToastProps> = ({ open, message, severity, onClose, action }) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={3000}
      onClose={onClose}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      sx={{
        top: {
          xs: 'env(safe-area-inset-top, 0px)',
          sm: '24px'
        },
        right: {
          xs: '0px',
          sm: '24px'
        },
        left: {
          xs: '0px',
          sm: 'auto'
        },
      }}
    >
      <SnackbarContent
        message={message}
        action={
          <>
            {action}
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={onClose}
              sx={{
                ml: 1,
                opacity: 0.7,
                '&:hover': {
                  opacity: 1
                }
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
        sx={(theme) => ({
          backgroundColor: theme.palette[severity].main,
          color: '#fff',
          width: {
            xs: '100%',
            sm: 'auto'
          },
          maxWidth: {
            xs: '100%',
            sm: '600px'
          },
          borderRadius: {
            xs: 0,
            sm: theme.shape.borderRadius * 1.5
          },
          boxShadow: theme.palette.mode === 'dark' 
            ? '0 2px 8px rgba(0, 0, 0, 0.35)'
            : '0 2px 8px rgba(0, 0, 0, 0.15)',
          '& .MuiSnackbarContent-message': {
            padding: '8px 0'
          },
          '& .MuiSnackbarContent-action': {
            paddingLeft: 2,
            marginRight: -1
          }
        })}
      />
    </Snackbar>
  );
};

export const ToastProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState<AlertColor>("info");
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState("");
  const [confirmResolve, setConfirmResolve] =
    useState<(value: boolean) => void>();
  const theme = useTheme();
  const [action, setAction] = useState<React.ReactNode | undefined>(undefined);

  const handleClose = () => {
    setOpen(false);
  };

  const toast = useCallback((severity: AlertColor, message: string, action?: React.ReactNode) => {
    setSeverity(severity);
    setMessage(message);
    setAction(action);
    setOpen(true);
  }, []);

  const confirm = useCallback((message: string): Promise<boolean> => {
    return new Promise((resolve) => {
      setConfirmMessage(message);
      setConfirmResolve(() => resolve);
      setConfirmOpen(true);
    });
  }, []);

  const handleConfirm = () => {
    setConfirmOpen(false);
    confirmResolve?.(true);
  };

  const handleCancel = () => {
    setConfirmOpen(false);
    confirmResolve?.(false);
  };

  return (
    <ToastContext.Provider value={{ toast, confirm }}>
      {children}
      <Toast
        open={open}
        message={message}
        severity={severity}
        onClose={handleClose}
        action={action}
      />
      <Dialog
        open={confirmOpen}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: {
            borderRadius: theme.shape.borderRadius * 2,
            minWidth: 320,
            backgroundColor: theme.palette.background.paper,
            backgroundImage: "none",
          },
        }}
        sx={{
          "& .MuiBackdrop-root": {
            backgroundColor:
              theme.palette.mode === "dark"
                ? "rgba(0, 0, 0, 0.8)"
                : "rgba(0, 0, 0, 0.5)",
          },
        }}
      >
        <DialogContent sx={{ pt: 3, pb: 2 }}>
          <DialogContentText
            id="alert-dialog-description"
            sx={{
              color: "text.primary",
              textAlign: "center",
              fontSize: "1rem",
            }}
          >
            {confirmMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ px: 2, pb: 2 }}>
          <Button
            onClick={handleCancel}
            variant="outlined"
            sx={{
              minWidth: 80,
              borderRadius: theme.shape.borderRadius * 1.5,
            }}
          >
            取消
          </Button>
          <Button
            onClick={handleConfirm}
            variant="contained"
            autoFocus
            sx={{
              minWidth: 80,
              borderRadius: theme.shape.borderRadius * 1.5,
            }}
          >
            确定
          </Button>
        </DialogActions>
      </Dialog>
    </ToastContext.Provider>
  );
};
