import { useCallback } from "react";
import { useBasicInfo } from "@/context/BasicInfoContext";
import { actions, isDesktopApp, isMobileApp } from "@/bridge/client";
import { useActions } from "./useActions";
import { useNavigate } from "react-router-dom";
import { useAuth } from "@/context/AuthContext";

export const usePay = () => {
  const { links } = useBasicInfo();
  const { isAuthenticated } = useAuth();
  const { urlWithAuth } = useActions();
  const navigate = useNavigate();

  const pay = useCallback(
    async (orderId: string) => {
      if (!links?.pay_url) {
        return;
      }

      const url = `${links.pay_url}?orderId=${orderId}`;
      const authedUrl = isAuthenticated ? await urlWithAuth(url) : url;

      // 如果是桌面应用,使用外部浏览器打开支付链接
      if (isDesktopApp || isMobileApp) {
        actions.externalOpen(authedUrl);
        return;
      }
      navigate(`/pay/order?orderId=${orderId}`);
    },
    [links, isAuthenticated, urlWithAuth, navigate]
  );

  return {
    pay,
  };
};
