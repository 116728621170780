import { Bridge, VpnConnectionStatus } from "../defination";
import web_bridge from "./web_bridge";

const userAgent = navigator.userAgent;

const mockApp = localStorage.getItem("mock-app");

export const isMacOSDesktopApp =
  userAgent.includes("WaymakerDesktopMacOS") || mockApp === "macos";
export const isWindowsDesktopApp =
  userAgent.includes("WaymakerDesktopWindows") || mockApp === "windows";
export const isLinuxDesktopApp =
  userAgent.includes("WaymakerDesktopLinux") || mockApp === "linux";
export const isDesktopApp =
  isMacOSDesktopApp || isWindowsDesktopApp || isLinuxDesktopApp;
export const isAndroidApp =
  userAgent.includes("WaymakerMobileAndroid") || mockApp === "android";
export const isIosApp =
  userAgent.includes("WaymakerMobileiOS") || mockApp === "ios";
export const isMobileApp = isAndroidApp || isIosApp;
export const isApp = isDesktopApp || isMobileApp;
export const isWeb = !isDesktopApp && !isMobileApp;

export const webappVersion = import.meta.env.WEBAPP_VERSION || "1.0.0";


let bridge: Bridge;

if (!window.bridge) {
  bridge = web_bridge;
  window.bridge = bridge;
} else {
  bridge = window.bridge;
}

export default bridge;

export const actions = bridge.actions;
export const storage = bridge.storage;
export const vpn = bridge.vpn;
export const env = bridge.env;

export const vpnStatusName = (status: VpnConnectionStatus) => {
  switch (status) {
    case "connecting":
      return "连接中";
    case "connected":
      return "已连接";
    case "disconnecting":
      return "断开中";
    case "disconnected":
      return "已断开";
    case "invalid":
      return "连接无效";
    default:
      return "-";
  }
};


export const getFullVersion = async () => {
  const deviceInfo = await actions.getDeviceInfo();
  return `v${webappVersion}@${deviceInfo?.version}`;
}

export const isInV1App = (() => {
  // 判断是否在老的app内
  const ua = navigator.userAgent.toLowerCase();
  // ios的在app的内嵌webview中，userAgent是
  // "Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/15E148"
  const uaParts = navigator.userAgent.toLowerCase().split(" ");
  const browserName = uaParts[uaParts.length - 1];
  const isIosWebview = browserName.startsWith("mobile/");

  const inAppCookie = !!document.cookie
    .split(";")
    .find((cookie) => cookie.trim().startsWith("in-app="));

  const inV1App =
    inAppCookie ||
    isIosWebview ||
    ua.includes("waymaker") ||
    ua.includes("wgandroid") ||
    ua.includes("wgios");
  return inV1App && !isApp;
})();


export const forceInV1App = async () => {
  await storage.set("in-app", "1");
};