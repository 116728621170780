import React, {
  createContext,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from "react";
import { BasicInfo, BasicInfoLinks } from "@/utils/data";
import api from "@/utils/api";
import cache from "@/utils/cache";
import { useToast } from "./ToastContext";
import { getFullVersion, isApp, isInV1App } from "@/bridge/client";
import { authService } from "@/services/auth";

type BasicInfoContextType = {
  basicInfo: BasicInfo | undefined;
  loadBasicInfo: () => void;
  links: BasicInfoLinks;
  loading: boolean;
  version: string;
  deviceId: string;
};

const emptyLinks: BasicInfoLinks = {
  delete_account_url: "",
  user_privacy_url: "",
  manage_apps: "",
  manage_members: "",
  manage_servers: "",
  device_login: "",
  quick_start_url: "",
  seller_event_url: "",
  purchase_url: "",
  pay_url: "",
  change_service_url: "",
};

const BasicInfoContext = createContext<BasicInfoContextType>({
  basicInfo: undefined,
  loadBasicInfo: () => {},
  links: emptyLinks,
  loading: true,
  version: "",
  deviceId: "",
});
const useBasicInfo = (): BasicInfoContextType => {
  const context = React.useContext(BasicInfoContext);
  if (!context) {
    throw new Error("useBasicInfo must be used within a BasicInfoProvider");
  }
  return context;
};

const BasicInfoProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [basicInfo, setBasicInfo] = useState<BasicInfo>();
  const { toast } = useToast();
  const [loading, setLoading] = useState(true);
  const [version, setVersion] = useState("");
  const [deviceId, setDeviceId] = useState("");

  const loadBasicInfo = async () => {
    const cachedBasicInfo = await cache.get<BasicInfo>("basicInfo");
    if (cachedBasicInfo) {
      setBasicInfo(cachedBasicInfo);
      setLoading(false);
    }

    try {
      const { code, msg, data } = await api<BasicInfo>({
        path: "/api/basic-info",
        authRequired: false,
      });

      if (code === 0) {
        await cache.set("basicInfo", data, 60 * 60 * 24);
        setBasicInfo(data);
      } else {
        toast("error", msg);
      }
    } catch (error) {
      toast("error", "獲取服務時發生錯誤");
      console.error("獲取服務時發生錯誤:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadBasicInfo();
    
    // 获取版本信息
    getFullVersion().then((version) => {
      setVersion(version);
    });
    
    // 使用 authService 获取设备ID
    authService.getDeviceId().then((deviceId) => {
      setDeviceId(deviceId);
    });
  }, []);

  useEffect(() => {
    if (!isInV1App && !isApp) {
      const script = document.createElement("script");
      script.innerHTML = `
      (function (d, t) {
        var BASE_URL = "https://chat.anc.52j.me";
        var g = d.createElement(t), s = d.getElementsByTagName(t)[0];
        g.src = BASE_URL + "/packs/js/sdk.js";
        g.defer = true;
        g.async = true;
        s.parentNode.insertBefore(g, s);
        g.onload = function () {
          window.chatwootSDK.run({
            websiteToken: 'LyboJEpwrEWGr3EbN8GaP5yX',
            baseUrl: BASE_URL
          })
        }
      })(document, "script");
      `;
      document.body.appendChild(script);
    }
  }, []);

  const links = useMemo(() => {
    return basicInfo?.links || emptyLinks;
  }, [basicInfo]);

  // Add the loadBasicInfo function to the context value
  return (
    <BasicInfoContext.Provider
      value={{ basicInfo, loadBasicInfo, links, loading, version, deviceId }}
    >
      {children}
    </BasicInfoContext.Provider>
  );
};

export { useBasicInfo, BasicInfoProvider };
