import { Box, Pagination as MuiPagination, Typography, Select, MenuItem } from "@mui/material";

import { Pagi } from "@/utils/api";
import { useMemo } from "react";


interface PaginationProps {
  data: Pagi;
  onChangePage: (page: number) => void;
  onChangeSize?: (size: number) => void;
  pageSizeChoices?: number[];
}

const Pagination = ({
  data,
  onChangePage,
  onChangeSize,
  pageSizeChoices = [10, 20, 50],
}: PaginationProps) => {
  const totalPages = useMemo(() => {
    if (data.total === 0) return 0;
    return Math.ceil(data.total / data.pageSize);
  }, [data.total, data.pageSize]);

  const sizeChoices = useMemo(() => {
    return [...new Set([...pageSizeChoices, data.pageSize])].sort((a, b) => a - b);
  }, [pageSizeChoices, data]);

  return (
    <Box
      sx={{
        mt: 3,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 2,
      }}
    >
      {/* 第一行：分页控件 */}
      {data.total ? (
        <MuiPagination
          count={totalPages}
          page={data.page + 1}
          onChange={(_event, value) => onChangePage(value - 1)}
          color="primary"
          showFirstButton
          showLastButton
          size="large"
          siblingCount={1}
          boundaryCount={1}
        />
      ) : null}

      {/* 第二行：每页条数选择和总条数 */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 3,
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography 
            component="span"
            variant="body2"
            color="text.secondary"
            sx={{ mr: 1 }}
          >
            每页
          </Typography>
          <Select
            value={data.pageSize}
            onChange={(e) => onChangeSize?.(Number(e.target.value))}
            size="small"
            sx={{ minWidth: 80 }}
          >
            {sizeChoices.map((size) => (
              <MenuItem key={size} value={size}>
                {size}
              </MenuItem>
            ))}
          </Select>
          <Typography 
            component="span"
            variant="body2"
            color="text.secondary"
            sx={{ ml: 1 }}
          >
            条
          </Typography>
        </Box>
        <Typography 
          component="span"
          variant="body2"
          color="text.secondary"
        >
          共 {data.total} 条记录
        </Typography>
      </Box>
    </Box>
  );
};

export default Pagination;
