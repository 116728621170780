import { FC } from "react";
import { useNavigate } from "react-router-dom";
import ContentLayout from "@/layouts/ContentLayout";
import { Box, Typography, Button, Container } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";

const Error404: FC = () => {
  const navigate = useNavigate();

  return (
    <ContentLayout>
      <Container maxWidth="lg" sx={{ width: "100%" }}>
        <Box display="flex" flexDirection="column" alignItems="center" py={8}>
          <Typography
            variant="h1"
            component="h1"
            fontSize={48}
            mb={2}
            color="primary"
          >
            404
          </Typography>

          <Typography variant="h6" component="p" mb={4} color="text.secondary">
            抱歉，您访问的页面不存在
          </Typography>

          <Button
            variant="contained"
            startIcon={<HomeIcon />}
            onClick={() => navigate("/dashboard")}
            size="large"
          >
            返回首页
          </Button>
        </Box>
      </Container>
    </ContentLayout>
  );
};

export default Error404;
