import {
  Bridge,
  ArchType,
  EnvType,
  PlatformType,
  RouteConfig,
  VpnConnectionStatus,
  VpnConnectionStatusChangeEvent,
  VpnConnectionErrorEvent,
} from "../defination";
import LZString from "lz-string";

function getBrowserLanguage(): string {
  const lang =
    navigator.language ||
    (navigator as any).userLanguage ||
    (navigator as any).browserLanguage ||
    "zh-CN";
  return lang.toLowerCase().replace("_", "-");
}

let connectionStatus = "disconnected";
let currentRouteConfig: RouteConfig = {
  mode: "global",
  pac: {
    socks5Proxy: "",
    routeDomains: [],
    blockedDomains: [],
  },
  routeIps: [],
  dns: [],
  routeApps: [],
};

class BridgeEventEmitter {
  private handlers = new Map<string, Set<(data: any) => void>>();

  on(eventName: string, handler: (data: any) => void) {
    if (!this.handlers.has(eventName)) {
      this.handlers.set(eventName, new Set());
    }
    this.handlers.get(eventName)!.add(handler);
    return () => this.off(eventName, handler);
  }

  off(eventName: string, handler: (data: any) => void) {
    this.handlers.get(eventName)?.delete(handler);
  }

  emit(eventName: string, data: any) {
    this.handlers.get(eventName)?.forEach((handler) => handler(data));
  }
}

export const bridgeEventEmitter = new BridgeEventEmitter();

const bridge: Bridge = {
  vpn: {
    async connect(url: string) {
      console.log("Web环境模拟VPN连接:", url);
      connectionStatus = "connecting";
      bridgeEventEmitter.emit("vpn.status-change", {
        status: "connecting",
        message: `正在连接到 ${url}`,
      });
      await new Promise((resolve) => setTimeout(resolve, 2000));
      connectionStatus = "connected";
      bridgeEventEmitter.emit("vpn.status-change", {
        status: "connected",
        message: `已成功连接到 ${url}`,
      });
    },

    async disconnect() {
      console.log("Web环境模拟VPN断开连接");
      connectionStatus = "disconnecting";
      bridgeEventEmitter.emit("vpn.status-change", {
        status: "disconnecting",
        message: "正在断开连接",
      });
      await new Promise((resolve) => setTimeout(resolve, 500));
      connectionStatus = "disconnected";
      bridgeEventEmitter.emit("vpn.status-change", {
        status: "disconnected",
        message: "已断开连接",
      });
    },

    async currentState() {
      return {
        status: connectionStatus as VpnConnectionStatus,
        url: "",
      };
    },

    async getRoute() {
      return currentRouteConfig;
    },

    async setRoute(routeConfig: RouteConfig) {
      console.log("Web环境模拟设置路由:", routeConfig);
      currentRouteConfig = routeConfig;
    },

    async restoreRoute() {
      console.log("Web环境模拟重置路由配置");
      currentRouteConfig = {
        mode: "global",
        pac: {
          socks5Proxy: "",
          routeDomains: [],
          blockedDomains: [],
        },
        routeIps: [],
        dns: [],
        routeApps: [],
      };
    },

    onStatusChange(callback: (event: VpnConnectionStatusChangeEvent) => void) {
      return bridgeEventEmitter.on("vpn.status-change", callback);
    },

    onError(callback: (event: VpnConnectionErrorEvent) => void) {
      return bridgeEventEmitter.on("vpn.error", callback);
    },
  },

  storage: {
    async get<T>(key: string): Promise<T | null> {
      try {
        const prefixedKey = `v1_${key}`;
        console.debug(`[Storage] 尝试读取键: ${prefixedKey}`);
        const compressed = localStorage.getItem(prefixedKey);
        if (!compressed) {
          console.debug(`[Storage] 键 ${prefixedKey} 不存在`);
          return null;
        }

        const value = LZString.decompress(compressed);
        if (!value) {
          console.debug(`[Storage] 键 ${prefixedKey} 解压缩失败`);
          return null;
        }

        const parsed = JSON.parse(value) as T;
        console.debug(`[Storage] 成功读取键 ${prefixedKey}`);
        return parsed;
      } catch (error) {
        console.warn(`[Storage] 读取键 ${key} 失败:`, error);
        return null;
      }
    },

    async set<T>(key: string, value: T): Promise<void> {
      try {
        const prefixedKey = `v1_${key}`;
        const stringValue = JSON.stringify(value);
        const compressed = LZString.compress(stringValue);
        localStorage.setItem(prefixedKey, compressed);
      } catch (error) {
        console.error(`向 Storage 写入 ${key} 失败:`, error);
        throw new Error(`存储失败: ${(error as Error).message}`);
      }
    },

    async delete(key: string): Promise<void> {
      try {
        const prefixedKey = `v1_${key}`;
        localStorage.removeItem(prefixedKey);
      } catch (error) {
        console.error(`从 Storage 删除 ${key} 失败:`, error);
        throw new Error(`删除失败: ${(error as Error).message}`);
      }
    },

    async clear(): Promise<void> {
      try {
        localStorage.clear();
      } catch (error) {
        console.error("清空 Storage 失败:", error);
        throw new Error(`清空失败: ${(error as Error).message}`);
      }
    },

    async keys(): Promise<string[]> {
      try {
        return Object.keys(localStorage)
          .filter(key => key.startsWith('v1_'))
          .map(key => key.substring(3));
      } catch (error) {
        console.error("获取 Storage keys 失败:", error);
        return [];
      }
    },
  },

  env: {
    async get(): Promise<EnvType> {
      return (localStorage.getItem("APP_ENV") as EnvType) || "production";
    },
    async set(env: EnvType): Promise<void> {
      localStorage.setItem("APP_ENV", env);
    },
  },

  actions: {
    async getDeviceInfo() {
      return {
        name: "web",
        platform: "web" as PlatformType,
        arch: "x64" as ArchType,
        version: "999.0.0",
        lang: getBrowserLanguage(),
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };
    },

    async externalOpen(url: string) {
      window.open(url, "_blank");
    },

    async webviewOpen(
      url: string,
      _title?: string,
      _icon?: string,
      _headers?: Record<string, string>,
      _preloadJs?: string
    ) {
      window.open(url, "_blank");
    },

    async getInstalledApps() {
      return [];
    },
  },
};

export default bridge;
