import { Box, IconButton, TextField, styled } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const NumberInputWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.primary.main}`,
  boxShadow: `0px 0px 10px ${theme.palette.primary.main}`,
  "& .MuiTextField-root": {
    "& input": {
      textAlign: "center",
      border: "none",
      width: "120px",
      "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
        WebkitAppearance: "none",
        margin: 0,
      },
    },
  },
  width: "fit-content",
  margin: "0 auto",
}));

interface NumberInputFieldProps {
  value: number;
  minValue: number;
  onChange: (value: number) => void;
}

const NumberInputField = ({
  value,
  minValue,
  onChange,
}: NumberInputFieldProps) => {
  const decrease = () => {
    onChange(Math.max(value - 1, minValue));
  };

  const increase = () => {
    onChange(value + 1);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(parseInt(e.target.value || "1"));
  };

  return (
    <NumberInputWrapper>
      <IconButton onClick={decrease}>
        <RemoveIcon />
      </IconButton>
      <TextField
        type="number"
        value={value}
        slotProps={{
          htmlInput: {
            min: minValue,
          },
        }}
        onChange={handleInputChange}
      />
      <IconButton onClick={increase}>
        <AddIcon />
      </IconButton>
    </NumberInputWrapper>
  );
};

export default NumberInputField;
