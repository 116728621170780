import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Box,
  Container,
  CircularProgress,
  Typography,
  Avatar,
} from "@mui/material";
import ContentLayout from "@/layouts/ContentLayout";
import logoImage from "@/assets/logo.png";
import { useAuth } from "@/context/AuthContext";
import { authService } from "@/services/auth";
import { forceInV1App } from "@/bridge/client";

const AuthDeviceLogin = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const { login } = useAuth();

  useEffect(() => {
    const deviceId = searchParams.get("deviceId");
    const token = searchParams.get("token");
    const nextUrl = searchParams.get("next") || "/";

    const getPathFromUrl = (url: string): string => {
      try {
        if (url.startsWith("/")) {
          return url;
        }

        const urlObj = new URL(url);
        return `${urlObj.pathname}${urlObj.search}${urlObj.hash}`;
      } catch {
        return "/";
      }
    };

    const next = getPathFromUrl(nextUrl);

    const handleDeviceLogin = async () => {
      const forceInApp = !!searchParams.get("inApp");

      if (forceInApp) {
        await forceInV1App();
      }

      if (!deviceId || !token) {
        navigate(next);
        return;
      }

      try {
        await authService.forceSetDeviceId(deviceId);
        await login(token);
        navigate(next);
      } catch {
        navigate("/dashboard");
      }
    };

    handleDeviceLogin();
  }, [navigate, searchParams, login]);

  return (
    <ContentLayout>
      <Container>
        <Box
          sx={{
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: 4,
          }}
        >
          <Avatar
            src={logoImage}
            sx={{
              width: 120,
              height: 120,
              animation: "spin 10s linear infinite",
              "@keyframes spin": {
                "0%": {
                  transform: "rotate(0deg)",
                },
                "100%": {
                  transform: "rotate(360deg)",
                },
              },
            }}
          />
          <CircularProgress />
          <Typography variant="h6">正在登录设备...</Typography>
        </Box>
      </Container>
    </ContentLayout>
  );
};

export default AuthDeviceLogin;
