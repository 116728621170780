import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Button,
  Chip,
  CircularProgress,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import PersonIcon from "@mui/icons-material/Person";
import ContentLayout from "@/layouts/ContentLayout";
import { useToast } from "@/context/ToastContext";
import { useAuth } from "@/context/AuthContext";
import { UserMember } from "@/utils/data";
import api from "@/utils/api";

const MemberList = () => {
  const [members, setMembers] = useState<UserMember[]>([]);
  const [loading, setLoading] = useState(true);
  const { toast, confirm } = useToast();
  const { user } = useAuth();
  const navigate = useNavigate();

  const breadcrumbs = [
    { name: "控制面版", link: "/dashboard" },
    { name: "我的成员" },
  ];

  const loadMembers = () => {
    setLoading(true);
    api<{ items: UserMember[] }>({
      path: "/api/group/members",
      method: "get",
      authRequired: true,
    })
      .then(({ code, msg, data }) => {
        if (code === 0) {
          setMembers(data.items);
        } else {
          toast("error", msg);
        }
      })
      .catch((error) => {
        console.error("加载成员列表时出错:", error);
        toast("error", "加载成员列表时发生错误");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDeleteMember = async (memberId: number) => {
    if (await confirm("确定要删除这个成员吗？")) {
      api({
        path: `/api/group/members/${memberId}`,
        method: "delete",
        authRequired: true,
      })
        .then(({ code, msg }) => {
          if (code === 0) {
            toast("success", "成员已成功删除");
            setMembers((prevMembers) =>
              prevMembers.filter((member) => member.id !== memberId)
            );
          } else {
            toast("error", msg);
          }
        })
        .catch((error) => {
          console.error("删除成员时出错:", error);
          toast("error", "删除成员时发生错误");
        });
    }
  };

  useEffect(() => {
    loadMembers();
  }, []);

  return (
    <ContentLayout
      breadcrumbs={breadcrumbs}
      action={
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/members/add")}
        >
          添加
        </Button>
      }
    >
      <Box maxWidth="sm" sx={{ width: "100%", mx: "auto" }}>
        {loading ? (
          <Box display="flex" justifyContent="center" py={4}>
            <CircularProgress />
          </Box>
        ) : members.length === 0 ? (
          <Box
            display="flex"
            justifyContent="center"
            py={4}
            bgcolor="background.paper"
            borderRadius={1}
          >
            <Typography color="text.secondary">暂无成员</Typography>
          </Box>
        ) : (
          <List>
            {members.map((member) => (
              <ListItem
                key={member.id}
                secondaryAction={
                  member.id === user?.id ? (
                    <Chip label="本账号" color="primary" size="small" />
                  ) : (
                    <Button
                      startIcon={<DeleteIcon />}
                      color="error"
                      onClick={() => handleDeleteMember(member.id!)}
                      size="small"
                    >
                      删除
                    </Button>
                  )
                }
                sx={{
                  borderRadius: 1,
                  mb: 1,
                  "&:hover": {
                    bgcolor: "action.hover",
                  },
                }}
              >
                <ListItemText
                  primary={
                    <Box display="flex" alignItems="center" gap={1}>
                      <PersonIcon color="primary" />
                      <Typography variant="subtitle1">
                        {member.email}
                      </Typography>
                    </Box>
                  }
                  secondary={
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ mt: 1 }}
                    >
                      设备数：{member.deviceCount}
                    </Typography>
                  }
                />
              </ListItem>
            ))}
          </List>
        )}
      </Box>
    </ContentLayout>
  );
};

export default MemberList;
