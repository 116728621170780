import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import api from "@/utils/api";
import { useToast } from "@/context/ToastContext";
import { VpnApp } from "@/utils/data";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Button,
  CircularProgress,
  ListItemAvatar,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import ContentLayout from "@/layouts/ContentLayout";
import AppIcon from "@/components/AppIcon";
import { isApp, isInV1App } from "@/bridge/client";

const AppList = () => {
  const [apps, setApps] = useState<VpnApp[]>([]);
  const [loading, setLoading] = useState(true);
  const { toast, confirm } = useToast();
  const navigate = useNavigate();

  const loadApps = () => {
    setLoading(true);
    api<{ items: VpnApp[] }>({
      path: "/api/group/apps",
      method: "get",
      authRequired: true,
    })
      .then(({ code, msg, data }) => {
        if (code === 0) {
          setApps(data.items);
        } else {
          toast("error", msg);
        }
      })
      .catch((error) => {
        console.error("加载应用列表时出错:", error);
        toast("error", "加载应用列表时发生错误");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDeleteApp = async (appId: number) => {
    if (await confirm("确定要删除这个应用吗？")) {
      api({
        path: `/api/group/apps/${appId}`,
        method: "delete",
        authRequired: true,
      })
        .then(({ code, msg }) => {
          if (code === 0) {
            toast("success", "应用已成功删除");
            setApps((prevApps) => prevApps.filter((app) => app.id !== appId));
          } else {
            toast("error", msg);
          }
        })
        .catch((error) => {
          console.error("删除应用时出错:", error);
          toast("error", "删除应用时发生错误");
        });
    }
  };

  // 定义面包屑
  const breadcrumbs = [
    { name: "控制面版", link: "/dashboard" },
    { name: "管理应用" },
  ];

  useEffect(() => {
    loadApps();
  }, []);

  return (
    <ContentLayout
      breadcrumbs={breadcrumbs}
      action={
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/apps/create")}
        >
          添加
        </Button>
      }
    >
      <Box sx={{ mx: "auto", width: "100%", maxWidth: "md" }}>
        {loading ? (
          <Box display="flex" justifyContent="center" py={4}>
            <CircularProgress />
          </Box>
        ) : apps.length === 0 ? (
          <Box
            display="flex"
            justifyContent="center"
            py={4}
            bgcolor="background.paper"
            borderRadius={1}
          >
            <Typography color="text.secondary">暂无应用</Typography>
          </Box>
        ) : (
          <List>
            {apps.map((app) => (
              <ListItem
                key={app.id}
                secondaryAction={
                  <Button
                    startIcon={<DeleteIcon />}
                    variant="text"
                    onClick={() => handleDeleteApp(app.id)}
                  >
                    删除
                  </Button>
                }
                onClick={() => navigate(`/apps/${app.id}`)}
                sx={{ textDecoration: "none", color: "inherit" }}
              >
                <ListItemAvatar>
                  <AppIcon src={app.icon} alt={app.name} />
                </ListItemAvatar>
                <ListItemText
                  primary={app.name}
                  secondary={
                    <Box component="span" sx={{ display: 'block', mt: 1 }}>
                      <Typography 
                        component="span" 
                        variant="body2" 
                        color="text.secondary" 
                        sx={{ mr: 2 }}
                      >
                        类型: {app.type}
                      </Typography>
                      <Typography 
                        component="span" 
                        variant="body2" 
                        color="text.secondary"
                      >
                        优先级: {app.priority}
                      </Typography>
                    </Box>
                  }
                  sx={{ ml: 2 }}
                />
              </ListItem>
            ))}
          </List>
        )}

        {isInV1App && !isApp && (
          <Box display="flex" justifyContent="center" mt={3}>
            <Button
              component={Link}
              to="/apps/create"
              variant="outlined"
              startIcon={<AddIcon />}
            >
              创建新应用
            </Button>
          </Box>
        )}
      </Box>
    </ContentLayout>
  );
};

export default AppList;
