import React, { useEffect, useState, useMemo } from "react";
import { mbToHumanReadable, timeLeft } from "@/utils";
import ContentLayout from "@/layouts/ContentLayout";
import { useToast } from "@/context/ToastContext";
import api from "@/utils/api";
import {
  Box,
  Button,
  Paper,
  Stack,
  Typography,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Container,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import AddIcon from "@mui/icons-material/Add";
import GroupIcon from "@mui/icons-material/Group";
import EditIcon from "@mui/icons-material/Edit";
import CircularProgress from "@mui/material/CircularProgress";
import DiamondIcon from "@mui/icons-material/Diamond";
import NumberInputField from "@/components/NumberInputField";
import { useMyService } from "@/context/MyServiceContext";
import { useNavigate } from "react-router-dom";

interface Preview {
  serviceId: string;
  seats: number;
  expiredAt: string;
  seatsChangedAt?: string;
}

const MyService: React.FC = () => {
  const {
    service,
    loadService,
    seatsUsed,
    seatQuota,
    expiredAt,
    serviceId,
    isLoading,
    expired,
  } = useMyService();
  const navigate = useNavigate();
  const { toast } = useToast();
  const urlParams = new URLSearchParams(window.location.search);
  const change = urlParams.get("change") !== null;
  const [changing, setChanging] = useState(change);
  const [seats, setSeats] = useState(service?.content?.startUsers || 1);
  const [preview, setPreview] = useState<Preview>({
    seats: 0,
    expiredAt: "",
    serviceId,
  });

  const breadcrumbs = [
    { name: "控制面版", link: "/dashboard" },
    { name: "我的服務方案" },
  ];

  useEffect(() => {
    if (expired && !isLoading) {
      navigate("/purchase");
    }
  }, [expired, isLoading]);

  useEffect(() => {
    setSeats(seatQuota || 1);
    setPreview({
      seats: seatQuota || 1,
      expiredAt,
      serviceId,
    });
  }, [serviceId, seatQuota, expiredAt]);

  const minSeats = useMemo(() => {
    if (!service) return 1;
    const s1 = service.content.startUsers;
    const s2 = seatsUsed || 1;
    return Math.max(s1, s2);
  }, [service, seatsUsed]);

  const confirm = (dryRun: boolean) => {
    if (!service) return;

    api<Preview>({
      path: `/api/my-service?${dryRun ? "dryrun=true" : ""}`,
      method: "post",
      params: {
        serviceId: service.id,
        seats,
      },
    })
      .then(({ code, msg, data }) => {
        if (code == 0) {
          setPreview(data);
          if (!dryRun) {
            setChanging(false);
            toast("success", "变更成功");
            setTimeout(() => {
              loadService();
            }, 1);
          }
        } else {
          toast("error", msg);
        }
      })
      .catch(() => {
        toast("error", "网络错误");
      });
  };

  const canChangeSeats = (changedAt?: string): boolean => {
    if (!changedAt) return true;

    const lastChanged = new Date(changedAt);
    const now = new Date();

    // 检查是否在同一个月
    return !(
      lastChanged.getFullYear() === now.getFullYear() &&
      lastChanged.getMonth() === now.getMonth()
    );
  };

  const formatLastChangeTime = (changedAt?: string): string => {
    if (!changedAt) return "";
    return new Date(changedAt).toLocaleDateString("zh-CN", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  useEffect(() => {
    loadService();
  }, []);

  useEffect(() => {
    if (!isLoading && service === null) {
      navigate("/purchase");
    }
  }, [service, isLoading]);

  const renderServiceSummary = () => {
    if (!service) return null;

    return (
      <Container sx={{ mb: 2, mt: 5 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 2,
            mb: 4,
          }}
        >
          <DiamondIcon sx={{ color: "#00bfff", fontSize: 48 }} />
          <Typography variant="h5">{service?.content.name}</Typography>
        </Box>

        <Paper
          sx={{
            background:
              "linear-gradient(45deg, rgba(0,191,255,0.25) 0%, rgba(147,51,234,0.15) 100%)",
            p: { xs: 2, sm: 3 },
            border: "1px solid rgba(0,191,255,0.2)",
            borderRadius: 2,
          }}
        >
          <Grid container spacing={3}>
            <Grid size={{ xs: 12 }}>
              <Box
                sx={{
                  p: 2,
                  bgcolor: "rgba(255,255,255,0.1)",
                  borderRadius: 1,
                  height: "100%",
                }}
              >
                <Typography variant="subtitle2" color="text.secondary">
                  流量配额
                </Typography>
                <Typography variant="h6" sx={{ mt: 1, color: "primary.main" }}>
                  {mbToHumanReadable(service?.content.maxByteflowMbPerUser)} /
                  用户
                </Typography>
              </Box>
            </Grid>

            <Grid size={{ xs: 12 }}>
              <Box
                sx={{
                  p: 2,
                  bgcolor: "rgba(255,255,255,0.1)",
                  borderRadius: 1,
                  height: "100%",
                }}
              >
                <Typography variant="subtitle2" color="text.secondary">
                  设备限制
                </Typography>
                <Typography variant="h6" sx={{ mt: 1, color: "primary.main" }}>
                  {service?.content.devicePerUser} 台 / 用户
                </Typography>
              </Box>
            </Grid>

            <Grid size={{ xs: 12 }}>
              <Box
                sx={{
                  p: 2,
                  bgcolor: "rgba(255,255,255,0.1)",
                  borderRadius: 1,
                  height: "100%",
                  cursor: "pointer",
                  "&:hover": {
                    bgcolor: "rgba(255,255,255,0.15)",
                  },
                }}
                onClick={() => navigate("/members")}
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
                  <Box>
                    <Typography variant="subtitle2" color="text.secondary">
                      成员管理
                    </Typography>
                    <Button
                      variant="text"
                      sx={{
                        mt: 1,
                        p: 0,
                        color: "primary.main",
                        "&:hover": {
                          background: "none",
                          opacity: 0.8,
                        },
                        display: "flex",
                        alignItems: "center",
                        gap: 0.5,
                      }}
                    >
                      <Typography variant="h6" component="span">
                        {seatsUsed} / {seatQuota} 成员
                      </Typography>
                      <GroupIcon sx={{ ml: 0.5 }} />
                    </Button>
                    <Typography 
                      variant="caption" 
                      color="text.secondary"
                      sx={{ 
                        display: "block",
                        "&:hover": {
                          color: "primary.main",
                        }
                      }}
                    >
                      点击管理团队成员
                    </Typography>
                  </Box>
                  <Button
                    variant="contained"
                    size="small"
                    startIcon={<EditIcon />}
                    onClick={(e) => {
                      e.stopPropagation();
                      setChanging(!changing);
                    }}
                    sx={{ bgcolor: "background.paper", color: "primary.main" }}
                  >
                    变更席位
                  </Button>
                </Stack>
              </Box>
            </Grid>

            <Grid size={{ xs: 12 }}>
              <Box
                sx={{
                  p: 2,
                  bgcolor: "rgba(255,255,255,0.1)",
                  borderRadius: 1,
                  height: "100%",
                }}
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
                  <Box>
                    <Typography variant="subtitle2" color="text.secondary">
                      剩余时间
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{ mt: 1, color: "primary.main" }}
                    >
                      {expiredAt ? timeLeft(expiredAt) : "已过期"}
                    </Typography>
                  </Box>
                  <Button
                    variant="contained"
                    size="small"
                    startIcon={<AddIcon />}
                    onClick={() => navigate("/purchase")}
                    sx={{ bgcolor: "background.paper", color: "primary.main" }}
                  >
                    充值
                  </Button>
                </Stack>
              </Box>
            </Grid>

          </Grid>
        </Paper>
      </Container>
    );
  };

  const renderChangeSeats = () => {
    if (!service) return null;

    const isChangeAllowed = canChangeSeats(preview.seatsChangedAt);
    const lastChangeTime = formatLastChangeTime(preview.seatsChangedAt);

    return (
      <Dialog
        open={changing}
        onClose={() => setChanging(false)}
        maxWidth="sm"
        fullWidth
        aria-labelledby="change-seats-dialog-title"
        disableEnforceFocus
      >
        <DialogTitle sx={{ textAlign: "center", pb: 1 }}>
          变更席位数量
        </DialogTitle>
        <DialogContent sx={{ p: 1 }}>
          <Stack spacing={2}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 1,
              }}
            >
              <NumberInputField
                value={seats}
                minValue={minSeats}
                onChange={setSeats}
              />
              <Typography variant="caption" color="text.secondary">
                最小可选择席位数：{minSeats}
              </Typography>
            </Box>

            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: "30%" }}></TableCell>
                  <TableCell align="center" sx={{ color: "text.secondary" }}>
                    当前状态
                  </TableCell>
                  <TableCell align="center" sx={{ color: "text.secondary" }}>
                    变更后
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>席位</TableCell>
                  <TableCell align="center" sx={{ color: "primary.main" }}>
                    {seatQuota}
                  </TableCell>
                  <TableCell align="center" sx={{ color: "primary.main" }}>
                    {seats}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>有效期</TableCell>
                  <TableCell align="center" sx={{ color: "primary.main" }}>
                    {expiredAt ? timeLeft(expiredAt) : "已过期"}
                  </TableCell>
                  <TableCell align="center" sx={{ color: "primary.main" }}>
                    {timeLeft(preview.expiredAt)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>

            <Alert
              severity={isChangeAllowed ? "warning" : "error"}
              sx={{ mt: 1 }}
            >
              {isChangeAllowed ? (
                "每个月仅可修改一次席位数量，请谨慎操作。"
              ) : (
                <>
                  本月已进行过席位修改（上次修改时间：{lastChangeTime}），
                  需要等到下个月才能再次修改。
                </>
              )}
            </Alert>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ p: 2, justifyContent: "center" }}>
          <Button variant="outlined" onClick={() => setChanging(false)}>
            取消
          </Button>
          <Button
            variant="contained"
            onClick={() => confirm(false)}
            disabled={!isChangeAllowed}
            sx={{
              "&.Mui-disabled": {
                bgcolor: "rgba(0, 0, 0, 0.12)",
                color: "rgba(0, 0, 0, 0.26)",
              },
            }}
          >
            确认变更
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <ContentLayout breadcrumbs={breadcrumbs}>
      <Box maxWidth="sm" sx={{ width: "100%", mx: "auto" }}>
        {service ? (
          <>
            {renderServiceSummary()}
            {renderChangeSeats()}
          </>
        ) : (
          <Box sx={{ display: "flex", justifyContent: "center", p: 4 }}>
            <CircularProgress />
          </Box>
        )}
      </Box>
    </ContentLayout>
  );
};

export default MyService;
