import { useState, useEffect } from "react";
import api from "@/utils/api";
import { Payment } from "@/utils/data";
import { useToast } from "@/context/ToastContext";

export const usePayment = (orderId: number | null, currency: string) => {
  const [payment, setPayment] = useState<Payment | null>(null);
  const { toast } = useToast();

  useEffect(() => {
    if (!orderId || !currency) return;

    api<Payment>({
      path: `/api/orders/${orderId}/payments/`,
      method: "patch",
      params: { payMethod: "stripe", currency },
    })
      .then(({ code, msg, data }) => {
        if (code === 0) {
          setPayment(data);
        } else {
          toast("error", msg);
        }
      })
      .catch((e: Error) => {
        toast("error", e.message);
      });
  }, [orderId, currency, toast]);

  return payment;
};
