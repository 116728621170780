import { useCallback, useEffect, useState } from "react";
import { useToast } from "@/context/ToastContext";
import { useBasicInfo } from "@/context/BasicInfoContext";
import { Order } from "@/utils/data";
import api from "@/utils/api";
import { formatMoney } from "@/utils";
import { loadStripe, Stripe, StripeElements } from "@stripe/stripe-js";
import { useThemeMode } from "../hooks/useThemeMode";
import { usePayTracker } from "../hooks/usePayTracker";
import { useNavigate, useSearchParams } from "react-router-dom";
import { usePayment } from "../hooks/usePayment";
import { styled, useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Grid2";
import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Select,
  MenuItem,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import ContentLayout from "@/layouts/ContentLayout";
import { useAuth } from "@/context/AuthContext";

// 样式组件
const OrderSection = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  height: "100%",
  backgroundColor: theme.palette.background.default,
}));

const PaymentSection = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  height: "100%",
}));

const RightTableCell = styled(TableCell)(({ theme }) => ({
  textAlign: "right",
  "&.amount": {
    color: theme.palette.primary.main,
    fontWeight: "bold",
    fontSize: "1.2rem",
  },
}));

const PayButton = styled(Button)(({ theme }) => ({
  width: "100%",
  marginTop: theme.spacing(3),
  padding: theme.spacing(1.5),
}));

const Pay = () => {
  const navigate = useNavigate();
  const { toast } = useToast();

  const { user, isPayer } = useAuth();

  useEffect(() => {
    if (!isPayer) {
      navigate("/dashboard");
    }
  }, [isPayer, navigate]);

  const { basicInfo } = useBasicInfo();
  const [order, setOrder] = useState<Order>();
  const [stripe, setStripe] = useState<Stripe>();
  const [elements, setElements] = useState<StripeElements>();
  const { getMode } = useThemeMode();
  const [currency, setCurrency] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [paymentMethod, setPaymentMethod] = useState<string>("");
  const [searchParams] = useSearchParams();
  const [isPaymentElementLoading, setIsPaymentElementLoading] = useState(true);
  const [isPaying, setIsPaying] = useState(false);
  const theme = useTheme();
  const [openWechatDialog, setOpenWechatDialog] = useState(false);

  const { startPayEvent } = usePayTracker({
    currency,
    order,
    error,
    paymentMethod,
  });

  const payment = usePayment(order?.id || null, currency);

  useEffect(() => {
    if (basicInfo?.stripePublicKey) {
      loadStripe(basicInfo?.stripePublicKey).then((s) => {
        if (s && !stripe) {
          setStripe(s);
        }
      });
    }
  }, [basicInfo]);

  useEffect(() => {
    const defaultCurrency = (function () {
      if (user && user.currency) {
        return user.currency;
      }
      return basicInfo?.defaultPayCurrency?.currency || "sgd";
    })();
    setCurrency(defaultCurrency);
  }, [user, basicInfo]);

  useEffect(() => {
    const orderId = searchParams.get("orderId");
    console.info(`get order detail for ${orderId}`);
    api<Order>({
      path: `/api/orders/${orderId}`,
    })
      .then(({ code, msg, data }) => {
        if (code == 0) {
          setOrder(data);
        } else if (code == 404) {
          navigate("/dashboard");
        } else {
          toast("error", msg);
        }
      })
      .catch((e) => {
        toast("error", e.message);
      });
  }, [toast, searchParams]);

  useEffect(() => {
    if (order && order.isPaid) {
      navigate("/dashboard");
    }
  }, [order]);

  useEffect(() => {
    if (!payment || !stripe) {
      return;
    }
    const elements = stripe.elements({
      clientSecret: payment.extra?.paymentIntentClientSecret,
      locale: "zh-HK",
      appearance: {
        variables: {
          colorPrimary: getComputedStyle(
            document.documentElement
          ).getPropertyValue("--primary-color"),
        },
      },
    });

    const paymentElement = elements.create("payment");

    paymentElement.on("change", (event) => {
      console.info(`changed event`, event);
      if (event.value?.type) {
        setPaymentMethod(event.value.type);
      }
    });

    paymentElement.on("ready", () => {
      setIsPaymentElementLoading(false);
    });

    paymentElement.mount("#payment-element");
    setIsPaymentElementLoading(true);
    setElements(elements);
  }, [payment, stripe, getMode]);

  useEffect(() => {
    if (paymentMethod === "wechat_pay") {
      setOpenWechatDialog(true);
    }
  }, [paymentMethod]);

  const confirm = useCallback(async () => {
    if (!stripe || !elements) {
      return;
    }
    setIsPaying(true);
    startPayEvent();
    const return_url = `${location.protocol}//${location.host}/pay/confirming?orderId=${order?.uuid}`;
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url,
      },
    });
    if (error && error.message) {
      setError(error.message);
      toast("error", error.message);
    } else {
      toast("success", "支付成功，正在處理中...");
    }
    setIsPaying(false);
  }, [stripe, elements, order, toast, startPayEvent]);

  const breadcrumbs = [
    { name: "控制面版", link: "/dashboard" },
    { name: "支付訂單" },
  ];

  return (
    <ContentLayout breadcrumbs={breadcrumbs}>
      <Box maxWidth="lg" sx={{ width: "100%", mx: "auto" }}>
        <Grid container spacing={3}>
          {/* 订单信息部分 */}
          <Grid size={{ xs: 12, md: 6 }}>
            <OrderSection elevation={0}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>名稱:</TableCell>
                    <RightTableCell>{order?.title}</RightTableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>價格:</TableCell>
                    <RightTableCell>
                      {formatMoney(order?.price || 0)}(USD)
                    </RightTableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>數量：</TableCell>
                    <RightTableCell>x {order?.quantity}</RightTableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>訂單總額:</TableCell>
                    <RightTableCell className="amount">
                      {formatMoney(order?.totalAmount || 0)}(USD)
                    </RightTableCell>
                  </TableRow>
                </TableBody>
              </Table>

              <Typography variant="h6" sx={{ mt: 3, mb: 2 }}>
                支付資訊
              </Typography>

              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>支付货币:</TableCell>
                    <RightTableCell>
                      <Select
                        value={currency}
                        onChange={(e) => setCurrency(e.target.value)}
                        fullWidth
                        size="small"
                      >
                        {basicInfo?.currencies.map((c) => (
                          <MenuItem key={c.currency} value={c.currency}>
                            {c.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </RightTableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>需要換匯:</TableCell>
                    <RightTableCell>
                      {payment?.isExchanged ? "是" : "否"}
                    </RightTableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>換匯費用:</TableCell>
                    <RightTableCell>
                      {payment?.exchangeFeeRate} %
                    </RightTableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>匯率:</TableCell>
                    <RightTableCell>{payment?.exchangeRate}</RightTableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>支付總額:</TableCell>
                    <RightTableCell className="amount">
                      {payment ? (
                        <span>
                          {formatMoney(payment?.amount || 0)}(
                          {payment?.currency.toUpperCase()})
                        </span>
                      ) : (
                        <Typography variant="body2" color="text.secondary">
                          ...
                        </Typography>
                      )}
                    </RightTableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </OrderSection>
          </Grid>

          {/* 支付部分 */}
          <Grid size={{ xs: 12, md: 6 }}>
            <PaymentSection elevation={0}>
              <Typography variant="h6" gutterBottom>
                支付方式
              </Typography>

              <Dialog open={openWechatDialog} onClose={() => setOpenWechatDialog(false)}>
                <DialogTitle>微信支付提醒</DialogTitle>
                <DialogContent>
                  <Typography variant="body2">
                    微信支付要求您使用
                    <strong style={{ color: theme.palette.error.main }}>
                      另一台设备的摄像头
                    </strong>
                    扫描二维码完成付款。
                    <br />
                    请勿在当前设备上点击二维码，或者保存二维码以图片的形式扫码付款，以免支付失败。
                  </Typography>
                </DialogContent>
                <DialogActions>
                  <Button 
                    variant="outlined" 
                    color="inherit"
                    onClick={() => setOpenWechatDialog(false)}
                  >
                    选择其他付款方式
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={isPaying}
                    onClick={() => {
                      setOpenWechatDialog(false);
                      confirm();
                    }}
                  >
                    我明白了，继续微信支付
                  </Button>
                </DialogActions>
              </Dialog>

              <Box
                id="payment-element"
                sx={{
                  display: payment ? "block" : "none",
                  mb: 2,
                  position: "relative",
                }}
              ></Box>
              {(isPaymentElementLoading || !payment) && (
                <Box
                  sx={{
                    display: "flex",
                    mb: 2,
                    py: 4,
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: 1,
                    border: 1,
                    borderColor: 'divider',
                    borderStyle: 'dashed'
                  }}
                >
                  <CircularProgress size={24} />
                  <Typography variant="body2" sx={{ ml: 1 }}>
                    正在加載支付表單...
                  </Typography>
                </Box>
              )}
              <PayButton
                variant="contained"
                onClick={confirm}
                disabled={!payment || isPaying}
              >
                確認支付
              </PayButton>
            </PaymentSection>
          </Grid>
        </Grid>
      </Box>
    </ContentLayout>
  );
};

export default Pay;
