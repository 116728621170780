import React, { useCallback, useEffect } from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  CircularProgress,
  ListItemText,
} from "@mui/material";
import PaymentIcon from "@mui/icons-material/Payment";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import ContentLayout from "@/layouts/ContentLayout";
import { useToast } from "@/context/ToastContext";
import api, { Pagi, ResponseList } from "@/utils/api";
import { Order } from "@/utils/data";
import { formatDate, formatMoney } from "@/utils";
import Pagination from "@/components/Pagination";
import { useSearchParams, useNavigate } from "react-router-dom";
import LoadMore from "@/components/LoadMore";
import { isApp } from "@/bridge/client";

const OrderList = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [orders, setOrders] = React.useState<Order[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [pagination, setPagination] = React.useState<Pagi>({
    page: Number(searchParams.get("page")) || 0,
    pageSize: Number(searchParams.get("pageSize")) || 10,
    total: 0,
  });
  const { toast } = useToast();

  const breadcrumbs = [
    { name: "控制面版", link: "/dashboard" },
    { name: "我的订单" },
  ];

  const loadPageData = useCallback(async (page: number) => {
    setLoading(true);
    try {
      const { code, msg, data } = await api<ResponseList<Order>>({
        path: `/api/orders?page=${page}&pageSize=${pagination.pageSize}`,
        authRequired: true,
      });
      
      if (code === 0) {
        // 如果是移动端且不是第一页，追加数据
        if (isApp && page > 0) {
          setOrders(prev => [...prev, ...data.items]);
        } else {
          // 第一页或非移动端时直接替换数据
          setOrders(data.items);
        }
        setPagination(data.pagination);
      } else {
        toast("error", msg);
      }
    } catch (error) {
      console.error("加载订单列表时出错:", error);
      toast("error", "加载订单列表时发生错误");
    } finally {
      setLoading(false);
    }
  }, [pagination.pageSize, toast, isApp]);

  // 初始加载
  useEffect(() => {
    loadPageData(Number(searchParams.get("page")) || 0);
  }, [searchParams]);

  // 处理 URL 参数变化（仅用于非移动端）
  useEffect(() => {
    if (!isApp) {
      const page = Number(searchParams.get("page")) || 0;
      loadPageData(page);
    }
  }, [searchParams, isApp]);

  const handlePageChange = (newPage: number) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("page", String(newPage));
    navigate(`?${newSearchParams.toString()}`, { replace: true });
  };

  const handleSizeChange = (newSize: number) => {
    setPagination((prev) => ({
      ...prev,
      pageSize: newSize,
      page: 0,
    }));

    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("pageSize", String(newSize));
    newSearchParams.set("page", "0");
    navigate(`?${newSearchParams.toString()}`, { replace: true });
  };

  const renderPagination = () => {
    if (isApp) {
      return (
        <LoadMore
          data={pagination}
          loading={loading}
          onLoadMore={loadPageData}
        />
      );
    }

    return (
      <Pagination
        data={pagination}
        onChangePage={handlePageChange}
        onChangeSize={handleSizeChange}
      />
    );
  };

  const renderContent = () => {
    if (loading && orders.length === 0) {
      return (
        <Box display="flex" justifyContent="center" py={4}>
          <CircularProgress />
        </Box>
      );
    }

    if (orders.length === 0) {
      return (
        <Box
          display="flex"
          justifyContent="center"
          py={4}
          bgcolor="background.paper"
          borderRadius={1}
        >
          <Typography color="text.secondary">暂无订单</Typography>
        </Box>
      );
    }

    return (
      <>
        <List>
          {orders.map((order) => (
            <ListItem key={order.id}>
              <ListItemText
                primary={
                  <Box
                    component="span"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography component="span" variant="subtitle1">
                      {order.title}
                    </Typography>
                    <Typography
                      component="span"
                      variant="subtitle1"
                      color="primary"
                    >
                      {formatMoney(order.totalAmount)} (
                      {order.currency.toUpperCase()})
                    </Typography>
                  </Box>
                }
                secondary={
                  <Box component="span" sx={{ mt: 1, display: "block" }}>
                    <Box
                      component="span"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mb={0.5}
                    >
                      <Box
                        component="span"
                        display="flex"
                        alignItems="center"
                        gap={1}
                      >
                        <PaymentIcon fontSize="small" color="action" />
                        <Typography
                          component="span"
                          variant="body2"
                          color="text.secondary"
                        >
                          {order.payment.payMethod}
                        </Typography>
                      </Box>
                      <Typography
                        component="span"
                        variant="body2"
                        color="text.secondary"
                      >
                        {formatMoney(order.price)} x {order.quantity}
                      </Typography>
                    </Box>
                    <Box
                      component="span"
                      display="flex"
                      alignItems="center"
                      gap={1}
                    >
                      <CalendarTodayIcon fontSize="small" color="action" />
                      <Typography
                        component="span"
                        variant="body2"
                        color="text.secondary"
                      >
                        {formatDate(order.payment.payAt)}
                      </Typography>
                    </Box>
                  </Box>
                }
              />
            </ListItem>
          ))}
        </List>
        {renderPagination()}
      </>
    );
  };

  return (
    <ContentLayout breadcrumbs={breadcrumbs}>
      <Box maxWidth="sm" sx={{ width: "100%", mx: "auto" }}>
        {renderContent()}
      </Box>
    </ContentLayout>
  );
};

export default OrderList;
