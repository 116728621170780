import React, { useMemo } from "react";
import { Box, Breadcrumbs, Container, Typography } from "@mui/material";
import WebHeader from "./web/WebHeader";
import WebFooter from "./web/WebFooter";
import AppHeader from "./app/AppHeader";
import { Link, useNavigate } from "react-router-dom";
import { NavigateNext as NavigateNextIcon } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import AppContent from "./app/AppContent";
import { isApp, isInV1App } from "@/bridge/client";
import { useAuth } from "@/context/AuthContext";

export interface BreadcrumbItem {
  name: string;
  link?: string;
}

export interface ContentLayoutProps {
  /** 面包屑导航项目 */
  breadcrumbs?: BreadcrumbItem[];
  /** 右上角操作区域 */
  action?: React.ReactNode;
  /** 页面内容 */
  children?: React.ReactNode;
  /** 自定义页头，如果提供则替换默认的 WebHeader */
  header?: React.ReactNode;
  /** 是否显示底部 */
  hasAppNav?: boolean;
}

// 自定义面包屑链接样式
const BreadcrumbLink = styled(Link)(({ theme }) => ({
  color: theme.palette.text.secondary,
  textDecoration: "none",
  fontSize: "0.875rem",
  display: "flex",
  alignItems: "center",
  "&:hover": {
    color: theme.palette.primary.main,
  },
}));

// 自定义面包屑容器样式
const StyledBreadcrumbs = styled(Breadcrumbs)(({ theme }) => ({
  "& .MuiBreadcrumbs-separator": {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

const Layout: React.FC<ContentLayoutProps> = ({
  breadcrumbs,
  action,
  children,
  header,
}) => {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  // 从 breadcrumbs 中获取标题
  const title = useMemo(() => {
    if (!breadcrumbs?.length) return "";
    return breadcrumbs[breadcrumbs.length - 1].name;
  }, [breadcrumbs]);

  const backLink = useMemo(() => {
    // breadcrumbs中的上一页的连接
    if (breadcrumbs && breadcrumbs?.length > 1) {
      return breadcrumbs[breadcrumbs.length - 2].link;
    }
    return undefined;
  }, [breadcrumbs]);

  // 如果在App内但不是原生App，只显示内容
  if (isInV1App) {
    return <>{children}</>;
  }

  // App环境
  if (isApp) {
    const header =
      isAuthenticated && (backLink || title || action) ? (
        <>
          <AppHeader
            onBack={backLink ? () => navigate(backLink) : undefined}
            title={title}
            action={action}
          />
          <Box sx={{ height: "56px" }} />
        </>
      ) : null;
    return (
      <Box
        className="is-app"
        sx={{
          height: "100vh",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <AppContent header={header}>{children}</AppContent>
      </Box>
    );
  }

  // Web环境
  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {header ? header : <WebHeader />}
      <Container maxWidth="lg">
        {breadcrumbs && (
          <StyledBreadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
            sx={{
              py: 1,
              "& .MuiBreadcrumbs-ol": {
                flexWrap: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              },
            }}
          >
            {breadcrumbs.map((item, index) => {
              const isLast = index === breadcrumbs.length - 1;
              return (
                <BreadcrumbLink
                  key={index}
                  to={item?.link || ""}
                  sx={{
                    maxWidth: isLast ? "none" : 200,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    ...(isLast && !item.link
                      ? {
                          color: "text.primary",
                          pointerEvents: "none",
                        }
                      : {}),
                  }}
                >
                  {item.name}
                </BreadcrumbLink>
              );
            })}
          </StyledBreadcrumbs>
        )}
        {title && (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mt={2}
          >
            <Typography variant="h4" component="h1">
              {title}
            </Typography>
            {action && <Box>{action}</Box>}
          </Box>
        )}
      </Container>
      <Box
        sx={{
          flexGrow: 1,
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {children}
      </Box>
      <WebFooter />
    </Box>
  );
};

export default Layout;
