import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "@/context/AuthContext";
import AppLoading from "@/components/AppLoading";

interface RouteGuardProps {
  children: React.ReactNode;
  requireAuth?: boolean;
}

export const RouteGuard = ({
  children,
  requireAuth = false,
}: RouteGuardProps) => {
  const { isAuthenticated, isLoading } = useAuth();
  const location = useLocation();

  if (isLoading) {
    return <AppLoading />;
  }

  if (requireAuth && !isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (isAuthenticated) {
    if (location.pathname === "/login") {
      return <Navigate to="/dashboard" replace />;
    }

    if (location.pathname === "/quick-start") {
      return <Navigate to="/purchase" replace />;
    }
  }

  return <>{children}</>;
};
