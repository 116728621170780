export function cropImageToBase64(
  url: string,
  aspectRatio: number,
  width?: number
): Promise<string> {
  return new Promise((resolve) => {
    // 這個圖像將保存我們的源圖像數據
    const inputImage = new Image();

    // 我們要等待圖像加載
    inputImage.onload = () => {
      // 存儲圖像的寬度和高度
      const inputWidth = inputImage.naturalWidth;
      const inputHeight = inputImage.naturalHeight;

      // 獲取輸入圖像的寬高比
      const inputImageAspectRatio = inputWidth / inputHeight;

      // 如果它大於我們的目標寬高比
      let outputWidth = inputWidth;
      let outputHeight = inputHeight;

      if (inputImageAspectRatio > aspectRatio) {
        outputWidth = inputHeight * aspectRatio;
      } else if (inputImageAspectRatio < aspectRatio) {
        outputHeight = inputWidth / aspectRatio;
      }

      // 計算繪製圖像的位置
      const outputX = (outputWidth - inputWidth) * 0.5;
      const outputY = (outputHeight - inputHeight) * 0.5;

      // 創建一個呈現輸出圖像的畫布
      const outputImage = document.createElement("canvas");

      let scale = 1.0;
      if (width) {
        scale = width / outputWidth;
      }

      // 設置畫布大小
      outputImage.width = outputWidth * scale;
      outputImage.height = outputHeight * scale;

      // 在畫布上的位置 0, 0 繪製我們的圖像
      const ctx = outputImage.getContext("2d");
      if (ctx) {
        ctx.drawImage(
          inputImage,
          outputX * scale,
          outputY * scale,
          inputWidth * scale,
          inputHeight * scale
        );
      }
      resolve(outputImage.toDataURL());
    };

    // 開始加載圖像
    inputImage.src = url;
  });
}
