import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { MyService } from "@/utils/data";
import api from "@/utils/api";
import { useToast } from "@/context/ToastContext";
import { useI18n } from "@/context/I18nContext";
import cache from "@/utils/cache";
import dayjs from "dayjs";
import { useAuth } from "./AuthContext";

interface MyServiceContextType {
  myService: MyService | null;
  isLoading: boolean;
  loadService: () => Promise<void>;
  setMyService: (service: MyService | null) => Promise<void>;
  serviceId: string;
  expiredAt: string;
  seatsUsed: number;
  seatQuota: number;
  expired: boolean;
  service: any | null;
}

const MyServiceContext = createContext<MyServiceContextType | null>(null);

export const MyServiceProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [myService, setMyService] = useState<MyService | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [heartbeat, setHeartbeat] = useState(0);
  const { toast } = useToast();
  const { t } = useI18n();
  const { user, isAuthenticated } = useAuth();

  // 添加心跳计时器
  useEffect(() => {
    const intervalId = setInterval(() => {
      setHeartbeat((prev) => prev + 1);
    }, 60 * 1000);
    return () => clearInterval(intervalId);
  }, []);

  // 计算 service
  const service = useMemo(() => {
    if (myService?.service) {
      return myService.service;
    }
    return null;
  }, [myService]);

  // 计算其他属性
  const memoizedValues = useMemo(() => {
    console.debug("new heartbeat", heartbeat);
    return {
      serviceId: myService?.serviceId || "",
      expiredAt: myService?.expiredAt || "",
      seatsUsed: user?.profile?.seatsUsed || 1,
      seatQuota: myService?.seats || 1,
      expired: dayjs(myService?.expiredAt).isBefore(dayjs()),
    };
  }, [myService, user, heartbeat]);

  const setMyServiceWithCache = async (service: MyService | null) => {
    await cache.set("myService", service, 3600);
    setMyService(service);
  };

  const loadService = useCallback(async () => {
    if (!isAuthenticated || isLoading) return;

    try {
      setIsLoading(true);
      const { code, msg, data } = await api<MyService>({
        path: "/api/my-service",
        authRequired: true,
      });

      if (code === 0) {
        await setMyServiceWithCache(data);
      } else {
        toast("error", msg);
      }
    } catch (error) {
      console.error("loadService:error", error);
      toast("error", t("errors.network"));
    } finally {
      setIsLoading(false);
    }
  }, [isLoading, toast, t, isAuthenticated]);

  // 初始化时从缓存加载
  useEffect(() => {
    const init = async () => {
      if (!isAuthenticated) {
        return;
      }
      
      const cached = await cache.get<MyService>("myService");
      if (cached) {
        setMyService(cached);
      } else {
        loadService();
      }
    };
    init();
  }, [isAuthenticated]);

  return (
    <MyServiceContext.Provider
      value={{
        myService,
        isLoading,
        loadService,
        setMyService: setMyServiceWithCache,
        service,
        ...memoizedValues,
      }}
    >
      {children}
    </MyServiceContext.Provider>
  );
};

export const useMyService = () => {
  const context = useContext(MyServiceContext);
  if (!context) {
    throw new Error("useMyService must be used within a MyServiceProvider");
  }
  return context;
};
