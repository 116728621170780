import { createTheme, Components, Theme } from "@mui/material/styles";

// 定义通用变量
const commonVariables = {
  borderRadius: "8px",
  fontFamily: "Inter, system-ui, Avenir, Helvetica, Arial, sans-serif",
};

// 定义亮色主题变量
const lightTheme = {
  // 主要颜色
  primary: "#ff1964",
  secondary: "#193643",

  // 文字颜色
  textPrimary: "#242424",
  textSecondary: "#666666",
  textDisabled: "#999999",

  // 背景颜色
  backgroundPrimary: "#fafafa",
  backgroundSecondary: "#f5f7fa",
  backgroundHover: "#f0f2f5",
  backgroundSelected: "rgba(255, 25, 100, 0.08)",

  // 边框颜色
  borderPrimary: "#e6e8eb",
  borderSecondary: "#eef0f3",

  // 状态颜色
  success: "#00b578",
  warning: "#ff9500",
  error: "#f65f7b",
  info: "#4096ff",

  // 阴影
  shadowLight: "none",
  shadowMedium: "0px 1px 2px rgba(0, 0, 0, 0.05)",
};

// 定义暗色主题变量
const darkTheme = {
  // 主要颜色
  primary: "#ff6b9d",
  secondary: "#dbd3d4",

  // 文字颜色
  textPrimary: "#ffffff",
  textSecondary: "#b3b3b3",
  textDisabled: "#666666",

  // 背景颜色
  backgroundPrimary: "#2e2e3d",
  backgroundSecondary: "#1e1e2d",
  backgroundHover: "#323244",
  backgroundSelected: "rgba(255, 64, 129, 0.15)",

  // 边框颜色
  borderPrimary: "#3a3a4c",
  borderSecondary: "#454558",

  // 状态颜色
  success: "#00b578",
  warning: "#ffa940",
  error: "#ff85a0",
  info: "#4096ff",

  // 阴影
  shadowLight: "none",
  shadowMedium: "0px 2px 4px rgba(0, 0, 0, 0.2)",
};

// 创建组件样式
const createComponents = (mode: "light" | "dark"): Components<Theme> => {
  const colors = mode === "light" ? lightTheme : darkTheme;

  return {
    MuiCssBaseline: {
      styleOverrides: {
        ":root": {
          colorScheme: mode,
        },
        html: {
          WebkitTextSizeAdjust: "100%",
          backgroundColor: colors.backgroundPrimary,
        },
        body: {
          margin: 0,
          padding: 0,
          minWidth: "320px",
          minHeight: "100vh",
          backgroundColor: colors.backgroundPrimary,
          color: colors.textPrimary,
          fontFamily: commonVariables.fontFamily,
          lineHeight: 1.5,
          WebkitFontSmoothing: "antialiased",
          MozOsxFontSmoothing: "grayscale",
          fontSynthesis: "none",
          textRendering: "optimizeLegibility",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: colors.backgroundSecondary,
          backgroundImage: "none",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: colors.backgroundPrimary,
          backgroundImage: "none",
          borderRadius: commonVariables.borderRadius,
          ".server-list &": {
            backgroundColor: 'transparent',
          }
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          borderRadius: "20px",
        },
        contained: {
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: colors.backgroundSecondary,
          borderBottom: `1px solid ${colors.borderSecondary}`,
          boxShadow: "none",
          "& .MuiToolbar-root": {
            backgroundColor: "inherit",
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: commonVariables.borderRadius,
        },
      },
      variants: [
        {
          props: { className: "vpn-trial-alert" },
          style: {
            backgroundColor: "rgba(255, 152, 0, 0.1)",
            color: colors.textPrimary,
            marginBottom: "16px",
            "& .MuiAlert-message": {
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            },
          },
        },
      ],
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: colors.borderPrimary,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderColor: colors.borderPrimary,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundColor: colors.backgroundPrimary,
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          borderRadius: commonVariables.borderRadius,
          marginBottom: "8px",
          "&:hover": {
            backgroundColor: colors.backgroundHover,
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: colors.textSecondary,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: colors.borderPrimary,
              borderWidth: mode === "dark" ? "1px" : "1px",
            },
            "&:hover fieldset": {
              borderColor: colors.primary,
            },
            "&.Mui-focused fieldset": {
              borderColor: colors.primary,
            },
            "&.Mui-error fieldset": {
              borderColor: colors.error,
            },
            "&.Mui-disabled fieldset": {
              borderColor: mode === "dark" ? "rgba(255, 255, 255, 0.2)" : "rgba(0, 0, 0, 0.2)",
            },
          },
          "& .MuiInputLabel-root": {
            color: colors.textSecondary,
            "&.Mui-focused": {
              color: colors.primary,
            },
          },
          "& .MuiInputBase-input": {
            color: colors.textPrimary,
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: "none",
          "&:hover": {
            textDecoration: "underline",
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: colors.textSecondary,
        },
      },
    },
    MuiListItemSecondaryAction: {
      styleOverrides: {
        root: {
          "& .MuiButton-root": {
            color: colors.primary,
          },
        },
      },
    },
    MuiBottomNavigation: {
      styleOverrides: {
        root: {
          backgroundColor: colors.backgroundSecondary,
          borderTop: mode === "dark" 
            ? `1px solid ${colors.borderSecondary}`  // 暗色模式下使用更明显的边框
            : `1px solid ${colors.borderPrimary}`,
          boxShadow: mode === "dark"
            ? "0 -1px 3px rgba(0, 0, 0, 0.2)"  // 添加微妙的阴影
            : "none"
        },
      },
    },
    MuiBottomNavigationAction: {
      styleOverrides: {
        root: {
          color: colors.textSecondary,
          '&.Mui-selected': {
            color: colors.primary,
          },
          '& .MuiBottomNavigationAction-label': {
            fontSize: '0.75rem',
          },
          '& .MuiBottomNavigationAction-label.Mui-selected': {
            fontSize: '0.75rem',
          },
        },
      },
    },
  };
};

// 创建主题函数
export const createAppTheme = (mode: "light" | "dark") => {
  const colors = mode === "light" ? lightTheme : darkTheme;

  return createTheme({
    palette: {
      mode,
      primary: {
        main: colors.primary,
      },
      secondary: {
        main: colors.secondary,
      },
      error: {
        main: colors.error,
      },
      warning: {
        main: colors.warning,
      },
      info: {
        main: colors.info,
      },
      success: {
        main: colors.success,
      },
      text: {
        primary: colors.textPrimary,
        secondary: colors.textSecondary,
        disabled: colors.textDisabled,
      },
      background: {
        default: colors.backgroundPrimary,
        paper: colors.backgroundSecondary,
      },
      action: {
        hover: colors.backgroundHover,
        selected: colors.backgroundSelected,
      },
      divider: colors.borderPrimary,
    },
    typography: {
      fontFamily: commonVariables.fontFamily,
      fontSize: 16,
    },
    shape: {
      borderRadius: parseInt(commonVariables.borderRadius),
    },
    components: createComponents(mode),
  });
};

// 导出默认主题（可以根据系统设置或用户偏好选择）
export const theme = createAppTheme("light");
