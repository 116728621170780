import { useCallback, useEffect, useState } from "react";
import { useCustomerEvent } from "./useCustomerEvent";
import { CustomerKeyPointEvent } from "../services/CustomerEventEmitter";
import { VpnService, VpnPlan } from "@/utils/data";
import { secretEmail } from "@/utils";
import cache from "@/utils/cache";

interface UseQuickStartTrackerProps {
  service: VpnService | null;
  plan: VpnPlan | null;
  seats: number;
  email: string;
}

export const useQuickStartTracker = ({
  service,
  plan,
  seats,
  email,
}: UseQuickStartTrackerProps) => {
  const { sendEvent } = useCustomerEvent();

  const [campaign, setCampaign] = useState<string>("");

  useEffect(() => {
    const init = async () => {
      const params = new URLSearchParams(window.location.search);
      let campaign = params.get("campaign") || "";
      if (campaign) {
        await cache.set("campaign", campaign, -1);
      }
      campaign = (await cache.get<string>("campaign")) || "";
      setCampaign(campaign);
    };
    init();
  }, []);

  // 追踪服务选择
  useEffect(() => {
    if (service) {
      sendEvent("select_service", {
        service: service?.content.name,
        description: `服务变更为: ${service?.content.name}`,
      });
    }
  }, [sendEvent, service]);

  // 追踪套餐切换
  useEffect(() => {
    if (plan) {
      sendEvent("switch_plan", {
        plan: plan.name,
        description: `套餐变更为: ${plan.name}`,
      });
    }
  }, [sendEvent, plan]);

  // 追踪成员数量变化
  useEffect(() => {
    let timeoutId: any;

    const debouncedSendEvent = (seatsCount: number) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        sendEvent("set_members", {
          seats: seatsCount,
          description: `成员数量变更为: ${seatsCount}`,
        });
      }, 3000);
    };

    if (seats) {
      debouncedSendEvent(seats);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [sendEvent, seats]);

  // 追踪邮箱输入
  useEffect(() => {
    if (email) {
      sendEvent("input_email", {
        email: secretEmail(email),
        description: `邮箱变更为: ${secretEmail(email)}`,
      });
    }
  }, [sendEvent, email]);

  const createOrderEvent = useCallback(() => {
    sendEvent(CustomerKeyPointEvent.CREATE_ORDER, {
      description: `创建订单`,
    });
  }, [sendEvent]);

  return { createOrderEvent, campaign };
};
