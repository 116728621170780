import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import api from "@/utils/api";
import ContentLayout from "@/layouts/ContentLayout";
import { useToast } from "@/context/ToastContext";
import { TextField, Button, CircularProgress, Container } from "@mui/material";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";

const CouponConsume = () => {
  const [uuid, setUuid] = useState("");
  const [loading, setLoading] = useState(false);
  const { toast } = useToast();
  const navigate = useNavigate();
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  const breadcrumbs = [
    { name: "控制面板", link: "/dashboard" },
    { name: "授权码充值" },
  ];

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    api({
      path: `/api/license-keys/${uuid}/consume`,
      method: "post",
      authRequired: true,
    })
      .then(({ code, msg }) => {
        if (code === 0) {
          toast("success", "授权码充值成功");
          navigate("/my-service");
        } else {
          toast("error", msg);
        }
      })
      .catch((error) => {
        console.error("授权码充值时出错:", error);
        toast("error", "授权码充值时发生错误");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <ContentLayout breadcrumbs={breadcrumbs}>
      <Container
        maxWidth="sm"
        component="form"
        onSubmit={handleSubmit}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 3,
          mt: 10,
          mx: "auto",
          width: "100%",
        }}
      >
        <TextField
          fullWidth
          label="授权码"
          name="uuid"
          value={uuid}
          onChange={(e) => setUuid(e.target.value)}
          required
          placeholder="请输入授权码"
          inputRef={inputRef}
        />

        <Button
          type="submit"
          variant="contained"
          disabled={loading}
          startIcon={
            loading ? <CircularProgress size={20} /> : <CardGiftcardIcon />
          }
          sx={{ py: 1.5 }}
        >
          {loading ? "充值中..." : "充值"}
        </Button>
      </Container>
    </ContentLayout>
  );
};

export default CouponConsume;
