// 主要产品数据的接口定义
export interface VpnPlan {
  id: number;
  name: string;
  slug: string;
  appleProductId: string;
  price: number;
  month: number;
  stripePriceId: string;
  index: number;
  serviceId: string;
}

export interface VpnApp {
  id: number;
  name: string;
  type: string;
  link: string;
  icon: string;
  priority: number;
  requireLabels?: string[];
}

interface Country {
  alpha2: string;
  name: string;
}

interface Currency {
  currency: string;
  name: string;
}

export interface BasicInfoLinks {
  delete_account_url: string;
  user_privacy_url: string;
  manage_apps: string;
  manage_members: string;
  manage_servers: string;
  device_login: string;
  quick_start_url: string;
  seller_event_url: string;
  purchase_url: string;
  change_service_url: string;
  pay_url: string;
}

export interface UpgradeInfo {
  url: string;
  newestVersion: string;
  minSupportedVersion: string;
  needUpgrade: boolean;
  needForceUpgrade: boolean;
  currentVersion: string;
  preDeployed: boolean;
}

export interface BasicInfo {
  app: any;
  links: BasicInfoLinks;
  iosPaymentGuide: boolean;
  languages: string[];
  currencies: Currency[];
  defaultCurrency: Currency;
  defaultPayCurrency: Currency;
  countries: Country[];
  stripePublicKey: string;
  newestApp?: UpgradeInfo;
}

export const enum USER_LEVEL {
  TOP = 1,
  PAYER = 2,
  CHILD = 3,
}

export interface VpnServiceContent {
  name: string;
  maxByteflowMbPerUser: number;
  devicePerUser: number;
  startUsers: number;
  index: number;
}

export interface VpnService {
  id: string;
  content: VpnServiceContent;
  plans?: VpnPlan[];
  index?: number;
}

interface UserProfile {
  level: USER_LEVEL; // level=2 付款用户，level=3，在team中的成员
  serviceId: string;
  service: VpnServiceContent;
  seats: number;
  seatsUsed: number;
  expiredAt: string;
  firstOrderDone: boolean;
  invitedByUser: null | User; // 假设 inviteUser 可以是 null 或字符串
  inviteCode: number;
  plan: VpnPlan;
  isTeam: boolean;
}

export interface User {
  email: string;
  nickname: string;
  avatar: string;
  timezone: string;
  lang: string;
  id?: number;
  currency?: string;
  profile?: UserProfile;
}

export interface UserMember {
  id: number;
  email: string;
  nickname: string;
  avatar: string;
  deviceCount: number;
}

export interface UserDevice {
  deviceId: string;
  deviceName: string;
  loginAt: string;
}

export interface Order {
  id: number;
  uuid: string;
  userId: number;
  title: string;
  currency: string;
  totalAmount: number;
  price: number;
  isPaid: boolean;
  quantity: number;
  plan: VpnPlan; // 如果plan可能有其他类型，可以进一步定义为具体类型或更具体的联合类型
  payment: Payment;
}

export interface StripeExtra {
  customerId: string;
  ephemeralKey: string;
  merchantDisplayName: string;
  paymentIntentClientSecret: string;
}

export interface Payment {
  uuid: string;
  payMethod: string;
  amount: number;
  currency: string;
  exchangeRate: number;
  exchangeFeeRate: number;
  isExchanged: boolean;
  isPaid: boolean;
  extra: StripeExtra | null; // 同样，如果extra可能包含数据，应定义为具体类型或联合类型
  payAt: string;
}

export interface GiftCard extends VpnPlan {
  uuid: string;
  plan: VpnPlan;
  isUsed: boolean;
  usedByUser: {
    id: number;
    email: string;
    avatar: string;
    nickname: string;
  };
  createdAt: string;
  updatedAt: string;
  expiredAt: string;
}

export type WithdrawType = "gift" | "award" | "charge" | "withdraw" | "consume";

export enum WithdrawEnum {
  "gift" = "礼金奖励",
  "award" = "现金奖励",
  "charge" = "充值",
  "withdraw" = "提现",
  "consume" = "消费",
}

// 定义一个 Change 接口来描述单个变更记录
export interface Change {
  id: number;
  title: string;
  type: WithdrawType; // 根据具体业务场景，这里的类型可以使用更严格的联合类型定义，例如 'withdrawal' | 'deposit' | 'awards'
  value: number;
  isDone: boolean;
}

// 定义 Wallet 接口来描述钱包的结构
export interface Wallet {
  userId: number;
  amount: number;
  freezedAmount: number;
  unfinishedChanges: Change[];
}

// 钱包变更记录
export interface WalletChangeRecord extends Change {
  freezedBefore: string; // 发放奖励的冻结截止日期
  createdAt: string;
  updatedAt: string;
}

export interface DNS {
  defaultServers: [];
  antiPornServers: [];
  blockedDomains: string[];
}

// 新增的接口定义
export interface VpnUseStats {
  month: number;
  in: number;
  out: number;
  rated: number;
}

export interface VpnServer {
  serverId: string;
  name: string;
  country: string;
  domain: string;
  port: number;
  protocol?: string;
}

export interface VpnGroups {
  isTrial: boolean;
  trialExpiredAt: string;
  items: VpnGroup[];
}

export interface VpnGroup {
  id: number;
  level: number;
  name: string;
  servers: VpnServer[];
}

export interface VpnAppGroup {
  id: number;
  name: string;
  description: string;
  priority: number;
  apps: VpnApp[];
}

export interface PageNote {
  id: number;
  title: string;
  htmlBody: string;
  endAt: string;
  priority: number;
  clickAction: string;
  redisplayLockSeconds: number;
}

export interface TrialServer {
  expiredAt: string;
  group?: VpnGroup;
}

// 需要修改的接口
interface AppVersionInfo {
  version: string;
  name: string;
  url: string;
  minSupportedVersion: string;
}

export interface AppInfo {
  android: AppVersionInfo;
  ios: AppVersionInfo;
  osx_arm: AppVersionInfo;
  osx_intel: AppVersionInfo;
  windows: AppVersionInfo;
}

export interface SignInResponseData {
  deviceId: string;
  token: string;
  userInfo: User;
}

export interface MyService {
  serviceId: string;
  seats: number;
  expiredAt: string;
  seatsChangedAt: string;
  service: VpnService;
}
