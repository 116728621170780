import React, { createContext, useContext, useState, useEffect, useCallback, useMemo } from "react";
import { User, USER_LEVEL } from "@/utils/data";
import { authService } from "@/services/auth";
import { useToast } from "./ToastContext";
import { useI18n } from "./I18nContext";
import api from "@/utils/api";

interface AuthContextType {
  isAuthenticated: boolean;
  isLoading: boolean;
  deviceId: string;
  login: (token: string) => Promise<boolean>;
  logout: () => Promise<void>;
  user: User | null;
  refreshUser: () => Promise<void>;
  isPayer: boolean;
  isMember: boolean;
  firstOrderDone: boolean;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<User | null>(null);
  const [deviceId, setDeviceId] = useState<string>("");
  const { toast } = useToast();
  const { t } = useI18n();

  // 用户角色计算
  const isPayer = useMemo(() => {
    return user?.profile?.level === USER_LEVEL.PAYER;
  }, [user]);

  const isMember = useMemo(() => {
    return user?.profile?.level === USER_LEVEL.CHILD;
  }, [user]);

  const firstOrderDone = useMemo(() => {
    return !!user?.profile?.firstOrderDone;
  }, [user]);

  // 刷新用户信息
  const refreshUser = async () => {
    try {
      const { code, msg, data } = await api<User>({
        path: "/api/user-info",
        authRequired: true,
      });
      
      if (code === 0) {
        await authService.setCachedUser(data);
        setUser(data);
      } else if (code === 401) {
        const cachedUser = await authService.getCachedUser();
        if (cachedUser) {
          setUser(cachedUser);
        } else {
          setUser(null);
          setIsAuthenticated(false);
        }
      } else {
        const cachedUser = await authService.getCachedUser();
        if (cachedUser) {
          setUser(cachedUser);
        }
        toast("error", msg);
      }
    } catch (error) {
      console.info(`refreshUser:error`, error);
      const cachedUser = await authService.getCachedUser();
      if (cachedUser) {
        setUser(cachedUser);
      }
      toast("error", t("errors.network"));
    }
  };

  // 登录
  const login = useCallback(async (token: string) => {
    try {
      if (!token) return false;
      
      await authService.setToken(token);
      setIsAuthenticated(true);
      await refreshUser();
      return true;
    } catch (error) {
      console.error("Login failed:", error);
      return false;
    }
  }, []);

  // 登出
  const logout = async () => {
    await authService.clearAll();
    setUser(null);
    setIsAuthenticated(false);
  };

  // 初始化认证状态和用户信息
  useEffect(() => {
    const init = async () => {
      try {
        const deviceId = await authService.getDeviceId();
        setDeviceId(deviceId);

        if (await authService.isAuthenticated()) {
          const cachedUser = await authService.getCachedUser();
          if (cachedUser) {
            setUser(cachedUser);
          }
          setIsAuthenticated(true);
          refreshUser();
        }
      } catch (error) {
        console.error("Auth init error:", error);
      } finally {
        setIsLoading(false);
      }
    };
    init();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        isLoading,
        user,
        deviceId,
        login,
        logout,
        refreshUser,
        isPayer,
        isMember,
        firstOrderDone,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
