import { useState, useEffect } from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Button,
  Chip,
  CircularProgress,
  Alert,
  Container,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import DeviceHubIcon from "@mui/icons-material/DeviceHub";
import ContentLayout from "@/layouts/ContentLayout";
import { useToast } from "@/context/ToastContext";
import { UserDevice } from "@/utils/data";
import api from "@/utils/api";
import { formatDate } from "@/utils";
import { useMyService } from "@/context/MyServiceContext";

const DeviceList = () => {
  const [devices, setDevices] = useState<UserDevice[]>([]);
  const [currentDevice, setCurrentDevice] = useState<UserDevice | null>(null);
  const [loading, setLoading] = useState(true);
  const { toast, confirm } = useToast();
  const { service } = useMyService();

  const breadcrumbs = [
    { name: "控制面版", link: "/dashboard" },
    { name: "我的设备" },
  ];

  const loadDevices = () => {
    setLoading(true);
    api<{ items: UserDevice[]; current: UserDevice | null }>({
      path: "/api/devices",
      authRequired: true,
    })
      .then(({ code, msg, data }) => {
        if (code === 0) {
          setDevices(data.items);
          setCurrentDevice(data.current);
        } else {
          toast("error", msg);
        }
      })
      .catch((error) => {
        console.error("加载设备列表时出错:", error);
        toast("error", "加载设备列表时发生错误");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDeleteDevice = async (deviceId: string) => {
    if (await confirm("确定要删除这个设备吗？")) {
      api({
        path: `/api/devices/${deviceId}`,
        method: "delete",
        authRequired: true,
      })
        .then(({ code, msg }) => {
          if (code === 0) {
            toast("success", "设备已成功删除");
            setDevices((prevDevices) =>
              prevDevices.filter((device) => device.deviceId !== deviceId)
            );
          } else {
            toast("error", msg);
          }
        })
        .catch((error) => {
          console.error("删除设备时出错:", error);
          toast("error", "删除设备时发生错误");
        });
    }
  };

  useEffect(() => {
    loadDevices();
  }, []);

  return (
    <ContentLayout breadcrumbs={breadcrumbs}>
      <Container sx={{ mx: "auto", width: "100%", maxWidth: "sm" }}>
        <Alert
          severity="info"
          sx={{ mt:1, mb:1 }}
        >
          当前已使用 {devices.length}/{service?.content.devicePerUser || 0}{" "}
          台设备
        </Alert>

        {loading ? (
          <Box display="flex" justifyContent="center" py={4}>
            <CircularProgress />
          </Box>
        ) : devices.length === 0 ? (
          <Box
            display="flex"
            justifyContent="center"
            py={4}
            bgcolor="background.paper"
            borderRadius={1}
          >
            <Typography color="text.secondary">暂无设备</Typography>
          </Box>
        ) : (
          <List>
            {devices.map((device) => (
              <ListItem
                key={device.deviceId}
                secondaryAction={
                  currentDevice?.deviceId === device.deviceId ? (
                    <Chip label="当前设备" color="primary" size="small" />
                  ) : (
                    <Button
                      startIcon={<DeleteIcon />}
                      color="error"
                      onClick={() => handleDeleteDevice(device.deviceId)}
                      size="small"
                    >
                      删除
                    </Button>
                  )
                }
              >
                <ListItemText
                  primary={
                    <Box display="flex" alignItems="center" gap={1}>
                      <DeviceHubIcon color="primary" />
                      <Typography variant="subtitle1">
                        {device.deviceName}
                      </Typography>
                    </Box>
                  }
                  secondary={
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ mt: 1 }}
                    >
                      上次登录时间：{formatDate(device.loginAt)}
                    </Typography>
                  }
                />
              </ListItem>
            ))}
          </List>
        )}
      </Container>
    </ContentLayout>
  );
};

export default DeviceList;
