import React, { ReactNode } from "react";
import { Typography, Card, Box, Container, Button } from "@mui/material";
import DiamondIcon from "@mui/icons-material/Diamond";
import { timeLeft } from "@/utils";
import { useMyService } from "@/context/MyServiceContext";
import { useNavigate } from "react-router-dom";

interface PayerServiceCardProps {
  action?: ReactNode;
}

const PayerServiceCard: React.FC<PayerServiceCardProps> = ({ action }) => {
  const {
    service,
    seatsUsed,
    seatQuota,
    expiredAt,
    isLoading: isServiceLoading,
  } = useMyService();
  const navigate = useNavigate();


  if (isServiceLoading) {
    return (
      <Container sx={{ mb: 2, mt: 2 }}>
        <Card
          sx={{
            background:
              "linear-gradient(45deg, rgba(0,191,255,0.25) 0%, rgba(147,51,234,0.15) 100%)",
            p: 2,
            minHeight: 120,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: "1px solid rgba(0,191,255,0.2)",
            borderRadius: 2,
          }}
        >
          <Typography variant="body1" color="text.secondary">
            加载中...
          </Typography>
        </Card>
      </Container>
    );
  }

  if (!service?.id) {
    return (
      <Container sx={{ mb: 2, mt: 5 }}>
        <Card
          sx={{
            background:
              "linear-gradient(45deg, rgba(0,191,255,0.25) 0%, rgba(147,51,234,0.15) 100%)",
            p: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            border: "1px solid rgba(0,191,255,0.2)",
            borderRadius: 2,
            cursor: "pointer",
          }}
          onClick={() => navigate("/purchase")}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
            <Box>
              <Typography variant="h6">首次使用需要开通服务</Typography>
              <Typography variant="body2" color="text.secondary">
                点击此处开通服务以继续使用
              </Typography>
            </Box>
          </Box>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate("/purchase")}
          >
            开通
          </Button>
        </Card>
      </Container>
    );
  }

  return (
    <Container sx={{ mb: 2, mt: 2 }}>
      <Card
        sx={{
          background:
            "linear-gradient(45deg, rgba(0,191,255,0.25) 0%, rgba(147,51,234,0.15) 100%)",
          p: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          border: "1px solid rgba(0,191,255,0.2)",
          borderRadius: 2,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
          <Box sx={{ textAlign: "center" }}>
            <DiamondIcon sx={{ color: "#00bfff", fontSize: 40 }} />
            <Typography
              variant="subtitle2"
              sx={{
                color: "primary.main",
                fontWeight: "bold",
                whiteSpace: "nowrap",
              }}
            >
              {service?.content?.name}
            </Typography>
          </Box>
          <Box>
            <Box sx={{ display: "flex", alignItems: "baseline", gap: 1, mb: 1 }}>
              <Typography variant="h4">{seatQuota || 1}</Typography>
              <Typography variant="body2" color="text.secondary">
                席位
              </Typography>
            </Box>
            <Typography variant="caption" color="text.secondary" component="div">
              已用: {seatsUsed || 0} 席位
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "baseline",
                gap: 1,
              }}
            >
              <Typography variant="caption" color="text.secondary">
                剩余:
              </Typography>
              <Typography
                variant="subtitle2"
                color="primary"
                sx={{
                  fontWeight: "bold",
                  whiteSpace: "nowrap",
                }}
              >
                {expiredAt ? timeLeft(expiredAt) : "已过期"}
              </Typography>
            </Box>
          </Box>
        </Box>
        {action}
      </Card>
    </Container>
  );
};

export default PayerServiceCard;