type LogLevel = "debug" | "log" | "info" | "warn" | "error" | "none";

const logLevels: LogLevel[] = ["debug", "log", "info", "warn", "error", "none"];

let currentLogLevel: LogLevel = "log";

let displayCallerLine = false;

const originalConsole = { ...console };

function setLogLevel(level: LogLevel) {
  currentLogLevel = level;

  logLevels.forEach((methodName) => {
    if (methodName === "none") return;

    console[methodName] = (...args: any[]) => {
      if (logLevels.indexOf(methodName) >= logLevels.indexOf(currentLogLevel)) {
        const stack = new Error().stack;
        if (displayCallerLine) {
          const callerLine = stack?.split("\n")[2]?.trim() || "";
          originalConsole[methodName](`[${methodName}]`, ...args, callerLine);
        } else {
          originalConsole[methodName](`[${methodName}]`, ...args);
        }
      }
    };
  });

  if (level === "none") {
    logLevels.forEach((methodName) => {
      if (methodName === "none") return;
      console[methodName] = () => {};
    });
  }
}

function setDisplayCallerLine(value: boolean) {
  displayCallerLine = value;
}

export { setLogLevel, setDisplayCallerLine };
