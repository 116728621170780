import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import { Link } from "react-router-dom";
import logo from "@/assets/logo.png";

// MUI Icons
import DashboardIcon from "@mui/icons-material/Dashboard";
import LoginIcon from "@mui/icons-material/Login";
import { useAuth } from "@/context/AuthContext";

const Header: React.FC = () => {
  const { isAuthenticated } = useAuth();

  return (
    <>
      <AppBar elevation={1}>
        <Container
          maxWidth={false}
          sx={{
            px: { xs: 2, sm: 3, md: 4 },
          }}
        >
          <Toolbar sx={{ justifyContent: "space-between" }} disableGutters>
            <Link to="/">
              <Box component="img" src={logo} alt="Logo" sx={{ height: 40 }} />
            </Link>
            <Box sx={{ display: 'flex', gap: 1 }}>
              <Button
                href={isAuthenticated ? "/dashboard" : "/login"}
                startIcon={isAuthenticated ? <DashboardIcon /> : <LoginIcon />}
                variant="text"
                sx={(theme) => ({
                  color: theme.palette.text.primary,
                  "&:hover": {
                    backgroundColor: theme.palette.action.hover,
                  },
                })}
              >
                {isAuthenticated ? "管理面板" : "登录"}
              </Button>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Toolbar /> {/* 这个是占位组件，高度与 AppBar 一致 */}
    </>
  );
};

export default Header;
