import { useCallback } from "react";
import { useBasicInfo } from "@/context/BasicInfoContext";
import { actions, isInV1App } from "@/bridge/client";
import { useAuth } from "@/context/AuthContext";
import { authService } from "@/services/auth";

export const useActions = () => {
  const { links } = useBasicInfo();
  const { isAuthenticated } = useAuth();

  const urlWithAuth = useCallback(
    async (url: string) => {
      let next = url;
      if (links?.device_login && isAuthenticated) {
        const token = await authService.getToken();
        const deviceId = await authService.getDeviceId();
        const p = new URLSearchParams();
        p.append("deviceId", deviceId);
        p.append("token", token || "");
        p.append("next", url);
        p.append("time", Date.now() + "");
        if (isInV1App) {
          p.append("inApp", "true");
        }
        next = links.device_login + "?" + p.toString();
      }
      //console.debug(`the authed url is ${next}`);
      return next;
    },
    [links, isAuthenticated]
  );

  const purchase = useCallback(async () => {
    if (!links?.purchase_url) {
      return;
    }
    const deviceId = await authService.getDeviceId();
    if (isAuthenticated) {
      actions.externalOpen(links.purchase_url);
    } else {
      actions.externalOpen(`${links.purchase_url}?deviceId=${deviceId}`);
    }
  }, [links, isAuthenticated]);

  return {
    purchase,
    urlWithAuth,
  };
};
