import React from "react";
import { Box, Container, Typography, Paper } from "@mui/material";
import Grid from "@mui/material/Grid2";
import ContentLayout from "@/layouts/ContentLayout";

const AboutUs: React.FC = () => {
  const breadcrumbs = [
    { name: "控制面版", link: "/dashboard" },
    { name: "关于我们" },
  ];

  return (
    <ContentLayout breadcrumbs={breadcrumbs}>
      <Container maxWidth="sm" sx={{ py: 6 }}>
        {/* 标题部分 */}
        <Box textAlign="center" mb={6}>
          <Typography
            variant="h3"
            component="h1"
            gutterBottom
            sx={{
              fontWeight: "bold",
              color: "primary.main",
              mb: 2,
            }}
          >
            开路者
          </Typography>
          <Typography variant="h6" color="text.secondary" sx={{ mb: 4 }}>
            为真理开路，让消息畅通。
          </Typography>
        </Box>

        {/* 主要内容 */}
        <Grid container>
          <Grid>
            <Paper
              elevation={0}
              sx={{
                p: 4,
                bgcolor: "background.paper",
                borderRadius: 2,
                mt: 4,
              }}
            >
              <Typography
                variant="h5"
                gutterBottom
                sx={{ mb: 3, color: "primary.main" }}
              >
                我们的使命
              </Typography>
              <Typography>
                开路者致力于为企业、个人提供安全、稳定、高效的网络访问解决方案。以达到让通讯畅通、让贸易畅通、让沟通畅通、让真理畅通。
              </Typography>
            </Paper>
          </Grid>

          <Grid>
            <Paper
              elevation={0}
              sx={{
                p: 4,
                bgcolor: "background.paper",
                borderRadius: 2,
                mt: 4,
              }}
            >
              <Typography
                variant="h5"
                gutterBottom
                sx={{ mb: 3, color: "primary.main" }}
              >
                产品特点
              </Typography>
              <Typography sx={{ mb: 2 }}>
                • 全球加速：依托全球优质网络资源，为用户提供快速、稳定的访问体验
              </Typography>
              <Typography sx={{ mb: 2 }}>
                • 安全可靠：采用军工级加密技术，确保数据传输安全
              </Typography>
              <Typography sx={{ mb: 2 }}>
                • 简单易用：无需复杂配置，快速部署，支持多平台使用
              </Typography>
              <Typography sx={{ mb: 2 }}>
                • 灵活扩展：根据企业需求灵活调整配置，支持团队协作
              </Typography>
            </Paper>
          </Grid>

          <Grid>
            <Paper
              elevation={0}
              sx={{
                p: 4,
                bgcolor: "background.paper",
                borderRadius: 2,
                mt: 4,
              }}
            >
              <Typography
                variant="h5"
                gutterBottom
                sx={{ mb: 3, color: "primary.main" }}
              >
                成员背景
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                <Typography>
                  我们团队成员以华人为主，公司总部设立在新加坡。成员大多有美企工作经验，并在东南亚地区活动。我们欢迎华人小伙伴加入。
                </Typography>
              </Box>

              <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                <Box
                  sx={{
                    width: 109,
                    height: 34,
                    mr: 2,
                    bgcolor: "#f5f5f5",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 1,
                    border: "1px solid #e0e0e0",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="100%"
                    height="100%"
                    viewBox="0 0 108 57"
                    role="img"
                    style={{ maxWidth: "90%", maxHeight: "90%" }}
                  >
                    <defs>
                      <path
                        d="M0 22.769a2.348 2.348 0 0 0 3.935 1.545c.434-.403.7-.955.744-1.545v-5.2a2.34 2.34 0 0 0-4.679 0v5.2"
                        id="bar_short"
                      ></path>
                      <path
                        d="M12.95 22.769a2.349 2.349 0 0 0 2.34 2.171 2.346 2.346 0 0 0 2.339-2.171V11.112a2.341 2.341 0 0 0-4.679 0V22.77"
                        id="bar_tall"
                      ></path>
                      <path
                        d="M25.832 27.464a2.345 2.345 0 0 0 4.678 0V2.249a2.342 2.342 0 0 0-4.678 0v25.215"
                        id="bar_grande"
                      ></path>
                      <path
                        d="M24.026 56.277v-5.002l-.098.043a9.253 9.253 0 0 1-3.605.915 5.303 5.303 0 0 1-3.63-1.07 4.644 4.644 0 0 1-1.58-2.244 5.395 5.395 0 0 1-.106-3 4.6 4.6 0 0 1 1.609-2.566 4.823 4.823 0 0 1 2.528-1.09 8.332 8.332 0 0 1 4.774.895l.108.056v-5.03l-.228-.061a12.78 12.78 0 0 0-4.552-.596 10.534 10.534 0 0 0-4.065.93 9.29 9.29 0 0 0-3.329 2.572 10.014 10.014 0 0 0-.182 12.18 9.546 9.546 0 0 0 5.292 3.403c2.211.538 4.528.456 6.697-.234l.367-.101"
                        id="cisco_c"
                      ></path>
                    </defs>
                    <g fill="#00bceb" fill-rule="evenodd">
                      <use href="#cisco_c"></use>
                      <use href="#cisco_c" x="49.5"></use>
                      <path d="M41.69 52.125l-.019 4.308.315.054c.989.185 1.987.313 2.99.385a18.1 18.1 0 0 0 2.642.051c.842-.06 1.675-.218 2.48-.468a6.862 6.862 0 0 0 2.573-1.371 5.19 5.19 0 0 0 1.498-2.148 5.912 5.912 0 0 0-.03-4.324 4.852 4.852 0 0 0-1.343-1.862 5.568 5.568 0 0 0-1.97-1.147l-3.25-1.206a1.742 1.742 0 0 1-.887-.845 1.107 1.107 0 0 1 .036-.986 1.29 1.29 0 0 1 .217-.291 1.75 1.75 0 0 1 .48-.347c.363-.18.755-.293 1.158-.337a6.76 6.76 0 0 1 2.072.022c.81.088 1.613.231 2.402.43l.168.037v-3.974l-.31-.067a21.14 21.14 0 0 0-2.444-.435 12.545 12.545 0 0 0-3.213-.014 6.945 6.945 0 0 0-3.699 1.488 4.908 4.908 0 0 0-1.58 2.178 5.984 5.984 0 0 0-.003 4.05c.242.65.63 1.237 1.136 1.714.437.422.932.78 1.47 1.065.708.38 1.458.675 2.235.878.257.077.512.158.766.243l.385.141.11.045c.38.136.726.35 1.018.628.202.19.358.423.455.683.06.237.061.485.003.723a1.536 1.536 0 0 1-.744.892 3.691 3.691 0 0 1-1.239.387 9 9 0 0 1-1.92.097 21.973 21.973 0 0 1-2.507-.334c-.433-.09-.864-.19-1.291-.303l-.16-.04zM30.546 56.607h4.73V37.846h-4.73zM85.305 43.386a4.934 4.934 0 1 1 6.157 7.711 4.934 4.934 0 0 1-6.157-7.71m-6.867 3.848A9.87 9.87 0 0 0 90.44 56.72a9.629 9.629 0 0 0 3.157-17.729 9.934 9.934 0 0 0-15.16 8.244"></path>
                      <use href="#bar_short" x="0"></use>
                      <use href="#bar_tall" x="0"></use>
                      <use href="#bar_grande" x="0"></use>
                      <use href="#bar_tall" x="25.875"></use>
                      <use href="#bar_short" x="51.75"></use>
                      <use href="#bar_tall" x="51.75"></use>
                      <use href="#bar_grande" x="51.75"></use>
                      <use href="#bar_tall" x="77.625"></use>
                      <use href="#bar_short" x="103.375"></use>
                    </g>
                  </svg>
                </Box>
                <Typography>Anyconnect</Typography>
              </Box>

              <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                <Box
                  sx={{
                    width: 109,
                    height: 34,
                    mr: 2,
                    bgcolor: "#000",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 1,
                    border: "1px solid #e0e0e0",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="100%"
                    height="100%"
                    viewBox="0 0 109 34"
                    style={{ maxWidth: "90%", maxHeight: "90%" }}
                  >
                    <path
                      fill="#fff"
                      fill-rule="evenodd"
                      d="M108.828 8.798c0 .633-.522 1.147-1.165 1.147a1.156 1.156 0 0 1-1.164-1.147c0-.634.521-1.148 1.164-1.148s1.165.514 1.165 1.148m-2.135 14.152h1.941V12.546h-1.941zm-7.013-1.912c-1.844 0-3.338-1.473-3.338-3.29s1.494-3.29 3.337-3.29 3.337 1.473 3.337 3.29-1.494 3.29-3.337 3.29Zm5.277-3.29v-5.202h-1.941v1.173a5.3 5.3 0 0 0-3.337-1.173c-2.915 0-5.278 2.329-5.278 5.202s2.363 5.202 5.278 5.202a5.3 5.3 0 0 0 3.337-1.173v1.173h1.941z"
                      clip-rule="evenodd"
                    ></path>
                    <path
                      fill="#FF006B"
                      fill-rule="evenodd"
                      d="M93.405 21.802c0 .634-.521 1.148-1.164 1.148a1.156 1.156 0 0 1-1.165-1.148c0-.633.522-1.147 1.165-1.147s1.164.514 1.164 1.147"
                      clip-rule="evenodd"
                    ></path>
                    <path
                      fill="#fff"
                      fill-rule="evenodd"
                      d="M83.885 21.038c-1.843 0-3.337-1.473-3.337-3.29s1.494-3.29 3.337-3.29 3.337 1.473 3.337 3.29-1.494 3.29-3.337 3.29m3.337-7.32a5.3 5.3 0 0 0-3.337-1.172c-2.915 0-5.277 2.329-5.277 5.202s2.362 5.202 5.277 5.202a5.3 5.3 0 0 0 3.337-1.173v1.173h1.94V12.546h-1.94zM75.45 7.65h-1.94v4.896h-2.522v1.913h2.522v8.491h1.94v-8.491h2.522v-1.913H75.45zM64.01 21.038c-1.842 0-3.337-1.473-3.337-3.29s1.495-3.29 3.338-3.29 3.337 1.473 3.337 3.29-1.494 3.29-3.337 3.29Zm3.338-7.32a5.3 5.3 0 0 0-3.337-1.172c-2.915 0-5.278 2.329-5.278 5.202s2.363 5.202 5.278 5.202a5.3 5.3 0 0 0 3.337-1.173v1.173h1.94V12.546h-1.94zm-15.331 7.32c-1.844 0-3.338-1.473-3.338-3.29s1.494-3.29 3.337-3.29 3.338 1.473 3.338 3.29-1.494 3.29-3.337 3.29m3.337-7.32a5.3 5.3 0 0 0-3.337-1.172c-2.915 0-5.278 2.329-5.278 5.202s2.363 5.202 5.278 5.202c1.266 0 2.427-.44 3.337-1.173v1.173h1.94V7.65h-1.94v6.069Z"
                      clip-rule="evenodd"
                    ></path>
                    <path
                      fill="url(#logo-color_svg__a)"
                      fill-rule="evenodd"
                      d="M13.828 0 .196 13.699l-.024.024 20.155 20.252.025.025 20.154-20.254.024-.024L26.884.01l-.01-.01zm2.029 1.692h8.955l-4.477 4.383-4.479-4.383Zm5.755 5.683 5.09-5.06 10.6 10.54h-10.18zm-7.632-5.06 5.111 5.092-5.468 5.448H3.4zm1.041 11.564 5.33-5.373 5.33 5.373L20.351 30zm12.323.667h9.858L22.416 29.472zm-23.944 0h9.947l4.973 14.927z"
                      clip-rule="evenodd"
                    ></path>
                    <defs>
                      <linearGradient
                        id="logo-color_svg__a"
                        x1="20.351"
                        x2="0.172"
                        y1="13.448"
                        y2="13.448"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#fff"></stop>
                        <stop offset="1" stopColor="#FD1F6D"></stop>
                      </linearGradient>
                    </defs>
                  </svg>
                </Box>
                <Typography>Data.ai</Typography>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </ContentLayout>
  );
};

export default AboutUs;
