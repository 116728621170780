import React, { useEffect, useState } from "react";
import {
  Typography,
  Card,
  CardContent,
  Button,
  Box,
  styled,
  Table,
  TableBody,
  TableCell,
  TableRow,
  CircularProgress,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { VpnService } from "@/utils/data";
import { mbToHumanReadable } from "@/utils";
import NumberInputField from "./NumberInputField";
import api from "@/utils/api";
import { ResponseList } from "@/utils/api";
import { useToast } from "@/context/ToastContext";

const Ribbon = styled("div")(({ theme }) => ({
  position: "absolute",
  right: 0,
  top: 0,
  width: "100px",
  height: "100px",
  overflow: "hidden",
  "&::before": {
    content: '"已选择"',
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "150px",
    height: "30px",
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    transform: "rotate(45deg) translateY(-20px) translateX(50px)",
    boxShadow: "0 5px 10px rgba(0,0,0,0.1)",
    fontWeight: "bold",
    fontSize: "14px",
  },
}));

const StyledCard = styled(Card)(({ theme }) => ({
  cursor: "pointer",
  transition: "0.3s",
  position: "relative",
  overflow: "visible",
  "&:hover": {
    transform: "translateY(-4px)",
    boxShadow: theme.shadows[4],
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderBottom: "none",
  padding: theme.spacing(1),
  "&.label": {
    color: theme.palette.text.secondary,
    width: "40%",
  },
  "&.value": {
    fontWeight: 500,
    width: "60%",
  },
}));

interface SetMyServiceProps {
  onSuccess?: () => void;
}

const SetMyService: React.FC<SetMyServiceProps> = ({ onSuccess }) => {
  const { toast } = useToast();
  const [services, setServices] = useState<VpnService[]>([]);
  const [service, setService] = useState<VpnService | null>(null);
  const [seats, setSeats] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const minSeats = service?.content?.startUsers || 1;

  useEffect(() => {
    setIsLoading(true);
    api<ResponseList<VpnService>>({
      path: "/api/services",
      method: "get",
    })
      .then(({ data }) => {
        const ss = data.items;
        setServices(ss);
        if (ss.length > 0) {
          setService(ss[ss.length - 1]);
        }
      })
      .catch((error) => {
        toast("error", "获取服务时发生错误");
        console.error("获取服务时发生错误:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    if (service) {
      setSeats(service.content.startUsers);
    }
  }, [service]);

  const handleSubmit = () => {
    if (!service) {
      toast("error", "请选择服务方案");
      return;
    }

    setIsSubmitting(true);

    api<unknown>({
      path: "/api/my-service",
      method: "post",
      params: {
        serviceId: service.id,
        seats: seats,
      },
    })
      .then(({ code, msg }) => {
        if (code === 0) {
          toast("success", "设置服务成功");
          onSuccess?.();
        } else {
          toast("error", msg);
        }
      })
      .catch(() => {
        toast("error", "请求发生错误，请重试");
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="300px"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <Typography variant="h6" gutterBottom color="text.primary" sx={{ mb: 2 }}>
        选择服务方案
      </Typography>
      <Grid container spacing={3}>
        {services.map((s) => (
          <Grid size={{ xs: 12, md: 6 }} key={s.id}>
            <StyledCard
              raised={service?.id === s.id}
              onClick={() => setService(s)}
              sx={{
                bgcolor:
                  service?.id === s.id ? "action.selected" : "background.paper",
              }}
            >
              {service?.id === s.id && <Ribbon />}
              <CardContent>
                <Typography variant="h6" gutterBottom align="center">
                  {s?.content?.startUsers}
                </Typography>

                <Table size="small">
                  <TableBody>
                    <TableRow hover>
                      <StyledTableCell className="label">
                        单用户月流量
                      </StyledTableCell>
                      <StyledTableCell className="value">
                        {mbToHumanReadable(s.content.maxByteflowMbPerUser)}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow hover>
                      <StyledTableCell className="label">
                        单用户设备数量
                      </StyledTableCell>
                      <StyledTableCell className="value">
                        {s.content.devicePerUser} 台
                      </StyledTableCell>
                    </TableRow>
                    <TableRow hover>
                      <StyledTableCell className="label">
                        起购人数
                      </StyledTableCell>
                      <StyledTableCell className="value">
                        {s.content.startUsers} 人
                      </StyledTableCell>
                    </TableRow>
                  </TableBody>
                </Table>

                <Box sx={{ mt: 2, textAlign: "center" }}>
                  <Button
                    variant={service?.id === s.id ? "contained" : "outlined"}
                    size="large"
                    fullWidth
                  >
                    {service?.id === s.id ? "已选择" : "选择此方案"}
                  </Button>
                </Box>
              </CardContent>
            </StyledCard>
          </Grid>
        ))}
      </Grid>

      <Box mt={4} display="flex" flexDirection="column" alignItems="center">
        <Typography
          variant="h6"
          gutterBottom
          color="text.primary"
          sx={{ mb: 2 }}
        >
          选择成员数量
        </Typography>
        <Box>
          <NumberInputField
            value={seats}
            minValue={minSeats}
            onChange={setSeats}
          />
        </Box>
      </Box>

      <Box mt={4} textAlign="center">
        <Button
          variant="contained"
          size="large"
          disabled={!service || isSubmitting}
          onClick={handleSubmit}
          sx={{ minWidth: "200px", py: 1.5 }}
        >
          {isSubmitting ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            "确认选择"
          )}
        </Button>
      </Box>
    </Box>
  );
};

export default SetMyService;
