import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

interface AppHeaderProps {
  title: string;
  onBack?: () => void;
  action?: React.ReactNode;
}

const AppHeader: React.FC<AppHeaderProps> = ({ title, onBack, action }) => {

  return (
    <>
      <AppBar 
        position="fixed" 
        elevation={0}
        className="app-header"
        sx={{
          top: 0,
          left: 0,
          right: 0,
          zIndex: (theme) => theme.zIndex.appBar,
          '&.app-header': {
            backgroundColor: 'background.default',
            color: 'text.primary',
            borderBottom: 1,
            borderColor: 'divider',
            boxShadow: (theme) => 
              theme.palette.mode === 'dark' 
                ? '0 1px 2px rgba(0, 0, 0, 0.3)'
                : '0 1px 1px rgba(0, 0, 0, 0.05)'
          }
        }}
      >
        <Toolbar>
          {onBack && (
            <IconButton
              edge="start"
              color="inherit"
              onClick={onBack}
              sx={{ mr: 2 }}
            >
              <ArrowBackIcon />
            </IconButton>
          )}
          {title && (
            <Typography
              variant="h6"
              component="div"
              sx={{
                flex: 1,
                textAlign: "center",
                mr: action ? 0 : 8, // 如果右侧没有action，补充右侧间距保持标题居中
              }}
            >
              {title}
            </Typography>
          )}

          {action && <Box sx={{ ml: 2 }}>{action}</Box>}
        </Toolbar>
      </AppBar>
    </>
  );
};

export default AppHeader;
