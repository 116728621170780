import { base64Encode } from "@/utils";
import cache from "@/utils/cache";

// 事件名称常量
export const CustomerKeyPointEvent = {
  INPUT_CAMPAIGN: "input_campaign", // 填寫邀請碼
  OPEN: "open", // 首次打開页面
  CREATE_ORDER: "create_order", // 创建订单
  PAY: "pay", // 打開付款頁
  PAY_FAILED: "pay_failed", // 付款失敗
  PAY_SUCCESS: "pay_success", // 付款成功
} as const;

// 从常量类型中提取事件名称类型
export type CustomerEventName =
  (typeof CustomerKeyPointEvent)[keyof typeof CustomerKeyPointEvent] | string;

// 事件元数据类型
export type EventMeta = {
  description: string;
  [key: string]: any;
};

// 基础事件数据类型
export type BaseEventData = {
  page: string;
  customer: string;
  event: string;
  meta: EventMeta;
  at: number;
};

// 内部使用的带重试信息的事件类型
type EventWithRetry = BaseEventData & {
  retryCount: number;
  nextRetryTime?: number;
};

class CustomerEventEmitter {
  private static instance: CustomerEventEmitter;
  private readonly FAILED_EVENTS_KEY = "evt@queue";
  private readonly FAILED_EVENTS_CACHE_DAYS = 7; // 失败事件缓存7天
  private eventQueue: EventWithRetry[] = [];
  private isProcessing = false;
  private sellerEventUrl: string | null = null;
  private isOnline = true; // 网络状态标记

  private constructor() {
    this.loadFailedEvents();
  }

  public static getInstance(): CustomerEventEmitter {
    if (!CustomerEventEmitter.instance) {
      CustomerEventEmitter.instance = new CustomerEventEmitter();
    }
    return CustomerEventEmitter.instance;
  }

  // 设置网络状态
  public setNetworkStatus(status: boolean) {
    const statusChanged = this.isOnline !== status;
    this.isOnline = status;

    // 如果网络从离线变为在线，尝试处理队列
    if (statusChanged && status) {
      this.processQueue();
    }
  }

  // 设置发送URL
  public setEventUrl(url: string) {
    this.sellerEventUrl = url;
    // URL 设置后，尝试处理队列
    this.processQueue();
  }

  // 发送新事件
  public emit(eventData: BaseEventData) {
    console.debug("[CustomerEventEmitter] Emitting new event:", {
      event: eventData.event,
      customer: eventData.customer,
      meta: eventData.meta,
    });

    const enhancedEvent: EventWithRetry = {
      ...eventData,
      retryCount: 0,
    };
    this.eventQueue.push(enhancedEvent);
    console.debug(
      "[CustomerEventEmitter] Current queue length:",
      this.eventQueue.length
    );
    this.processQueue();
  }

  // 加载失败的事件
  private async loadFailedEvents() {
    try {
      const failedEvents = await cache.get<EventWithRetry[]>(this.FAILED_EVENTS_KEY);
      if (
        failedEvents &&
        Array.isArray(failedEvents) &&
        failedEvents.length > 0
      ) {
        console.debug("Loaded failed events:", failedEvents.length);
        this.eventQueue.push(...failedEvents);
      }
    } catch (error) {
      console.error("Failed to load failed events:", error);
      // 清除可能损坏的缓存数据
      await cache.del(this.FAILED_EVENTS_KEY);
    }
  }

  // 保存失败的事件
  private async saveFailedEvents() {
    if (this.eventQueue.length > 0) {
      await cache.set(
        this.FAILED_EVENTS_KEY,
        this.eventQueue,
        this.FAILED_EVENTS_CACHE_DAYS * 24 * 60 * 60
      );
    } else {
      await cache.del(this.FAILED_EVENTS_KEY);
    }
  }

  // 处理事件队列
  private async processQueue() {
    if (
      this.isProcessing ||
      !this.sellerEventUrl ||
      !this.isOnline ||
      this.eventQueue.length === 0
    ) {
      console.debug("[CustomerEventEmitter] Skip processing queue:", {
        isProcessing: this.isProcessing,
        hasUrl: !!this.sellerEventUrl,
        isOnline: this.isOnline,
        queueLength: this.eventQueue.length,
      });
      return;
    }

    console.debug("[CustomerEventEmitter] Start processing queue");
    this.isProcessing = true;

    try {
      while (this.eventQueue.length > 0) {
        const event = this.eventQueue[0];
        const now = Math.floor(Date.now() / 1000);

        if (event.nextRetryTime && event.nextRetryTime > now) {
          console.debug("[CustomerEventEmitter] Waiting for retry:", {
            event: event.event,
            nextRetryTime: new Date(event.nextRetryTime * 1000).toISOString(),
            remainingSeconds: event.nextRetryTime - now,
          });
          break;
        }

        if (event.retryCount >= 20) {
          console.warn(
            "[CustomerEventEmitter] Event dropped due to max retries:",
            event
          );
          this.eventQueue.shift();
          continue;
        }

        try {
          console.debug("[CustomerEventEmitter] Attempting to send event:", {
            event: event.event,
            retryCount: event.retryCount,
          });

          await this.sendEvent(event);
          console.debug(
            "[CustomerEventEmitter] Event sent successfully:",
            event.event
          );
          this.eventQueue.shift();
        } catch (error: any) {
          const delay = 5 * Math.pow(2, event.retryCount); // 5秒 * 2^重試次數

          console.warn("[CustomerEventEmitter] Failed to send event:", {
            event: event.event,
            error: error.message,
            retryCount: event.retryCount,
            nextDelay: delay,
            nextRetryTime: new Date((now + delay) * 1000).toISOString(),
          });

          this.eventQueue[0] = {
            ...event,
            retryCount: event.retryCount + 1,
            nextRetryTime: now + delay,
          };
          break;
        }

        await new Promise((resolve) => setTimeout(resolve, 200));
      }
    } finally {
      console.debug("[CustomerEventEmitter] Queue processing finished", {
        remainingEvents: this.eventQueue.length,
      });
      this.isProcessing = false;
      await this.saveFailedEvents();
    }
  }

  // 实际发送事件的方法
  private async sendEvent(event: EventWithRetry): Promise<void> {
    if (!this.sellerEventUrl) {
      console.error("[CustomerEventEmitter] Seller event URL not set");
      throw new Error("Seller event URL not set");
    }

    // 忽略 retryCount 和 nextRetryTime，只是为了得到 baseEventData
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { retryCount, nextRetryTime, ...baseEventData } = event;
    const eventBase64 = base64Encode(JSON.stringify(baseEventData));
    const imgUrl = `${this.sellerEventUrl}?v=${encodeURIComponent(
      eventBase64
    )}`;

    console.debug("[CustomerEventEmitter] Sending event request:", {
      event: event.event,
      url: imgUrl.substring(0, 100) + "...", // 只顯示部分URL
    });

    return new Promise((resolve, reject) => {
      const img = new Image();
      const timeoutId = setTimeout(() => {
        console.warn(
          "[CustomerEventEmitter] Request timeout for event:",
          event.event
        );
        reject(new Error("Request timeout"));
      }, 10000);

      img.onload = () => {
        clearTimeout(timeoutId);
        console.debug(
          "[CustomerEventEmitter] Image loaded successfully for event:",
          event.event
        );
        resolve();
      };

      img.onerror = () => {
        clearTimeout(timeoutId);
        console.warn(
          "[CustomerEventEmitter] Image load failed for event:",
          event.event
        );
        reject(new Error("Failed to send event"));
      };

      img.src = imgUrl;
    });
  }
}

export const customerEventEmitter = CustomerEventEmitter.getInstance();
