import React, { useEffect, useMemo, useState } from "react";
import { mbToHumanReadable } from "@/utils";
import { VpnService, VpnPlan, Order } from "@/utils/data";
import { useQuickStartTracker } from "../hooks/useQuickStartTracker";
import ContentLayout from "@/layouts/ContentLayout";
import {
  Container,
  Typography,
  Card,
  CardContent,
  Button,
  TextField,
  Box,
  Alert,
  styled,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import PlanCard from "@/components/PlanCard";
import { useToast } from "@/context/ToastContext";
import api, { ResponseList } from "@/utils/api";
import NumberInputField from "@/components/NumberInputField";
import { useNavigate } from "react-router-dom";
import { usePay } from "@/hooks/usePay";
import { useAuth } from "@/context/AuthContext";
import { authService } from "@/services/auth";

// 样式组件定义
const Ribbon = styled("div")(({ theme }) => ({
  position: "absolute",
  right: 0,
  top: 0,
  width: "100px",
  height: "100px",
  overflow: "hidden",
  "&::before": {
    content: '"已选择"',
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "150px",
    height: "30px",
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    transform: "rotate(45deg) translateY(-20px) translateX(50px)",
    boxShadow: "0 5px 10px rgba(0,0,0,0.1)",
    fontWeight: "bold",
    fontSize: "14px",
  },
}));

const StyledCard = styled(Card)(({ theme }) => ({
  cursor: "pointer",
  transition: "0.3s",
  position: "relative",
  overflow: "visible",
  "&:hover": {
    transform: "translateY(-4px)",
    boxShadow: theme.shadows[4],
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderBottom: "none",
  padding: theme.spacing(1),
  "&.label": {
    color: theme.palette.text.secondary,
    width: "40%",
  },
  "&.value": {
    fontWeight: 500,
    width: "60%",
  },
}));

const QuickStart: React.FC = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/purchase");
    }
  }, [isAuthenticated]);

  const [isLoading, setIsLoading] = useState(false);
  const [seats, setSeats] = useState(1);
  const [services, setServices] = useState<VpnService[]>([]);
  const [service, setService] = useState<VpnService | null>(null);
  const [email, setEmail] = useState<string>("");
  const [inviteCode, setInviteCode] = useState<string>("");
  const [plan, setPlan] = useState<VpnPlan | null>(null);
  const [errs, setErrs] = useState<{
    email?: string;
    seats?: string;
    planId?: string;
  }>({});
  const [emailOk, setEmailOk] = useState(false);
  const { pay } = usePay();

  // Hooks
  const { toast } = useToast();
  const { createOrderEvent, campaign } = useQuickStartTracker({
    service,
    plan,
    seats,
    email,
  });

  // 计算属性
  const plans = useMemo(() => {
    const servicePlans = (service && service.plans) || [];
    return [...servicePlans].sort((a, b) => a.price - b.price);
  }, [service]);

  const minSeats = useMemo(() => {
    return (service && service.content.startUsers) || 1;
  }, [service]);

  // 副作用
  useEffect(() => {
    document.title = "快速開通開路者";
  }, []);

  useEffect(() => {
    if (plans.length > 0) {
      const middleIndex = Math.floor(plans.length / 2);
      setPlan(plans[middleIndex]);
    }
  }, [plans]);

  useEffect(() => {
    api<ResponseList<VpnService>>({
      path: "/api/services",
      method: "get",
      authRequired: false,
    })
      .then(({ code, msg, data }) => {
        if (code === 0) {
          const ss = data.items;
          setServices(ss);
          setService(ss[ss.length - 1]);
        } else {
          toast("error", msg);
        }
      })
      .catch(() => {
        toast("error", "獲取服務時發生錯誤");
      });
  }, []);

  useEffect(() => {
    if (service) {
      setSeats(minSeats);
    }
  }, [service]);

  // 处理函数
  const validateEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    const email = e.target.value;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setErrs({
        email: "郵箱格式不正確",
      });
      setEmail("");
    } else {
      setErrs({ ...errs, email: "" });
      setEmail(email);
    }
  };

  const handleNextStep = async () => {
    try {
      setIsLoading(true);
      const { code, msg, data } = await api<{ firstOrderDone: boolean }>({
        path: "/api/check-first-order-user",
        method: "post",
        params: { email },
        authRequired: false,
      });

      if (code === 0) {
        if (data.firstOrderDone) {
          // 已完成首单的用户，发送验证码并跳转
          toast("info", "我們正引导您登录...");
          const { code: authCode, data: authData } = await api<{
            authId: string;
          }>({
            path: "/api/get-auth-code",
            method: "post",
            params: {
              email,
              deviceId: await authService.getDeviceId(),
              deviceName: "web",
            },
          });

          if (authCode === 0) {
            navigate(
              `/auth-verify?email=${email}&authId=${authData.authId}&next=/purchase`
            );
          } else {
            toast("error", msg);
          }
        } else {
          // 新用户或未完成首单，设置 emailOk 为 true
          setEmailOk(true);
        }
      } else {
        setErrs({
          email: msg,
        });
        toast("error", msg);
      }
    } catch (error) {
      console.error(error);
      toast("error", "验证用户信息失败");
    } finally {
      setIsLoading(false);
    }
  };

  const handlePlanSelect = (selectedPlan: VpnPlan) => {
    setPlan(selectedPlan);
  };

  const createOrder = () => {
    if (!plan) {
      toast("error", "請選擇服務方案");
      return;
    }
    setErrs({});
    createOrderEvent();

    const params = {
      email,
      seats,
      planId: plan.id,
      inviteCode,
      seller: {
        campaign,
      },
    };

    api<Order>({
      path: "/api/quick-order",
      method: "post",
      params,
      authRequired: false,
    })
      .then(({ code, msg, data }) => {
        if (code === 0) {
          pay(data.uuid)
        } else {
          setErrs({
            ...data,
            planId: msg,
          });
          toast("error", msg);
        }
      })
      .catch(() => {
        console.error("創建訂單時發生錯誤");
        setErrs({
          planId: "請求發生錯誤，請重試",
        });
      });
  };

  // 渲染方法
  const renderUserInfo = () => (
    <Box mb={4}>
      <Typography variant="h6" gutterBottom color="text.primary">
        新用户信息
      </Typography>
      <Alert severity="warning" sx={{ mb: 2 }}>
        <strong>重要提醒：</strong>
        <ul style={{ margin: "8px 0", paddingLeft: "20px" }}>
          <li>此邮箱将作为您的账号，请确保是您本人的邮箱</li>
          <li>如填写他人邮箱，充值金额将无法退回</li>
        </ul>
      </Alert>
      <Box mb={2}>
        <TextField
          fullWidth
          type="email"
          error={!!errs.email}
          disabled={isLoading}
          helperText={errs.email || "我们会将此邮箱作为您的账号标识"}
          placeholder="邮箱（必填）"
          defaultValue={email}
          onChange={validateEmail}
          slotProps={{
            input: {
              endAdornment: emailOk && (
                <Button
                  onClick={() => setEmailOk(false)}
                  sx={{
                    minWidth: "60px",
                    px: 1,
                  }}
                >
                  修改
                </Button>
              ),
              readOnly: emailOk,
            },
          }}
        />
      </Box>

      <Box mb={2}>
        <TextField
          fullWidth
          type="text"
          disabled={isLoading || emailOk}
          value={inviteCode}
          onChange={(e) => setInviteCode(e.target.value)}
          placeholder="邀请码（选填）"
          helperText="如果您有邀请码，请在此输入"
          sx={{ mt: 2 }}
        />
      </Box>

      {!emailOk && (
        <Box textAlign="center" mt={4}>
          <Button
            variant="contained"
            size="large"
            disabled={!email || isLoading}
            onClick={handleNextStep}
            sx={{ minWidth: "200px", py: 1.5 }}
          >
            {isLoading ? "驗證中..." : "下一步"}
          </Button>
        </Box>
      )}
    </Box>
  );

  const renderServiceSelection = () => (
    <Box mb={4}>
      <Typography variant="h6" gutterBottom color="text.primary" sx={{ mb: 2 }}>
        选择服务方案
      </Typography>
      <Grid container spacing={3}>
        {services.map((s) => (
          <Grid size={{ xs: 12, md: 6 }} key={s.id}>
            <StyledCard
              raised={service?.id === s.id}
              onClick={() => setService(s)}
              sx={{
                bgcolor:
                  service?.id === s.id ? "action.selected" : "background.paper",
              }}
            >
              {service?.id === s.id && <Ribbon />}
              <CardContent>
                <Typography variant="h6" gutterBottom align="center">
                  {s?.content?.startUsers}
                </Typography>

                <Table size="small">
                  <TableBody>
                    <TableRow hover>
                      <StyledTableCell className="label">
                        单用户月流量
                      </StyledTableCell>
                      <StyledTableCell className="value">
                        {mbToHumanReadable(s.content.maxByteflowMbPerUser)}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow hover>
                      <StyledTableCell className="label">
                        单用户设备数量
                      </StyledTableCell>
                      <StyledTableCell className="value">
                        {s.content.devicePerUser} 台
                      </StyledTableCell>
                    </TableRow>
                    <TableRow hover>
                      <StyledTableCell className="label">
                        起购人数
                      </StyledTableCell>
                      <StyledTableCell className="value">
                        {s.content.startUsers} 人
                      </StyledTableCell>
                    </TableRow>
                  </TableBody>
                </Table>

                <Box sx={{ mt: 2, textAlign: "center" }}>
                  <Button
                    variant={service?.id === s.id ? "contained" : "outlined"}
                    size="large"
                    fullWidth
                  >
                    {service?.id === s.id ? "已选择" : "选择此方案"}
                  </Button>
                </Box>
              </CardContent>
            </StyledCard>
          </Grid>
        ))}
      </Grid>

      {service && (
        <Box mt={4} display="flex" flexDirection="column" alignItems="center">
          <Typography
            variant="h6"
            gutterBottom
            color="text.primary"
            sx={{ mb: 2 }}
          >
            选择成员数量
          </Typography>
          <Box>
            <NumberInputField
              value={seats}
              minValue={minSeats}
              onChange={setSeats}
            />
          </Box>
        </Box>
      )}
    </Box>
  );

  const renderPaymentPlans = () => (
    <Box mb={4}>
      <Typography variant="h6" gutterBottom color="text.primary" sx={{ mb: 2 }}>
        选择付款方式
      </Typography>
      <Grid container spacing={3}>
        {plans.map((p) => (
          <Grid size={{ xs: 12, md: 4 }} key={p.id}>
            <PlanCard
              plan={p}
              seats={seats}
              isSelected={plan?.id === p.id}
              onClick={() => handlePlanSelect(p)}
            />
          </Grid>
        ))}
      </Grid>
      {errs.planId && (
        <Alert severity="error" sx={{ mt: 2 }}>
          {errs.planId}
        </Alert>
      )}

      <Box mt={4} textAlign="center">
        <Button
          variant="contained"
          size="large"
          disabled={!plan}
          onClick={createOrder}
          sx={{ minWidth: "10rem" }}
        >
          下一步
        </Button>
      </Box>
    </Box>
  );

  // 主渲染
  return (
    <ContentLayout
      header={
        <Typography
          variant="h3"
          component="h1"
          align="center"
          gutterBottom
          sx={{
            mt: 6,
            mb: 1,
            fontWeight: "bold",
            color: "primary.main",
          }}
        >
          快速购买开路者
        </Typography>
      }
    >
      <Container maxWidth="lg" sx={{ width: "100%", mx: "auto" }}>
        {renderUserInfo()}
        {emailOk && renderServiceSelection()}
        {emailOk && service && renderPaymentPlans()}
      </Container>
    </ContentLayout>
  );
};

export default QuickStart;
