import React, {
  createContext,
  useContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useToast } from "@/context/ToastContext";
import { useI18n } from "@/context/I18nContext";
import { useAuth } from "@/context/AuthContext";
import { useMyService } from "@/context/MyServiceContext";
import api from "@/utils/api";
import cache from "@/utils/cache";
import dayjs from "dayjs";
import { formatSeconds } from "@/utils/time";
import {
  vpn,
  vpnStatusName,
  actions,
  isAndroidApp,
  isIosApp,
  isDesktopApp,
} from "@/bridge/client";
import {
  VpnConnectionErrorEvent,
  VpnConnectionStatusChangeEvent,
  RouteConfig,
  InstalledApp,
} from "@/bridge/defination";
import { VpnServer, VpnGroups, VpnUseStats } from "@/utils/data";
import { authService } from "@/services/auth";

const CACHE_KEYS = {
  IPV4_LIST: "ipv4_list",
  PAC_CONFIG: "pac_list",
};

interface VpnStatus {
  status: string;
  message: string;
}

interface Stats {
  isEnough: boolean;
  isUsedUp: boolean;
  isUnlimited: boolean;
  rated: number;
  theIn: number;
  theOut: number;
  used: number;
}

interface VpnContextType {
  // VPN连接相关
  connect: (server: VpnServer) => Promise<void>;
  disconnect: () => Promise<void>;
  status: VpnStatus;
  syncStatus: () => Promise<void>;

  // VPN路由相关
  routeConfig: RouteConfig;
  setAutoMode: (isAuto: boolean) => Promise<void>;
  isAuto: boolean;

  // VPN服务器组相关
  groups: VpnGroups | null;
  groupedServers: Array<{
    id: number;
    title: string;
    data: VpnServer[];
  }>;
  setGroups: (groups: VpnGroups) => void;
  onTrial: boolean;
  trialRestTime: string;

  // VPN服务器选择相关
  server: VpnServer | null;
  setServer: (server: VpnServer) => Promise<void>;

  // VPN使用统计相关
  stats: Stats;
  setStats: (stats: VpnUseStats) => void;
}

const VpnContext = createContext<VpnContextType | null>(null);

export const VpnProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { t } = useI18n();
  const { toast } = useToast();
  const { isAuthenticated } = useAuth();
  const { expired, isLoading } = useMyService();

  // 状态管理
  const [vpnStatus, setVpnStatus] = useState<VpnStatus>({
    status: "",
    message: "",
  });
  const [routeConfig, setRouteConfig] = useState<RouteConfig>(
    {} as RouteConfig
  );
  const [installedApps, setInstalledApps] = useState<InstalledApp[]>([]);
  const [groups, setGroups] = useState<VpnGroups | null>(null);
  const [serverId, setServerId] = useState<string>("");
  const [onTrial, setOnTrial] = useState(false);
  const [trialRestTime, setTrialRestTime] = useState("00:00");
  const [vpnUseStats, setVpnUseStats] = useState<VpnUseStats>();

  // VPN连接相关逻辑
  const disconnect = useCallback(async () => {
    console.info(`disconnect the vpn`);
    await vpn.disconnect();
  }, []);

  const syncStatus = useCallback(async () => {
    const state = await vpn.currentState();
    setVpnStatus({
      status: state.status,
      message: vpnStatusName(state.status),
    });
  }, []);

  const connect = useCallback(
    async (server: VpnServer) => {
      if (!server) {
        toast("error", t("vpn.alert.no_server_selected"));
        return;
      }
      if (isAuthenticated) {
        setVpnStatus({
          status: "connecting",
          message: "连接中...",
        });
        const token = await authService.getToken();
        const deviceId = await authService.getDeviceId();
        const { protocol, domain, port } = server;
        const proto = protocol || "anyconnect";
        const url = `${proto}://${deviceId}:${token}@${domain}:${port || 443}`;
        console.debug(`connect to ${url}`);
        if (url) {
          vpn
            .connect(url)
            .then(() => {
              console.log("[VPN] 连接成功");
            })
            .catch((err) => {
              console.error("[VPN] 连接失败:", err);
              toast("error", "连接失败");
            });
        }
      }
    },
    [toast, t, isAuthenticated]
  );

  // VPN路由相关逻辑
  const getIpList = useCallback(async (country: string) => {
    const cacheKey = `${CACHE_KEYS.IPV4_LIST}_${country}`;
    const cached = await cache.get<string[]>(cacheKey);
    if (cached) return cached;

    const response = await api<{ items: string[] }>({
      path: `/api/v1/ipv4/${country}`,
    });

    if (response.code === 0 && response.data.items) {
      await cache.set(cacheKey, response.data.items, 60 * 60 * 24 * 30);
      return response.data.items;
    }
    return [];
  }, []);

  const getPacConfig = useCallback(async () => {
    const cacheKey = CACHE_KEYS.PAC_CONFIG;
    const cached = await cache.get<{
      routeDomains: string[];
      blockedDomains: string[];
    }>(cacheKey);
    if (cached) return cached;

    const response = await api<{
      routeDomains: string[];
      blockedDomains: string[];
    }>({
      path: "/api/route/pac",
    });

    if (response.code === 0 && response.data) {
      await cache.set(cacheKey, response.data, 60 * 60 * 24);
      return response.data;
    }
    throw new Error("获取PAC配置失败");
  }, []);

  const getRouteApps = useCallback(async (installedApps: InstalledApp[]) => {
    const response = await api<{ items: string[] }>({
      path: "/api/apps/filter-my-app",
      method: "post",
      params: {
        apps: installedApps.map((app) => app.packageName),
      },
    });

    if (response.code === 0 && response.data.items) {
      return response.data.items;
    }
    return [];
  }, []);

  const setAutoMode = useCallback(
    async (isAuto: boolean) => {
      let newConfig: RouteConfig = {
        ...routeConfig,
        mode: "global",
      };

      if (isAuto) {
        if (isAndroidApp) {
          const routeApps = await getRouteApps(installedApps);
          newConfig = {
            ...routeConfig,
            mode: "app",
            routeApps: routeApps,
          };
        } else if (isIosApp) {
          const ips = await getIpList("CN");
          newConfig = {
            ...routeConfig,
            mode: "ip",
            routeIps: ips,
          };
        } else if (isDesktopApp) {
          const { routeDomains, blockedDomains } = await getPacConfig();
          newConfig = {
            ...routeConfig,
            mode: "pac",
            pac: {
              socks5Proxy: "",
              routeDomains,
              blockedDomains,
            },
            routeIps: [],
            dns: [],
          };
        }
      }

      vpn
        .setRoute(newConfig)
        .then(() => {
          setRouteConfig(newConfig);
        })
        .catch((err) => {
          console.error("[VPN Route] 设置路由失败:", err);
          toast("error", "设置路由失败");
        });
    },
    [routeConfig, installedApps, getIpList, getPacConfig, getRouteApps, toast]
  );

  // 计算派生状态
  const isAuto = useMemo(() => routeConfig.mode !== "global", [routeConfig]);

  const effectedGroups = useMemo(() => {
    if (!groups) return null;
    if (!isLoading && expired && !onTrial) return null;
    return groups;
  }, [groups, expired, onTrial, isLoading]);

  const groupedServers = useMemo(() => {
    if (!effectedGroups) return [];
    return effectedGroups.items
      .filter((x) => x.servers && x.servers.length > 0)
      .map((group) => ({
        id: group.id,
        title: onTrial
          ? t("vpn.trial_servers")
          : group.level != 1
          ? t("vpn.dedicated_servers")
          : t("vpn.vip_servers"),
        data: group.servers,
      }));
  }, [effectedGroups, onTrial, t]);

  const effectedServer = useMemo(() => {
    if (!serverId) return null;
    for (const group of groups?.items || []) {
      for (const s of group.servers) {
        if (s.serverId === serverId) {
          return s;
        }
      }
    }
    return null;
  }, [serverId, groups]);

  // 副作用
  useEffect(() => {
    syncStatus();
  }, [syncStatus]);

  useEffect(() => {
    if (!isAuthenticated) return;

    const unsubscribe = vpn.onStatusChange(
      (data: VpnConnectionStatusChangeEvent) => {
        setVpnStatus({
          status: data.status,
          message: data.message,
        });
      }
    );

    const unsubscribe2 = vpn.onError((data: VpnConnectionErrorEvent) => {
      console.error("[VPN] 错误", data);
      toast("error", data.error);
    });

    return () => {
      unsubscribe();
      unsubscribe2();
    };
  }, [isAuthenticated, toast]);

  useEffect(() => {
    const init = async () => {
      if (!routeConfig.mode) {
        const currentConfig = await vpn.getRoute();
        setRouteConfig(currentConfig);
      }

      if (isAndroidApp && !installedApps.length) {
        const apps = await actions.getInstalledApps();
        setInstalledApps(apps);
      }
    };

    init();
  }, []);

  useEffect(() => {
    if (groups) {
      setOnTrial(groups.isTrial);
      if (groups.isTrial) {
        const rest = dayjs(groups.trialExpiredAt).diff(dayjs(), "second");

        const interval = setInterval(() => {
          const seconds = dayjs(groups.trialExpiredAt).diff(dayjs(), "second");
          setTrialRestTime(formatSeconds(seconds));
        }, 1000);

        const timer = setTimeout(() => {
          disconnect();
          setOnTrial(false);
          clearTimeout(timer);
          clearInterval(interval);
        }, 1000 * rest);

        return () => {
          clearTimeout(timer);
          clearInterval(interval);
        };
      }
    }
  }, [groups, disconnect]);

  // 添加 generateStats 函数
  const generateStats = useCallback((detail: VpnUseStats | null): Stats => {
    if (detail) {
      const { rated, in: theIn, out: theOut } = detail;
      const isEnough = !!(rated === 0 || (theIn + theOut) / rated <= 0.4);
      const isUsedUp = rated > 0 && theIn + theOut >= rated;
      return {
        isEnough,
        isUsedUp,
        isUnlimited: rated === 0,
        rated,
        theIn,
        theOut,
        used: theIn + theOut,
      };
    }
    return {
      isEnough: true,
      isUsedUp: false,
      isUnlimited: true,
      theIn: 0,
      theOut: 0,
      rated: 0,
      used: 0,
    };
  }, []);

  // 添加 stats 计算
  const stats = useMemo(() => {
    return generateStats(vpnUseStats || null);
  }, [vpnUseStats, generateStats]);

  const value = {
    // VPN连接相关
    connect,
    disconnect,
    status: vpnStatus,
    syncStatus,

    // VPN路由相关
    routeConfig,
    setAutoMode,
    isAuto,

    // VPN服务器组相关
    groups: effectedGroups,
    setGroups,
    groupedServers,
    onTrial,
    trialRestTime,

    // VPN服务器选择相关
    server: effectedServer,
    setServer: async (server: VpnServer) => setServerId(server.serverId),

    // VPN使用统计相关
    stats,
    setStats: setVpnUseStats,
  };

  return <VpnContext.Provider value={value}>{children}</VpnContext.Provider>;
};

export const useVpn = () => {
  const context = useContext(VpnContext);
  if (!context) {
    throw new Error("useVpn must be used within a VpnProvider");
  }
  return context;
};
